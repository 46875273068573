import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  FormControl,
  RadioGroup,
  FormControlLabel,
  styled,
  TableCell,
  useTheme,
  Autocomplete,
  ThemeProvider
} from "@mui/material"
import moment from "moment"
import { user } from "../../../common/MainApp"
import { useEffect, useState } from "react"
import { useMutation } from "@apollo/client"
import {
  DELETE_REPAIR_FROM,
  UPDATE_APPROVE_BY,
  UPDATE_REPAIR_FROM
} from "../../../common/Mutation/MaintenanceMutation"
import swal from "sweetalert"
import {
  GET_MACHINES_MT,
  GET_REPAIR_REQUEST
} from "../../../common/Query/MaintenanceQuery"
import { GET_DEPARTMENT } from "../../../common/Query/MasterQuery"
import { useQuery } from "@apollo/client"
import { miniLogo } from "../../../common/utilities"
import { IDepartment } from "../RepairDocument"
import ThemedLoadingButton from "../../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedRadio from "../../../common/Resources/ThemedComponents/ThemedRadio"
import FormTextField from "../../../common/Resources/ThemedComponents/Maintenance/FormTextField"
import ThemedButton from "../../../common/Resources/ThemedComponents/ThemedButton"

const StyledTableCell = styled(TableCell)(() => ({
  textAlign: "center",
  borderBottom: "1px",
  padding: "5px",
  fontSize: "22px",
  border: "solid 1px black",
  backgroundColor: "#FFFFFF",
  color: "#000000"
}))

export interface IRequestData {
  id: number
  actionDate: string
  recordNo: number
  department: string
  machineName: string
  code: string
  workType: string
  jobType: string
  urgency: string
  detail: string
  requestDate: string
  endWaitRepair: string
  request: string
  completeDate: string
  requestBy: string
  approveBy: string
  factory: string
}
interface IProp {
  data: IRequestData
  factory: string
}
const RepairPerson: React.FC<IProp> = (props: IProp) => {
  const { data, factory } = props
  const { actionDate, recordNo } = data
  const [status, setStatus] = useState<boolean>(true)
  const [department, setDepartment] = useState<string>(data.department)
  const [machineName, setMachineName] = useState<string>(data.machineName)
  const [code, setCode] = useState<string>(data.code)
  const [workType, setWorkType] = useState<string>(data.workType)
  const [jobType, setJobType] = useState<string>(data.jobType)
  const [urgency, setUrgency] = useState<string>(data.urgency)
  const [completeDate, setCompleteDate] = useState<string>(data.urgency)
  const [detail, setDetail] = useState<string>(data.detail)
  const getDepartment = useQuery(GET_DEPARTMENT)
  const getMachine = useQuery(GET_MACHINES_MT)
  const { data: requestData } = useQuery(GET_REPAIR_REQUEST)
  const [updateApproveBy] = useMutation(UPDATE_APPROVE_BY, {
    refetchQueries: [GET_REPAIR_REQUEST],
    awaitRefetchQueries: true
  })
  const [updateRepairFrom] = useMutation(UPDATE_REPAIR_FROM)
  const [deleteRepairFrom] = useMutation(DELETE_REPAIR_FROM)
  const departmentList: IDepartment[] = getDepartment?.data?.department || []
  const machineList =
    Array.from(
      new Set(
        getMachine?.data?.machinesMt.map(
          ({ machine }: { machine: string }) => machine
        )
      )
    ) || []
  const codeList =
    Array.from(
      new Set(
        getMachine.data?.machinesMt
          .filter((e: { machine: string }) => e.machine === machineName)
          .map(({ code }: { code: string }) => code)
      )
    ) || []
  let detailList: string[] = []
  if (requestData)
    detailList = Array.from(
      new Set(
        requestData.repairRequestFrom.map((e: { detail: string }) => e.detail)
      )
    )
  const theme = useTheme()
  useEffect(() => {
    if (data?.approveBy) setStatus(false)
  }, [data])
  const handleApprove = async () => {
    if (
      (user.position === "Manager" && user.factory === factory) ||
      user.position === "GM" ||
      user.position === "FM" ||
      user.position === "Admin"
    ) {
      await updateApproveBy({
        variables: {
          approveBy: user.employee + " " + moment().format("YYYY/MM/DD HH:mm"),
          department: data.department,
          recordNo: Number(data.recordNo),
          actionDate: data.actionDate,
          factory: data.factory
        }
      })
      swal("Approve", "approve success", "success")
      setStatus(false)
    } else {
      swal("Warning", "ผู้อนุมัติไม่ถูกต้อง", "warning")
    }
  }
  const handleUpdate = async () => {
    const inUpdateRepairFrom = {
      department,
      actionDate,
      workType,
      jobType,
      urgency,
      completeDate,
      machineName,
      code,
      detail,
      recordNo
    }

    try {
      await updateRepairFrom({
        variables: {
          inUpdateRepairFrom
        }
      })
      swal("Success", "", "success")
    } catch (error) {
      swal("Error", "", "error")
    }
  }

  const handleDeleteData = async () => {
    try {
      await deleteRepairFrom({
        variables: {
          inDeleteRepairFrom: {
            actionDate: data.actionDate,
            factory: data.factory,
            recordNo: data.recordNo
          }
        }
      })
      swal("Success", "", "success")
      window.location.reload()
    } catch (error) {
      swal("Error", "", "error")
    }
  }
  const position = ["Manager", "GM", "FM", "Admin"]
  return (
    <ThemeProvider theme={theme}>
      <div style={{ padding: "10px", backgroundColor: "white" }}>
        <Table sx={{ backgroundColor: "#FFFFFF", color: "#000000" }}>
          <TableHead>
            <TableRow>
              <StyledTableCell rowSpan={2}>
                <img
                  src={miniLogo}
                  alt="Brother Auto Parts."
                  style={{
                    width: "150px",
                    height: "100px"
                  }}
                />
              </StyledTableCell>
              <StyledTableCell rowSpan={2}>
                <h1>ใบเเจ้งซ่อม</h1>
              </StyledTableCell>
              <StyledTableCell>
                <h3>Doc No.F-MT-01-04</h3>
                <br />
                <h3>Effective Date: 04/1/22 R03 Issue: 1</h3>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>
                <h3>
                  Rec.No.
                  {moment(data?.actionDate).format("YYYY-MM") +
                    "-" +
                    data?.recordNo}
                  -1000
                </h3>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell>สำหรับผู้เเจ้งซ่อม</StyledTableCell>
              <StyledTableCell style={{ textAlign: "left" }}>
                <p style={{ display: "inline-block" }}>แผนก</p>
                <Autocomplete
                  freeSolo
                  options={departmentList}
                  getOptionLabel={(option) =>
                    typeof option === "string"
                      ? option
                      : option.departmentNameThai
                  }
                  value={
                    department
                      ? departmentList.find(
                          (val) => val.departmentName === department
                        )
                      : {
                          departmentId: 0,
                          departmentName: "",
                          departmentNameThai: ""
                        }
                  }
                  fullWidth
                  onChange={(event, selectedDepartment) => {
                    setDepartment(
                      typeof selectedDepartment === "string"
                        ? selectedDepartment
                        : (selectedDepartment?.departmentName as string)
                    )
                  }}
                  sx={{
                    width: "150px",
                    display: "inline-flex",
                    input: { textAlign: "center" }
                  }}
                  renderInput={(params) => (
                    <FormTextField
                      {...params}
                      variant="standard"
                      InputProps={{ style: { fontSize: 25 } }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                />
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}>
                วันที่เเจ้ง :{data?.actionDate}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                colSpan={1}
                style={{ borderRight: "solid 1px white" }}
              >
                ลักษณะงาน
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "left" }} colSpan={2}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(e) => setWorkType(e.target.value)}
                    defaultValue={workType}
                  >
                    <FormControlLabel
                      value="ซ่อม"
                      control={<ThemedRadio />}
                      label="ซ่อม"
                    />
                    <FormControlLabel
                      value="สร้าง/จัดทำ"
                      style={{ paddingLeft: "103px" }}
                      control={<ThemedRadio />}
                      label="สร้าง/จัดทำ"
                    />
                    <FormControlLabel
                      value="ดัดแปลง/ปรับปรุง"
                      style={{
                        paddingLeft: "100px",
                        paddingRight: "100px"
                      }}
                      control={<ThemedRadio />}
                      label="ดัดแปลง/ปรับปรุง"
                    />
                  </RadioGroup>
                </FormControl>
                <FormTextField
                  type="text"
                  style={{
                    width: "30%",
                    display: "inline-flex",
                    justifyItems: "end"
                  }}
                  value={
                    workType === "สร้าง/จัดทำ" ||
                    workType === "ดัดแปลง/ปรับปรุง"
                      ? ""
                      : workType
                  }
                  onChange={(e) => setWorkType(e.target.value)}
                  label="อื่นๆ..."
                  variant="standard"
                  sx={{ mt: -1 }}
                />
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                colSpan={1}
                style={{ borderRight: "solid 1px white" }}
              >
                ประเภทงาน
              </StyledTableCell>
              <StyledTableCell colSpan={2} style={{ textAlign: "left" }}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(e) => setJobType(e.target.value)}
                    defaultValue={jobType}
                  >
                    <FormControlLabel
                      value="เครื่องมือ/เครื่องจักร"
                      control={<ThemedRadio />}
                      label="เครื่องมือ/เครื่องจักร"
                    />
                    <FormControlLabel
                      value="สารธารณูปโภค"
                      style={{ paddingRight: "340px" }}
                      control={<ThemedRadio />}
                      label="สารธารณูปโภค"
                    />
                  </RadioGroup>
                </FormControl>
                <FormTextField
                  type="text"
                  style={{
                    width: "30%"
                  }}
                  label="อื่นๆ..."
                  value={
                    jobType === "สารธารณูปโภค" ||
                    jobType === "เครื่องมือ/เครื่องจักร"
                      ? ""
                      : jobType
                  }
                  onChange={(e) => setJobType(e.target.value)}
                  variant="standard"
                  sx={{ mt: -1 }}
                />
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                colSpan={1}
                style={{ borderRight: "solid 1px white" }}
              >
                ความจำเป็น
              </StyledTableCell>
              <StyledTableCell colSpan={2} style={{ textAlign: "left" }}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(e) => setUrgency(e.target.value)}
                    defaultValue={urgency}
                  >
                    <FormControlLabel
                      value="ปกติ"
                      control={<ThemedRadio />}
                      label="ปกติ"
                    />

                    <FormControlLabel
                      value="ด่วน/ภายในวันที่"
                      control={<ThemedRadio />}
                      style={{ paddingLeft: "103px" }}
                      label="ด่วน/ภายในวันที่"
                    />
                    <FormTextField
                      type="date"
                      style={{
                        width: "120px",
                        paddingRight: "210px"
                      }}
                      onChange={(e) => setCompleteDate(e.target.value)}
                      value={completeDate}
                      variant="standard"
                    />
                  </RadioGroup>
                </FormControl>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                style={{
                  textAlign: "left",
                  borderRight: "solid 1px white",
                  borderBottom: "solid 1px white"
                }}
                colSpan={3}
              >
                <p style={{ display: "inline-block" }}>ชื่อเครื่องจักร :</p>
                <Autocomplete
                  freeSolo
                  options={machineList}
                  value={machineName}
                  sx={{
                    width: "150px",
                    display: "inline-flex",
                    input: { textAlign: "center" }
                  }}
                  onChange={(e, newValue) => setMachineName(newValue as string)}
                  renderInput={(params) => (
                    <FormTextField
                      {...params}
                      variant="standard"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                />
                <p style={{ display: "inline-block" }}>รหัสเครื่องจักร :</p>
                <Autocomplete
                  freeSolo
                  options={codeList}
                  value={code}
                  sx={{
                    width: "150px",
                    display: "inline-flex",
                    input: { textAlign: "center" }
                  }}
                  onChange={(e, newValue) => setCode(newValue as string)}
                  renderInput={(params) => (
                    <FormTextField
                      {...params}
                      variant="standard"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                />
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                style={{ textAlign: "left", borderBottom: "solid 1px white" }}
                colSpan={3}
              >
                <p style={{ display: "inline-block" }}>
                  รายละเอียดงานหรือลักษณะงานที่ชำรุด:&nbsp;&nbsp;
                </p>
                <Autocomplete
                  value={detail}
                  fullWidth
                  disablePortal
                  freeSolo
                  disableClearable
                  sx={{
                    width: "800px",
                    display: "inline-flex",
                    input: { textAlign: "center" }
                  }}
                  onChange={(event, selectedPartName) => {
                    setDetail(selectedPartName as string)
                  }}
                  options={detailList}
                  renderInput={(params) => (
                    <FormTextField
                      {...params}
                      variant="standard"
                      InputProps={{ style: { fontSize: 25 } }}
                      onChange={(e) => setDetail(e.target.value)}
                    />
                  )}
                />
                <br />
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                colSpan={2}
                style={{
                  borderRight: "solid 1px white"
                }}
              >
                ผู้เเจ้ง: {data?.requestBy}
                <br />
                หัวหน้าแผนก
              </StyledTableCell>
              <StyledTableCell
                style={{
                  textAlign: "left"
                }}
              >
                ผู้อนุมัติ
                {status ? (
                  <ThemedButton
                    style={
                      position.includes(user.position)
                        ? {}
                        : { display: "none" }
                    }
                    onClick={handleApprove}
                  >
                    Approve
                  </ThemedButton>
                ) : (
                  <> {data?.approveBy ? data?.approveBy : user.employee}</>
                )}
                <br />
                ผู้จัดการฝ่าย/ผู้จัดการโรงงาน
                <h6 style={{ display: "contents" }}>
                  (เฉพาะงานสร้าง/ปรับปรุง)
                </h6>
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
        <ThemedLoadingButton
          variant="contained"
          sx={{ mt: 1, width: "150px" }}
          style={{ backgroundColor: theme.palette.primary.light }}
          autoFocus
          onClick={handleUpdate}
        >
          UPDATE
        </ThemedLoadingButton>
        <ThemedLoadingButton
          variant="contained"
          sx={{ mt: 1, width: "150px" }}
          style={{
            backgroundColor: theme.palette.error.main,
            marginLeft: "10px"
          }}
          autoFocus
          onClick={handleDeleteData}
        >
          DELETE
        </ThemedLoadingButton>
      </div>
    </ThemeProvider>
  )
}
export default RepairPerson
