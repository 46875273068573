import { Grid, useTheme } from "@mui/material"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { GET_NUMBER_REPAIR } from "../../../../common/Query/MaintenanceQuery"
import { useQuery } from "@apollo/client"
import { useState } from "react"
import { DatePicker, LocalizationProvider } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterMoment"
import moment from "moment"
import ThemedTextField from "../../../../common/Resources/ThemedComponents/ThemedTextField"
import { formatDateToYearMonth } from "../../../../common/utilities"

export const PieDashBoard = (props: { year: Date }): JSX.Element => {
  const { year } = props
  const [dateMonth, setDateMonth] = useState<Date>(new Date())
  const setFullFormatDate = formatDateToYearMonth({ year }, dateMonth)
  const { data: repairData } = useQuery(GET_NUMBER_REPAIR, {
    variables: { month: setFullFormatDate }
  })
  const theme = useTheme()
  const pieOptions: Highcharts.Options = {
    chart: {
      type: "pie",
      plotShadow: false,
      height: 500,
      backgroundColor: theme.palette.secondary.main
    },
    title: {
      text: "Repair Request",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },
    plotOptions: {
      pie: {
        innerSize: 100,
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: true
        },
        showInLegend: false
      }
    },
    series: [
      {
        name: "Repair Request",
        type: "pie",
        data: [
          {
            name: "Completed",
            y: repairData?.getNumberOfRepairInvoices?.complete || 0
          },
          {
            name: "unCompleted",
            y: repairData?.getNumberOfRepairInvoices?.unComplete || 0
          }
        ]
      }
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false
        }
      }
    },
    credits: {
      enabled: false
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item container sm={12}>
        <Grid item sm={4}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DatePicker
              label="เลือกเดือน"
              views={["month"]}
              inputFormat="MM"
              value={dateMonth}
              onChange={(newSelectDate) => {
                setDateMonth(moment(newSelectDate).toDate() as Date)
              }}
              renderInput={(params) => (
                <ThemedTextField {...params} fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <div style={{ display: "inline-block", width: "100%" }}>
          <HighchartsReact highcharts={Highcharts} options={pieOptions} />
        </div>
      </Grid>
    </Grid>
  )
}

export default PieDashBoard
