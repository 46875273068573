import moment from "moment"
import { useEffect, useState } from "react"
import {
  GET_INVENTORY_VS_SALE_ORDER,
  GET_MONTHLY_BREAK_DOWN_MACHINE,
  GET_MONTHLY_BREAK_DOWN_PROBLEM,
  GET_MONTHLY_DEFECT
} from "../../../common/Query/DashboardQuery"
import {
  GET_CAPACITY,
  GET_VOLUME_PLAN,
  GET_VOLUME_PLAN_AUTO_PART
} from "../../../common/Query/PlanningQuery"
import {
  GET_MACHINE_RUN_TIME,
  OEE_MONTHLY_REPORT
} from "../../../common/Query/ProductionQuery"
import { GET_PREMIUM_FREIGHT_CHART } from "../../../common/Query/QCQuery"
import { GET_HOLIDAY, GET_LINE } from "../../../common/Query/GeneralQuery"
import {
  GET_MACHINES_MT,
  GET_MAINTENANCE_KPI,
  GET_PM_MACHINE_KPI,
  GET_REPAIRER,
  GET_REPAIR_REQUEST
} from "../../../common/Query/MaintenanceQuery"
import { useApolloClient } from "@apollo/client"
import { IRepairer } from "../../Maintenance/component/Repairer"

interface IFilterData {
  balanceFgStock: number
  balanceMatStock: number
  customer: string
  saleOrder: number
}
interface ISumInventory {
  fgStock: string
  matStock: string
  saleOrder: string
}
interface IInventoryDashboard {
  filterData: IFilterData[]
  sumInventory: ISumInventory
}

export interface IInventorySaleOrder {
  inventoryDashboard: IInventoryDashboard
}

interface IVolumeOrder {
  month: string
  quantity: number
}
export interface IVolumePlanReport {
  order: IVolumeOrder[]
  type: string
}

export interface IAutoPart {
  customer: string
  quantity: number
}

export interface IOEEMonthly {
  line: string
  oee: number
}

export interface IBreakdownMachine {
  machine: string
  problemName: string
  totalTime: number
}

export interface IBreakdownProblem {
  problemName: string
  remarks: string
  totalTime: number
}

export interface IMonthlyDefect {
  totalING: number
  totalSNG: number
  totalSupplierNG: number
  totalTNG: number
}

export interface IPremiumFreight {
  customerClaim?: number
  month?: number
  premiumFreight?: number
  premiumFreightMan?: number
  totalProcess?: number
}

export interface ICapacity {
  customer: string
  jph: number
  line: string
  machine: string
}

export interface ILine {
  line: string
}

export interface IHoliday {
  detail: string
  holiday: string
}

export interface IPMKPI {
  actual: number
  month: number
  target: number
}

export interface IRepairFrom {
  actionDate: string
  approveBy: string
  code: string
  completeDate: string
  department: string
  detail: string
  factory: string
  id: number
  jobType: string
  machineName: string
  recordNo: string
  requestBy: string
  requestDate: string
  urgency: string
  workType: string
}

export interface IMachine {
  brand: string
  code: string
  company: string
  details: string
  factory: string
  frequency: number
  installationDate: string
  line: string
  machine: string
  machinePower: string
  model: string
  priority: string
  remarks: string
}

export interface IPress {
  actionDate?: string
  checkReceive?: string
  completeDate?: string
  correctionList?: string
  department?: string
  detail?: string
  docNo?: string
  endWaitRepair?: string
  factory?: string
  id?: number
  leaderCheck?: string
  managerCheck?: string
  receiver?: string
  referDate?: string
  repairMan?: string
  repairType?: string
  requestDate?: string
  startRepair?: string
  waitRepair?: string
}

export interface IRuntimeMachine {
  factory: string
  line: string
  machine: string
  partNo: string
  planDate: string
  setupTime: number
  stopTime: number
  totalHour: number
  totalTime: number
}

interface ITargetMT {
  line: string
  target: string
  typeName: string
}

interface IFetchData {
  volumePlan: IVolumePlanReport[] | undefined
  inventorySaleOrder: IInventorySaleOrder | undefined
  autoPart: IAutoPart[] | undefined
  oeeMonthly: IOEEMonthly[] | undefined
  breakDownMachine: IBreakdownMachine[] | undefined
  breakdownProblem: IBreakdownProblem[] | undefined
  monthlyDefect: IMonthlyDefect | undefined
  premiumFreight: IPremiumFreight[] | undefined
  capacity: ICapacity[] | undefined
  lineList: ILine[]
  month: Date
  holiday: IHoliday[]
  pmKPI: IPMKPI[]
  repairRequestData: IRepairFrom[]
  machineMT: IMachine[]
  queryRepairer: IRepairer[]
  targetMT: ITargetMT[]
  runtimeMachine: IRuntimeMachine[]
}

export const useFetchData = (
  inventoryType: string,
  dateFrom: string,
  dateTo: string,
  factory: string,
  month: Date,
  quarter: number,
  year: string
): IFetchData => {
  const client = useApolloClient()

  const [volumePlan, setVolumePlan] = useState<IVolumePlanReport[]>()
  const [inventorySaleOrder, setInventorySaleOrder] =
    useState<IInventorySaleOrder>()

  const [autoPart, setAutoPart] = useState<IAutoPart[]>()
  const [oeeMonthly, setOeeMonthly] = useState<IOEEMonthly[]>()
  const [breakDownMachine, setBreakDownMachine] =
    useState<IBreakdownMachine[]>()
  const [breakdownProblem, setBreakdownProblem] =
    useState<IBreakdownProblem[]>()
  const [monthlyDefect, setMonthlyDefect] = useState<IMonthlyDefect>()
  const [premiumFreight, setPremiumFreight] = useState<IPremiumFreight[]>()
  const [capacity, setCapacity] = useState<ICapacity[]>()

  const [lineList, setLineList] = useState<ILine[]>([])
  const [holiday, setHoliday] = useState<IHoliday[]>([])
  const [pmKPI, setPmKPI] = useState<IPMKPI[]>([])
  const [repairRequestData, setRepairRequestData] = useState<IRepairFrom[]>([])
  const [machineMT, setMachineMT] = useState<IMachine[]>([])
  const [queryRepairer, setQueryRepairer] = useState<IRepairer[]>([])
  const [targetMT, setTargetMT] = useState<ITargetMT[]>([])
  const [runtimeMachine, setRuntimeMachine] = useState<IRuntimeMachine[]>([])
  useEffect(() => {
    const fetchData = async () => {
      const { data: inventorySaleOrderData } = await client.query({
        query: GET_INVENTORY_VS_SALE_ORDER,
        variables: {
          date: moment(new Date()).format("YYYY-MM-DD"),
          type: inventoryType
        }
      })
      setInventorySaleOrder(inventorySaleOrderData)
      const { data: volumePlan } = await client.query({
        query: GET_VOLUME_PLAN,
        variables: {
          quarter: quarter
        }
      })
      setVolumePlan(volumePlan.volumePlanReport)
      const { data: volumePlanAutoPart } = await client.query({
        query: GET_VOLUME_PLAN_AUTO_PART,
        variables: {
          dateFrom,
          dateTo,
          type: inventoryType
        }
      })
      setAutoPart(volumePlanAutoPart.volumePlanAutoPart)
      console.log(dateFrom, dateTo)

      const { data: oeeMonthly } = await client.query({
        query: OEE_MONTHLY_REPORT,
        variables: {
          dateFrom,
          dateTo
        }
      })
      setOeeMonthly(oeeMonthly.oeeReportMonthly)

      const { data: breakDownMachine } = await client.query({
        query: GET_MONTHLY_BREAK_DOWN_MACHINE,
        variables: {
          dateFrom: dateFrom,
          dateTo: dateTo
        }
      })
      setBreakDownMachine(breakDownMachine.breakDownMachineMonthlyReport)

      const { data: breakdownProblem } = await client.query({
        query: GET_MONTHLY_BREAK_DOWN_PROBLEM,
        variables: {
          dateFrom: dateFrom,
          dateTo: dateTo
        }
      })
      setBreakdownProblem(breakdownProblem.breakDownProblemMonthlyReport)

      const { data: monthlyDefect } = await client.query({
        query: GET_MONTHLY_DEFECT,
        variables: {
          dateFrom: dateFrom,
          dateTo: dateTo
        }
      })
      setMonthlyDefect(monthlyDefect.inventoryDefectReport)
      const { data: premiumFreight } = await client.query({
        query: GET_PREMIUM_FREIGHT_CHART,
        variables: {
          quarter,
          factory: factory,
          year: year
        }
      })
      setPremiumFreight(premiumFreight.premiumFreightChart)
      const { data: capacityData } = await client.query({
        query: GET_CAPACITY,
        variables: {
          dateFrom: moment(month).startOf("month").format("YYYY-MM-DD"),
          dateTo: moment(month).endOf("month").format("YYYY-MM-DD")
        }
      })
      setCapacity(capacityData.capacity)
      const { data: getLine } = await client.query({
        query: GET_LINE
      })
      setLineList(getLine.getLine)
      const { data: getHoliday } = await client.query({
        query: GET_HOLIDAY
      })
      setHoliday(getHoliday.getHoliday)
      const { data: dataGetPMKpi } = await client.query({
        query: GET_PM_MACHINE_KPI,
        variables: { year: new Date().getFullYear().toString() }
      })
      setPmKPI(dataGetPMKpi.pmMachineKPI)
      const { data: repairRequestData } = await client.query({
        query: GET_REPAIR_REQUEST,
        variables: {
          dateFrom: `${new Date().getFullYear().toString()}-01-01`,
          dateTo: `${new Date().getFullYear().toString()}-12-31`,
          factory: "2"
        }
      })
      setRepairRequestData(repairRequestData.repairRequestFrom)
      const { data: getMachine } = await client.query({
        query: GET_MACHINES_MT
      })
      setMachineMT(getMachine.machinesMt)
      const { data: repairerQuery } = await client.query({
        query: GET_REPAIRER,
        variables: {
          recordList: repairRequestData.repairRequestFrom.map(
            (e: { recordNo: string }) => Number(e.recordNo)
          ),
          factory: "2"
        }
      })
      setQueryRepairer(repairerQuery.getRepairerData)
      const { data: target } = await client.query({
        query: GET_MAINTENANCE_KPI
      })
      setTargetMT(target.maintenanceKPI)
      const { data: dataMachineRunTime } = await client.query({
        query: GET_MACHINE_RUN_TIME,
        variables: {
          dateFrom: `${new Date().getFullYear().toString()}-01-01`,
          dateTo: `${new Date().getFullYear().toString()}-12-31`,
          factory: "2"
        }
      })
      setRuntimeMachine(dataMachineRunTime.runTimeMachine)
    }
    fetchData()
  }, [quarter, inventoryType, dateFrom, dateTo, factory, year, month])
  return {
    volumePlan,
    inventorySaleOrder,
    autoPart,
    oeeMonthly,
    breakDownMachine,
    breakdownProblem,
    monthlyDefect,
    premiumFreight,
    capacity,
    lineList,
    month,
    holiday,
    pmKPI,
    repairRequestData,
    machineMT,
    queryRepairer,
    targetMT,
    runtimeMachine
  }
}
