import { Divider, Grid, Typography, useTheme } from "@mui/material"
import { ThemeProvider } from "@mui/styles"
import moment from "moment"
import { useRef, useState } from "react"
import ThemedTextField from "../../../../../common/Resources/ThemedComponents/ThemedTextField"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import AutocompleteFactory from "../../../../../common/Resources/AutocompleteFactory"
import { useQuery } from "@apollo/client"
import {
  GET_OBJECTIVE_CREATE,
  GET_PROBLEM_PM
} from "../../../../../common/Query/engineerQuery"
import ThemedCard from "../../../../../common/Resources/ThemedComponents/ThemedCard"

interface IParetoProblem {
  factory: string
  problem_count: number
  grouped_problem: string
  __typename: string
}

const ChartDefectReport = (props: {
  chart?: HighchartsReact.Props
  data: IParetoProblem[]
  dataObj: IParetoProblem[]
}): JSX.Element => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const { data, dataObj } = props
  const newData = dataObj.concat(data)
  newData.sort((a, b) => {
    if (a.__typename === "objectiveCreate") return 1
    if (b.__typename === "objectiveCreate") return -1
    const countDiff = b.problem_count - a.problem_count
    if (countDiff !== 0) {
      return countDiff
    }
    return a.grouped_problem.localeCompare(b.grouped_problem, undefined, {
      numeric: true,
      sensitivity: "base"
    })
  })
  const theme = useTheme()

  const options: Highcharts.Options = {
    chart: {
      renderTo: "container",
      type: "column",
      backgroundColor: theme.palette.secondary.main
    },
    credits: { enabled: false },
    title: {
      text: "",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },
    tooltip: {
      shared: true
    },
    xAxis: {
      categories: newData.map((item) => item.grouped_problem),
      crosshair: true,
      labels: {
        style: {
          color: theme.palette.secondary.contrastText,
          fontSize: "15px",
          whiteSpace: "normal"
        }
      }
    },
    legend: {
      enabled: false
    },
    yAxis: [
      {
        labels: {
          style: {
            color: theme.palette.secondary.contrastText
          }
        },
        title: {
          text: "",
          style: {
            color: theme.palette.secondary.contrastText
          }
        }
      },
      {
        title: {
          text: "",
          style: {
            color: theme.palette.secondary.contrastText
          }
        },
        minPadding: 0,
        maxPadding: 0,
        max: 100,
        min: 0,
        opposite: true,
        labels: {
          format: "{value}%",
          style: {
            color: theme.palette.secondary.contrastText
          }
        }
      }
    ],
    series: [
      {
        type: "pareto",
        name: "Percentage",
        yAxis: 1,
        zIndex: 10,
        baseSeries: 1,
        tooltip: {
          valueDecimals: 2,
          valueSuffix: "%"
        },
        color: "#FF924C"
      },
      {
        name: "จำนวนปัญหา",
        type: "column",
        zIndex: 2,
        color: "#4595F7",
        data: newData.map((item) => item.problem_count) ?? []
      }
    ],
    exporting: {
      enabled: false
    }
  }

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartComponentRef}
        {...props.chart}
      />
    </>
  )
}

const GraphDefectProblem: React.FC = () => {
  const [dateFrom, setDateFrom] = useState<string>(
    moment(new Date()).format("YYYY-MM-01")
  )
  const [dateTo, setDateTo] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [factory, setFactory] = useState<string>("2")
  const { data: queryObjective } = useQuery(GET_OBJECTIVE_CREATE, {
    variables: {
      dateFrom: dateFrom,
      dateTo: dateTo,
      factory: factory
    }
  })
  const { data: queryProblem } = useQuery(GET_PROBLEM_PM, {
    variables: {
      dateFrom: dateFrom,
      dateTo: dateTo,
      factory: factory
    }
  })
  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <ThemedCard sx={{ background: theme.palette.secondary.main }}>
        <Grid container spacing={2}>
          <Grid item sm={3}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", padding: "10px" }}
            >
              Defect Pareto Problem
            </Typography>
          </Grid>
          <Grid item container spacing={1} sm={9} padding={1}>
            <Grid item sm={4}>
              <ThemedTextField
                fullWidth
                value={dateFrom}
                variant="outlined"
                id="action-date"
                type="date"
                label="Date From"
                autoComplete="family-name"
                onChange={(e) => setDateFrom(e.target.value)}
              />
            </Grid>
            <Grid item sm={4}>
              <ThemedTextField
                fullWidth
                value={dateTo}
                variant="outlined"
                id="action-date"
                type="date"
                label="Date To"
                autoComplete="family-name"
                onChange={(e) => setDateTo(e.target.value)}
              />
            </Grid>
            <Grid item sm={4}>
              <AutocompleteFactory setFactory={setFactory} factory={factory} />
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Divider />
          </Grid>
          <Grid item sm={12} justifyContent="center">
            <ChartDefectReport
              data={queryProblem?.getDefectParetoProblemPMHistory ?? []}
              dataObj={queryObjective?.getDefectParetoProblemCreate ?? []}
            />
          </Grid>
        </Grid>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default GraphDefectProblem
