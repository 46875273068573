import { IMenuItem } from "../Navbar"

export const menuItemList: { [menuItemKey: string]: IMenuItem[] } = {
  Master: [
    { key: "Customers", url: "Customers" },
    { key: "Material", url: "Material" },
    { key: "Parts", url: "Parts" },
    { key: "Machine", url: "Machine" },
    { key: "Process", url: "Process" },
    { key: "Parts Process", url: "PartProcess" },
    // { key: "Bom", url: "Bom" },
    { key: "Employee", url: "Employee" }
  ],
  Materials: [
    { key: "Materials'In", url: "Materials'In" },
    { key: "Materials'Out", url: "Materials'Out" },
    { key: "Material In & Out Editor", url: "MatInOutEditor" },
    { key: "Barcode Part List", url: "BarcodePartList" },
    { key: "Materials Report Stock Card", url: "MaterialsReportStockCard" },
    { key: "FIFO Materials'Tag", url: "FIFOMaterials'Tag" },
    { key: "ใบตรวจสินค้า(sm)", url: "ใบตรวจสินค้า(sm)" },
    { key: "Material Out Scanner", url: "MaterialOutScanner" },
    { key: "Material Out Report", url: "MaterialOutReport" },
    { key: "Material Inventory Report", url: "MaterialInventoryReport" },
    { key: "WipStock", url: "WipStock" }
  ],
  Planning: [
    { key: "Job Order", url: "JobOrder" },
    { key: "Production Tag", url: "ProductionTag" },
    { key: "Production Plan", url: "ProductionPlan" },
    { key: "Production Plan Report", url: "ProductionPlanReport" },
    { key: "Work In Process", url: "Workinprocess" }
  ],
  Production: [
    { key: "Production Report", url: "ProductionReport" },
    { key: "Production OEE Result", url: "ProductionOEEResult" },
    { key: "Daily Production Plan Report", url: "DailyProductionPlanReport" },
    { key: "Master Breakdown", url: "MasterBreakdown" }
  ],
  Qc: [
    { key: "QC ใบอนุมัติทำลาย", url: "QCDemolish" },
    { key: "QC Finished Goods", url: "QCFinishedGoods" },
    { key: "Premium Freight-Man Hour", url: "PremiumFreight" },
    { key: "Premium Freight Report", url: "PremiumFreightReport" },
    { key: "Dock Audit Check Sheet", url: "DockAuditCheckSheet" }
  ],
  Engineer: [
    { key: "PM DashBoard KPI", url: "PMDashBoard" },
    { key: "Factory 1", url: "Factory1" },
    { key: "Factory 2", url: "Factory2" },
    { key: "PM History", url: "PMHistory" },
    { key: "PM Reset", url: "PMReset" },
    { key: "BM/PM Engineer Document", url: "PMDocument" },
    { key: "Engineer Spare Part In", url: "PMSparePartIn" },
    { key: "Engineer Stock Card", url: "PMSparePartStockCard" },
    { key: "Master Spare Part", url: "MasterSparePart" },
    { key: "PM Engineer Report", url: "PMEngineerReport" },
    { key: "Log Sheet", url: "PMLogSheetReport" }
  ],
  Warehouse: [
    { key: "FG In", url: "FGIn" },
    { key: "FG Out", url: "FGOut" },
    { key: "FG Out Multiple", url: "FGOutMultiple" },
    { key: "WH Scanner", url: "WHScanner" },
    { key: "Packaging Standard", url: "PackagingStandard" },
    { key: "FG Report Stock Card", url: "FGReportStockCard" },
    { key: "FiFO WH Tag", url: "WHTag" },
    { key: "WH Report", url: "WHReport" },
    { key: "WH Location Scan", url: "WHLocationscan" },
    { key: "WH Location Report", url: "WHLocationreport" },
    { key: "WH FG Edit", url: "WarehouseEditFG" },
    { key: "FG Inventory Report", url: "FGInventoryReport" }
  ],
  MasterPlan: [
    { key: "Order ActualPlan", url: "OrderActualPlan" },
    { key: "Add Begin Stock", url: "AddBeginStock" },
    { key: "Add | Cancel", url: "AddCancel" },
    { key: "Masterplan Report", url: "MPReport" }
  ],
  Logistics: [
    { key: "Transport", url: "Transport" },
    { key: "Logistics Cost Record", url: "LogisticsCostRecord" },
    { key: "Logistics Cost Report", url: "LogisticsCostReport" },
    { key: "Delivery Tag", url: "DeliveryTag" },
    { key: "Delivery Tag-1", url: "DeliveryTag-1" },
    { key: "Delivery Scan", url: "DeliveryScan" },
    { key: "Delivery Report", url: "DeliveryTagReport" },
    { key: "Scanner QR Code Part", url: "ScannerQRCodePart" }
  ],
  Authorization: [
    { key: "Production", url: "Production" },
    { key: "QC", url: "QC" },
    { key: "Confirmation", url: "Confirmation" }
  ],
  Adjust: [
    { key: "Memo", url: "Memo" },
    { key: "Memo Report", url: "MemoReport" },
    { key: "Adjust", url: "Adjust" },
    { key: "Adjust Report", url: "AdjustReport" }
  ],
  Scanner: [
    { key: "Scanner", url: "Scanner" },
    { key: "Edit / Cancel", url: "EditCancelScanner" },
    { key: "Approve", url: "ApproveEditScanner" }
  ],
  Maintenance: [
    { key: "KPI Maintenance", url: "KPIMaintenance" },
    { key: "Machine Maintenance", url: "MachineMaintenance" },
    { key: "PM Report", url: "PMReport" },
    { key: "Preventive Maintenance Plan", url: "PMPlan" },
    { key: "BM Report", url: "RepairRecord" },
    { key: "Break Down", url: "RepairDocument" },
    { key: "Spare Part", url: "SparePart" }
  ],
  Account: [
    { key: "Invoice", url: "Invoice" },
    { key: "Invoice Report", url: "InvoiceReport" }
  ],
  Plate: [
    { key: "Master List Part Plating", url: "MasterPlating" },
    { key: "Monthly Plating Report", url: "MonthlyPlatingReport" },
    { key: "Plating Daily Plan", url: "PlatingDailyReport" }
  ],
  HumanResources: [
    { key: "Upload data Slip Salary", url: "UploadDataSlipSalary" }
  ],
  Iot: [
    { key: "Iot", url: "Iot" },
    { key: "Iot Scanner", url: "IotScanner" },
    { key: "Iot Report", url: "IotReport" }
  ],
  SupplierControl: [
    { key: "Supplier Record", url: "SupplierRecord" },
    { key: "Supplier Stock Card", url: "SupplierStockCard" },
    { key: "Supplier Report", url: "SupplierReport" },
    // { key: "Supplier Edit", url: "SupplierEdit" },
    { key: "Supplier Transport", url: "SupplierTransport" }
  ]
}
