import {
  Autocomplete,
  Box,
  CardHeader,
  Grid,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
  Tabs,
  ThemeProvider,
  useTheme
} from "@mui/material"
import React, { useMemo, useState } from "react"
import { PageLabel } from "../../common/Resources/PageLabel"
import { menuItemList } from "../../common/Resources/menuItemList"
import DatePicker from "@mui/lab/DatePicker"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import DateAdapter from "@mui/lab/AdapterMoment"
import moment from "moment"
import {
  GET_MACHINES_MT,
  GET_MAINTENANCE_KPI,
  GET_PM_MACHINE_KPI,
  GET_REPAIRER
} from "../../common/Query/MaintenanceQuery"
import { useLazyQuery, useQuery } from "@apollo/client"
import { GET_REPAIR_REQUEST } from "../../common/Query/MaintenanceQuery"
import {
  conditionLinePress,
  factoryList,
  getProcessFilterRumTimeMachine
} from "../../common/utilities"
import { IRequestData } from "./component/RepairPerson"
import { GET_MACHINE_RUN_TIME } from "../../common/Query/ProductionQuery"
import PmKpi from "./component/PmKpi"
import MTTR from "./component/MTTR"
import MTBF from "./component/MTBF"
import DialogEditTarget from "./component/DialogEditTarget"
import MaintenanceDashboard from "./component/MaintenanceDashboard"
import { monthNames } from "./PreventiveMaintenancePlan"
import { IRepairer } from "./component/Repairer"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import PieDashBoard from "./component/dashboardComponent/PieDashboard"
import MachineRepairTime from "./component/dashboardComponent/MachineReapairTime"

interface IPropsTabPanel {
  children?: React.ReactNode
  index: number
  value: number
}

interface Repairer {
  id: number
  checkReceive: number
  completeDate: number
  department: number
  docNo: number
  repairMan: number
  repairType: number
  receiver: number
  detail: number
  requestDate: number
  startRepair: number
  waitRepair: number
  endWaitRepair: number
  referDate: number
  leaderCheck: number
  managerCheck: number
  actionDate: string
}
export interface IDataMachine {
  machine: string
  partNo: string
  line: string
  factory: string
  totalHour: number
  stopTime: number
  setupTime: number
  planDate: string
  totalTime: number
}

interface TabMenuProps {
  tapMenu: number
  handleChange: (event: React.SyntheticEvent, newValue: number) => void
  tapProps: (index: number) => { id: string; "aria-controls": string }
}

const pageTitle = "KPI Maintenance"
const TabPanel = (props: IPropsTabPanel) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

const TabPanelRepairRequest = (props: IPropsTabPanel) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ width: "100%" }}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}
const machineRunTime = (type = "", filterMachineOfMonth = []): number => {
  const machineRunTime = getProcessFilterRumTimeMachine(
    type,
    filterMachineOfMonth
  )
  return Number(
    machineRunTime
      ?.reduce((acc: number, obj: IDataMachine) => {
        return acc + obj.totalTime
      }, 0)
      .toFixed(2)
  )
}
const totalTime = (
  lineData: Repairer[],
  i: number,
  rowsMachineRuntime = [],
  type = ""
) => {
  const filterMachineOfMonth = rowsMachineRuntime.filter(
    (e: IDataMachine) => new Date(e.planDate).getMonth() === i
  )
  const lineMachineRunTime = machineRunTime(type, filterMachineOfMonth)

  if (lineData === undefined)
    return {
      dataOfMonth: 0,
      totalTimeOfMonth: Math.ceil(lineMachineRunTime * 60),
      arc: 0
    }
  const dataOfMonth = lineData.filter(
    (e) => new Date(e.actionDate).getMonth() === i
  )
  if (dataOfMonth.length) {
    const totalTimeOfMonth = dataOfMonth
      .map((data) => {
        const diff = Math.abs(
          Number(new Date(data.completeDate)) -
            Number(new Date(data.endWaitRepair))
        )
        const actualTime = diff / 1000 / 60 / 60
        return actualTime
      })
      .reduce((prev: number, cur: number) => {
        return prev + cur
      }, 0)

    const arc = lineMachineRunTime ? totalTimeOfMonth / lineMachineRunTime : 0
    return {
      dataOfMonth: Math.ceil(totalTimeOfMonth * 60),
      totalTimeOfMonth: Math.ceil(lineMachineRunTime * 60),
      arc: Number(arc).toFixed(3)
    }
  } else {
    return {
      dataOfMonth: 0,
      totalTimeOfMonth: Math.ceil(lineMachineRunTime) * 60,
      arc: 0
    }
  }
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 16,
    textAlign: "center",
    border: "solid 1px black",
    borderCollapse: "collapse",
    padding: 2,
    width: "500px"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
    border: "solid 1px black",
    borderCollapse: "collapse",
    padding: 2,
    width: "500px"
  }
}))

const StyledTableRow = styled(TableRow)(() => ({
  border: "solid 1px black",
  "&:nth-of-type(odd)": {
    border: "solid 1px black"
  },

  "&:last-child td, &:last-child th": {
    border: "solid 1px black"
  }
}))

const TabMenu: React.FC<TabMenuProps> = ({
  tapMenu,
  handleChange,
  tapProps
}) => {
  return (
    <Grid item xs={12} sm={12}>
      <Box className="hidden-print">
        <Tabs value={tapMenu} onChange={handleChange}>
          <Tab label="Dashboard" {...tapProps(0)} />
          <Tab label="Graph Repair Notification" {...tapProps(1)} />
          <Tab label="Maintenance Machine Time" {...tapProps(2)} />
          <Tab label="BM" {...tapProps(3)} />
          <Tab label="MTTR" {...tapProps(4)} />
          <Tab label="MTBF" {...tapProps(5)} />
          <Tab label="PM" {...tapProps(6)} />
        </Tabs>
      </Box>
    </Grid>
  )
}

const KPIMaintenance: React.FC = () => {
  const [tapMenu, setTapMenu] = useState<number>(0)
  const [factory, setFactory] = useState<string>("2")
  const [selectDate, setSelectDate] = useState<Date>(new Date())
  const { data: getMachine } = useQuery(GET_MACHINES_MT)

  const [getRepairer, { data: repairerQuery, loading }] =
    useLazyQuery(GET_REPAIRER)
  const [getRepairFrom, { data: repairRequestData }] =
    useLazyQuery(GET_REPAIR_REQUEST)
  const [
    getMachineRumTime,
    { called: callMachineRuntime, data: dataMachineRunTime }
  ] = useLazyQuery(GET_MACHINE_RUN_TIME, {
    variables: {
      dateFrom: `${selectDate.getFullYear().toString()}-01-01`,
      dateTo: `${selectDate.getFullYear().toString()}-12-31`,
      factory
    }
  })
  const [getPMKpi, { called, data: dataGetPMKpi }] = useLazyQuery(
    GET_PM_MACHINE_KPI,
    {
      variables: { year: new Date().getFullYear().toString(), factory }
    }
  )
  const { data: target } = useQuery(GET_MAINTENANCE_KPI)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTapMenu(newValue)
  }
  const [updateTarget, setTarget] = useState<string>("")
  const [line, setLine] = useState<string>("")
  const [open, setOpen] = useState<boolean>(false)
  let rowsMachineRuntime: never[] | undefined = []
  if (dataMachineRunTime) {
    rowsMachineRuntime = dataMachineRunTime?.runTimeMachine
  }
  if (!called) getPMKpi()
  if (!callMachineRuntime) getMachineRumTime()
  const tapProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    }
  }
  const handleSubmit = async () => {
    await getPMKpi({
      variables: {
        year: selectDate.getFullYear().toString(),
        factory
      }
    })
  }

  useMemo(async () => {
    await getRepairFrom({
      variables: {
        dateFrom: `${selectDate.getFullYear().toString()}-01-01`,
        dateTo: `${selectDate.getFullYear().toString()}-12-31`,
        factory
      },
      fetchPolicy: "network-only"
    })
    await getRepairer({
      variables: {
        dateFrom: `${selectDate.getFullYear().toString()}-01-01`,
        dateTo: `${selectDate.getFullYear().toString()}-12-31`,
        factory
      },
      fetchPolicy: "network-only"
    })
  }, [called, factory, selectDate])

  const linePress = useMemo(() => {
    return repairRequestData?.repairRequestFrom
      .filter((filterData: IRequestData) => {
        const machine = getMachine?.machinesMt.find(
          (e: { code: string }) => e.code === filterData.code
        )
        return (
          conditionLinePress(machine?.line) &&
          filterData.factory === factory &&
          filterData.approveBy &&
          filterData.workType === "ซ่อม"
        )
      })
      .map((data: IRequestData) => {
        const repairer: IRepairer = repairerQuery?.getRepairerData.find(
          (repairer: IRepairer) =>
            repairer.docNo === data.recordNo &&
            data.department === repairer.department &&
            repairer.factory === data.factory &&
            repairer.detail === data.detail
        )
        if (repairer) {
          return {
            actionDate: data.actionDate,
            ...repairer
          }
        }
        return null
      })
      .filter((data: IRepairer) => data !== null)
  }, [repairRequestData, repairerQuery])

  const lineSpot = useMemo(() => {
    return repairRequestData?.repairRequestFrom
      .filter((filterData: IRequestData) => {
        const machine = getMachine?.machinesMt.find(
          (e: { code: string }) => e.code === filterData.code
        )
        return (
          machine?.line === "LINE SPOT" &&
          filterData?.factory === factory &&
          filterData.approveBy &&
          filterData.workType === "ซ่อม"
        )
      })
      .map((data: IRequestData) => {
        const findRepairer: IRepairer = repairerQuery?.getRepairerData.find(
          (repairer: IRepairer) =>
            repairer.docNo === data.recordNo &&
            data.department === repairer.department &&
            repairer.factory === data.factory &&
            repairer.detail === data.detail
        )
        if (findRepairer) {
          return {
            actionDate: data.actionDate,
            ...findRepairer
          }
        }
        return null
      })
      .filter((data: IRepairer) => data !== null)
  }, [repairRequestData, repairerQuery])
  const lineArcWelding = useMemo(() => {
    return repairRequestData?.repairRequestFrom
      .filter((filterData: IRequestData) => {
        const machine = getMachine?.machinesMt.find(
          (e: { code: string }) => e.code === filterData.code
        )
        return (
          machine?.line === "ARC WELDING" &&
          filterData?.factory === factory &&
          filterData.approveBy &&
          filterData.workType === "ซ่อม"
        )
      })
      .map((data: IRequestData) => {
        const findRepairer = repairerQuery?.getRepairerData.find(
          (repairer: IRepairer) =>
            repairer.docNo === data.recordNo &&
            data.department === repairer.department &&
            repairer.factory === data.factory &&
            repairer.detail === data.detail
        )
        if (findRepairer) {
          return {
            actionDate: data.actionDate,
            ...findRepairer
          }
        }
        return null
      })
      .filter((data: IRepairer) => data !== null)
  }, [repairRequestData, repairerQuery])
  const BMTarget = target
    ? target.maintenanceKPI.filter(
        (e: { typeName: string }) => e.typeName === "BM"
      )
    : []
  const MTTRTarget = target
    ? target?.maintenanceKPI.filter(
        (e: { typeName: string }) => e.typeName === "MTTR"
      )
    : []
  const MTBFTarget = target
    ? target?.maintenanceKPI.filter(
        (e: { typeName: string }) => e.typeName === "MTBF"
      )
    : []
  const PMTarget = target
    ? target?.maintenanceKPI.find(
        (e: { typeName: string }) => e.typeName === "PM"
      )
    : { target: "0" }

  const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>
      <div style={{ marginTop: "26px" }}>
        <PageLabel
          menuItem={menuItemList.Maintenance}
          menuItemName={Object.keys(menuItemList)[12]}
          menuPageName={pageTitle}
        />
        <DialogEditTarget
          setOpen={setOpen}
          setTarget={setTarget}
          open={open}
          line={line}
          type={"BM"}
          updateTarget={updateTarget}
        />
        <ThemedCard>
          <CardHeader
            titleTypographyProps={{ variant: "h4", fontFamily: "Sarabun" }}
            title="KPI Maintenance"
            subheaderTypographyProps={{ fontFamily: "Sarabun" }}
          />
          <Grid container spacing={2} width={"98%"} margin={1}>
            <Grid item xs={12} sm={4}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  label="เลือกปี"
                  views={["year"]}
                  value={selectDate}
                  onChange={(newSelectDate) => {
                    setSelectDate(moment(newSelectDate).toDate() as Date)
                  }}
                  renderInput={(params) => (
                    <ThemedTextField {...params} fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sm={2} xs={12}>
              <Autocomplete
                fullWidth
                disablePortal
                value={factory}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Select Factory" />
                )}
                onChange={(e, newValue) => setFactory(newValue ? newValue : "")}
                options={factoryList}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ThemedLoadingButton
                sx={{ mt: 1 }}
                loading={loading}
                type="submit"
                variant="contained"
                style={{ backgroundColor: theme.palette.primary.main }}
                onClick={handleSubmit}
              >
                Submit
              </ThemedLoadingButton>
            </Grid>
            <TabMenu
              tapMenu={tapMenu}
              handleChange={handleChange}
              tapProps={tapProps}
            />
            <TabPanel value={tapMenu} index={0}>
              <MaintenanceDashboard
                pmData={dataGetPMKpi?.pmMachineKPI || []}
                linePress={linePress}
                lineSpot={lineSpot}
                lineArcWelding={lineArcWelding}
                MTBFTarget={MTBFTarget}
                MTTRTarget={MTTRTarget}
                rowsMachineRuntime={rowsMachineRuntime}
                BMTarget={BMTarget}
              />
            </TabPanel>
            <TabPanelRepairRequest value={tapMenu} index={1}>
              <h1>กราฟเปรียบเทียบใบแจ้งซ่อม</h1>
              <PieDashBoard year={selectDate} />
            </TabPanelRepairRequest>
            <TabPanelRepairRequest value={tapMenu} index={2}>
              <h1>เวลาที่เครื่องจักร maintenance</h1>
              <MachineRepairTime year={selectDate} />
            </TabPanelRepairRequest>
            <TabPanel value={tapMenu} index={3}>
              <h1>เวลาสูญเสียที่เกิดจากเครื่องจักร</h1>
              <h3 style={{ display: "inline-block" }}>LINE PRESS</h3>
              <ThemedLoadingButton
                sx={{ mt: -2, marginLeft: "10px" }}
                variant="contained"
                style={{ backgroundColor: theme.palette.warning.main }}
                onClick={() => {
                  setOpen(true)
                  setLine("Press")
                }}
              >
                EDIT TARGET
              </ThemedLoadingButton>
              <Table>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>Month</StyledTableCell>
                    {Array.from(Array(monthNames.length), (num, i) => {
                      return (
                        <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                          <span>{monthNames[i]}</span>
                        </StyledTableCell>
                      )
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell style={{ backgroundColor: "grey" }}>
                      Target
                    </StyledTableCell>
                    {Array.from(Array(monthNames.length), (num, i) => {
                      return (
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          key={i}
                          style={{ backgroundColor: "grey" }}
                        >
                          {BMTarget.length > 0
                            ? BMTarget.find(
                                (e: { line: string }) => e.line === "Press"
                              ).target
                            : "0"}
                        </StyledTableCell>
                      )
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>เวลาทำงานรวม</StyledTableCell>
                    {Array.from(Array(monthNames.length), (num, i) => {
                      return (
                        <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                          {
                            totalTime(linePress, i, rowsMachineRuntime, "stamp")
                              .totalTimeOfMonth
                          }
                        </StyledTableCell>
                      )
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>เวลาสูญเสีย </StyledTableCell>
                    {Array.from(Array(monthNames.length), (num, i) => {
                      return (
                        <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                          {
                            totalTime(linePress, i, rowsMachineRuntime, "stamp")
                              .dataOfMonth
                          }
                        </StyledTableCell>
                      )
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell style={{ backgroundColor: "grey" }}>
                      Act.Targe (%)
                    </StyledTableCell>
                    {Array.from(Array(monthNames.length), (num, i) => {
                      return (
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          key={i}
                          style={{ backgroundColor: "grey" }}
                        >
                          {(
                            Number(
                              totalTime(
                                linePress,
                                i,
                                rowsMachineRuntime,
                                "stamp"
                              ).arc
                            ) * 100
                          ).toFixed(2)}
                        </StyledTableCell>
                      )
                    })}
                  </StyledTableRow>
                </TableBody>
              </Table>
              <hr />
              <h3 style={{ display: "inline-block" }}>LINE SPOT</h3>
              <ThemedLoadingButton
                sx={{ mt: -2, marginLeft: "10px" }}
                variant="contained"
                style={{ backgroundColor: theme.palette.warning.main }}
                onClick={() => {
                  setOpen(true)
                  setLine("Spot")
                }}
              >
                EDIT TARGET
              </ThemedLoadingButton>
              <Table>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>Month</StyledTableCell>
                    {Array.from(Array(monthNames.length), (num, i) => {
                      return (
                        <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                          <span>{monthNames[i]}</span>
                        </StyledTableCell>
                      )
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell style={{ backgroundColor: "grey" }}>
                      Target
                    </StyledTableCell>
                    {Array.from(Array(monthNames.length), (num, i) => {
                      return (
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          key={i}
                          style={{ backgroundColor: "grey" }}
                        >
                          {BMTarget.length > 0
                            ? BMTarget.find(
                                (e: { line: string }) => e.line === "Spot"
                              ).target
                            : "0"}
                        </StyledTableCell>
                      )
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>เวลาทำงานรวม</StyledTableCell>
                    {Array.from(Array(monthNames.length), (num, i) => {
                      return (
                        <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                          {
                            totalTime(lineSpot, i, rowsMachineRuntime, "weld")
                              .totalTimeOfMonth
                          }
                        </StyledTableCell>
                      )
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>เวลาสูญเสีย </StyledTableCell>
                    {Array.from(Array(monthNames.length), (num, i) => {
                      return (
                        <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                          {
                            totalTime(lineSpot, i, rowsMachineRuntime, "weld")
                              .dataOfMonth
                          }
                        </StyledTableCell>
                      )
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell style={{ backgroundColor: "grey" }}>
                      Act.Targe (%)
                    </StyledTableCell>
                    {Array.from(Array(monthNames.length), (num, i) => {
                      return (
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          key={i}
                          style={{ backgroundColor: "grey" }}
                        >
                          {(
                            Number(
                              totalTime(lineSpot, i, rowsMachineRuntime, "weld")
                                .arc
                            ) * 100
                          ).toFixed(2)}
                        </StyledTableCell>
                      )
                    })}
                  </StyledTableRow>
                </TableBody>
              </Table>
              <hr />
              <h3 style={{ display: "inline-block" }}>LINE ARC WELDING</h3>
              <ThemedLoadingButton
                sx={{ mt: -2, marginLeft: "10px" }}
                variant="contained"
                style={{ backgroundColor: theme.palette.warning.main }}
                onClick={() => {
                  setOpen(true)
                  setLine("ArcWelding")
                }}
              >
                EDIT TARGET
              </ThemedLoadingButton>
              <Table>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>Month</StyledTableCell>
                    {Array.from(Array(monthNames.length), (num, i) => {
                      return (
                        <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                          <span>{monthNames[i]}</span>
                        </StyledTableCell>
                      )
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell style={{ backgroundColor: "grey" }}>
                      Target
                    </StyledTableCell>
                    {Array.from(Array(monthNames.length), (num, i) => {
                      return (
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          key={i}
                          style={{ backgroundColor: "grey" }}
                        >
                          {BMTarget.length > 0
                            ? BMTarget.find(
                                (e: { line: string }) => e.line === "ArcWelding"
                              ).target
                            : "0"}
                        </StyledTableCell>
                      )
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>เวลาทำงานรวม</StyledTableCell>
                    {Array.from(Array(monthNames.length), (num, i) => {
                      return (
                        <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                          {
                            totalTime(
                              lineArcWelding,
                              i,
                              rowsMachineRuntime,
                              "arcWeld"
                            ).totalTimeOfMonth
                          }
                        </StyledTableCell>
                      )
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>เวลาสูญเสีย </StyledTableCell>
                    {Array.from(Array(monthNames.length), (num, i) => {
                      return (
                        <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                          {
                            totalTime(
                              lineArcWelding,
                              i,
                              rowsMachineRuntime,
                              "arcWeld"
                            ).dataOfMonth
                          }
                        </StyledTableCell>
                      )
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell style={{ backgroundColor: "grey" }}>
                      Act.Targe (%)
                    </StyledTableCell>
                    {Array.from(Array(monthNames.length), (num, i) => {
                      return (
                        <StyledTableCell
                          colSpan={1}
                          rowSpan={1}
                          key={i}
                          style={{ backgroundColor: "grey" }}
                        >
                          {(
                            Number(
                              totalTime(
                                lineArcWelding,
                                i,
                                rowsMachineRuntime,
                                "arcWeld"
                              ).arc
                            ) * 100
                          ).toFixed(2)}
                        </StyledTableCell>
                      )
                    })}
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TabPanel>
            <TabPanel value={tapMenu} index={4}>
              <MTTR
                linePress={linePress}
                lineSpot={lineSpot}
                lineArcWelding={lineArcWelding}
                target={MTTRTarget}
              />
            </TabPanel>
            <TabPanel value={tapMenu} index={5}>
              <MTBF
                linePress={linePress}
                lineSpot={lineSpot}
                lineArcWelding={lineArcWelding}
                rowsMachineRuntime={rowsMachineRuntime}
                target={MTBFTarget}
              />
            </TabPanel>
            <TabPanel value={tapMenu} index={6}>
              <PmKpi
                data={dataGetPMKpi?.pmMachineKPI || []}
                target={PMTarget.target}
              />
            </TabPanel>
          </Grid>
        </ThemedCard>
      </div>
    </ThemeProvider>
  )
}
export default KPIMaintenance
