import { useState } from "react"
import Highcharts from "highcharts"

import { GET_MACHINE_REPAIR_TIMES } from "../../../../common/Query/MaintenanceQuery"
import { useQuery } from "@apollo/client"
import { Grid, useTheme } from "@mui/material"
import DateAdapter from "@mui/lab/AdapterMoment"
import { DatePicker, LocalizationProvider } from "@mui/lab"
import moment from "moment"
import ThemedTextField from "../../../../common/Resources/ThemedComponents/ThemedTextField"
import HighchartsReact from "highcharts-react-official"

interface IRepairMachineTime {
  machineCode: string
  sumTime: number
}

const MachineRepairTime = (props: { year: Date }): JSX.Element => {
  const { year } = props
  const [dateMonth, setDateMonth] = useState<Date>(new Date())
  const setFormatMonth = (dateMonth.getMonth() + 1).toString().padStart(2, "0")
  const setFormatYear = year.getFullYear().toString()
  const setFullFormatDate = setFormatYear + "-" + setFormatMonth
  const { data: pmRepair } = useQuery(GET_MACHINE_REPAIR_TIMES, {
    variables: { month: setFullFormatDate }
  })
  const oneHour = 60
  const convertRepairTimes = (
    repairTimes: IRepairMachineTime[] | undefined
  ): number[] => {
    return (
      repairTimes?.map((item: IRepairMachineTime) =>
        Number((item?.sumTime * oneHour).toFixed(2))
      ) ?? []
    )
  }
  const repairTimesInHours = convertRepairTimes(pmRepair?.getMachineRepairTimes)
  const theme = useTheme()

  const options: Highcharts.Options = {
    chart: {
      type: "column",
      backgroundColor: theme.palette.secondary.main
    },
    credits: { enabled: false },
    title: {
      text: "Machine Repair Time",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },
    xAxis: {
      categories: pmRepair?.getMachineRepairTimes?.map(
        (item: IRepairMachineTime) => item?.machineCode
      ),

      labels: {
        style: {
          color: theme.palette.secondary.contrastText,
          fontSize: "8px"
        }
      }
    },
    yAxis: {
      labels: {
        style: {
          color: theme.palette.secondary.contrastText
        }
      },
      title: {
        text: "Minute",
        style: {
          color: theme.palette.secondary.contrastText
        }
      }
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true
        }
      }
    },
    series: [
      {
        name: "MachineTime",
        type: "column",
        colorByPoint: false,
        data: repairTimesInHours
      }
    ],
    legend: {
      itemStyle: {
        fontSize: "10px",
        color: theme.palette.secondary.contrastText
      }
    },
    exporting: {
      enabled: false
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item container sm={12}>
        <Grid item sm={4}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DatePicker
              label="เลือกเดือน"
              views={["month"]}
              inputFormat="MM"
              value={dateMonth}
              onChange={(newSelectDate) => {
                setDateMonth(moment(newSelectDate).toDate() as Date)
              }}
              renderInput={(params) => (
                <ThemedTextField {...params} fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Grid item sm={12}>
        <div style={{ display: "inline-block", width: "100%" }}>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </Grid>
    </Grid>
  )
}

export default MachineRepairTime
