import { useLazyQuery } from "@apollo/client"
import {
  Box,
  Grid,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  useTheme,
  ThemeProvider,
  styled,
  tableCellClasses
} from "@mui/material"
import moment from "moment"
import React, { useState } from "react"
import { CSVLink } from "react-csv"
import { GET_INVENTORY_FG_REPORT } from "../../common/Query/WarehouseQuery"
import AutocompleteCustomer from "../../common/Resources/AutocompleteCustomer"
import AutocompletePartNo from "../../common/Resources/AutocompletePartNo"
import { menuItemList } from "../../common/Resources/menuItemList"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { formatNumber, logoTable } from "../../common/utilities"
import MultiChart from "./chartComponent/ColumnChart"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import "../../css/Warehouse/FGInventory.css"

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    border: 0
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 15,
    fontFamily: "Sarabun"
  }
}))

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {},

  "td, th": {
    border: 0,
    borderBottom: "1px solid black"
  }
}))

interface Column {
  id:
    | "partNo"
    | "partName"
    | "customer"
    | "remarks"
    | "balanceMaterial"
    | "inventoryCost"
    | "minStock"
    | "maxStock"
    | "saleOrder"
  label: string
  minWidth?: number
  align?: "right"
  format?: (value: number) => string
}

interface Data {
  balanceMaterial: number
  partName: string
  partNo: string
  maxStock: number
  minStock: number
  partType: string
  customer: string
  remarks: string
  saleOrder: number
  factory: string
  model: string
  cost: number
  inventoryCost: number
}

const pageTitle = "FG Inventory Report"

const columns: Column[] = [
  { id: "partNo", label: "Part No", minWidth: 50 },
  { id: "partName", label: "Part Name", minWidth: 100 },
  { id: "customer", label: "Customer", minWidth: 50 },
  { id: "balanceMaterial", label: "ยอดคงเหลือ(sheet/kg)", minWidth: 50 },
  { id: "inventoryCost", label: "Inventory Cost", minWidth: 50 },
  { id: "saleOrder", label: "Sale Order", minWidth: 50 },
  { id: "minStock", label: "Min Stock(3 วัน)", minWidth: 100 },
  { id: "maxStock", label: "Max Stock(10 วัน)", minWidth: 100 },
  { id: "remarks", label: "Remarks", minWidth: 100 }
]

const FGInventoryMaterial: React.FC = () => {
  const [partNo, setPartNo] = useState<string>("")
  const [date, setDate] = useState("")
  const [factory, setFactory] = useState<string>("1")
  const [customer, setCustomer] = useState<string>("")
  const [open, setOpen] = useState(false)

  const [inventoryReport, { data }] = useLazyQuery(GET_INVENTORY_FG_REPORT)
  let rows: Data[] = []
  const total = {
    totalFG: 0,
    totalSaleOrder: 0,
    totalInventoryCost: 0,
    date
  }
  if (data) {
    rows = data.fgInventoryMaterialReport.map((e: Data) => {
      return {
        ...e,
        inventoryCost: e.cost * e.balanceMaterial,
        remarks:
          e.maxStock < e.balanceMaterial && e.saleOrder
            ? "Over Stock 10 วัน"
            : e.minStock > e.balanceMaterial && e.saleOrder
            ? "Lost Stock 3 วัน"
            : ""
      }
    })

    rows.forEach(
      (e: {
        saleOrder: number
        balanceMaterial: number
        inventoryCost: number
      }) => {
        total.totalInventoryCost += e.inventoryCost
        total.totalSaleOrder += e.saleOrder
        total.totalFG += e.balanceMaterial
      }
    )
  }

  const theme = useTheme()
  const handleSubmit = async () => {
    setOpen(true)
    await inventoryReport({
      variables: {
        date,
        factory,
        partNo,
        customer
      },
      fetchPolicy: "network-only"
    })
    setOpen(false)
  }

  return (
    <ThemeProvider theme={theme}>
      <Box style={{ marginLeft: "22px" }} component="form">
        <PageLabel
          menuItem={menuItemList.Warehouse}
          menuItemName={Object.keys(menuItemList)[6]}
          menuPageName={pageTitle}
        />
        <ThemedCard style={{ marginTop: "26px" }} className="print-hide">
          <PageLabelCard
            title="FG Inventory Report"
            subTitle="detail about FG Inventory Report"
          />
          <Grid container spacing={2} width={"98%"} margin={2}>
            <Grid item xs={12} sm={3}>
              <ThemedTextField
                fullWidth
                value={date}
                id="dateFrom"
                type="date"
                label="Date From"
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => setDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <InputLabel>Factory</InputLabel>
                <Select
                  value={factory}
                  label="factory"
                  onChange={(e) => setFactory(e.target.value)}
                >
                  <MenuItem value={"1"}>1</MenuItem>
                  <MenuItem value={"2"}>2</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <AutocompleteCustomer
                setCustomer={setCustomer}
                customer={customer}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <AutocompletePartNo setPartNo={setPartNo} partNo={partNo} />
            </Grid>
            <Grid item xs={12} sm={10}>
              <ThemedButton
                variant="contained"
                onClick={handleSubmit}
                style={{ backgroundColor: theme.palette.primary.main }}
              >
                Submit
              </ThemedButton>
              <ThemedButton
                variant="outlined"
                onClick={() => window.print()}
                style={{ marginLeft: "15px" }}
              >
                Print
              </ThemedButton>
              <CSVLink
                data={rows.map((val) => {
                  return {
                    "Part No": val.partNo,
                    "Part Name": val.partName,
                    Customer: val.customer,
                    Inventory: val.balanceMaterial,
                    "Inventory Cost": val.inventoryCost
                  }
                })}
                filename={`${customer}-${moment(date).format(
                  "DD-MM-YYYY"
                )}-FG-Inventory`}
              >
                <ThemedButton
                  variant="contained"
                  style={{
                    marginLeft: "15px",
                    backgroundColor: "yellowgreen"
                  }}
                >
                  Download CSV
                </ThemedButton>
              </CSVLink>
            </Grid>
          </Grid>
        </ThemedCard>
        <Dialog open={open}>
          <DialogContent style={{ width: "auto" }}>
            <CircularProgress />
          </DialogContent>
        </Dialog>
        <ThemedCard>
          <Table className="fg-inventory-table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell
                  align="center"
                  colSpan={3}
                  style={{ textAlign: "center" }}
                >
                  <img src={logoTable} alt="" height={"80hv"}></img>
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  colSpan={3}
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "28px"
                  }}
                >
                  FG Inventory Material Report
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  colSpan={3}
                  style={{ fontWeight: "bold", textAlign: "center" }}
                >
                  F-PL-03-10
                  <br /> Effective Date 1-8-22 Rev.00
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ top: 57, minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => {
                return (
                  <StyledTableRow hover tabIndex={-1} key={i}>
                    {columns.map((column) => {
                      const value = row[column.id]
                      return (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={
                            column.id === "remarks"
                              ? { color: "red" }
                              : { color: "black" }
                          }
                        >
                          {column.id === "inventoryCost" &&
                          typeof value === "number"
                            ? formatNumber(value)
                            : column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </StyledTableCell>
                      )
                    })}
                  </StyledTableRow>
                )
              })}
            </TableBody>
          </Table>
          <br />
          <br />
          <Grid container spacing={2} marginTop={2} className="page-break">
            <Grid item xs={12} sm={8} className="chart-inventory">
              <MultiChart data={total}></MultiChart>
            </Grid>
            <Grid
              item
              sm={4}
              container
              justifyContent={"center"}
              marginTop={"26px"}
            >
              <Table
                style={{
                  width: "350px",
                  border: "1px solid black",
                  height: "300px"
                }}
              >
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell
                      style={{
                        textAlign: "center",
                        border: "1px solid black",
                        fontSize: "20px"
                      }}
                    >
                      Inventory FG
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        textAlign: "right",
                        fontSize: "20px"
                      }}
                    >
                      {formatNumber(total.totalFG)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      style={{
                        textAlign: "center",
                        border: "1px solid black",
                        fontSize: "20px"
                      }}
                    >
                      Inventory Cost
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        textAlign: "right",
                        fontSize: "20px"
                      }}
                    >
                      {formatNumber(total.totalInventoryCost)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      style={{
                        textAlign: "center",
                        border: "1px solid black",
                        fontSize: "20px"
                      }}
                    >
                      Total Sale Order
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        textAlign: "right",
                        fontSize: "20px"
                      }}
                    >
                      {formatNumber(total.totalSaleOrder)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      style={{
                        textAlign: "center",
                        border: "1px solid black",
                        fontSize: "20px"
                      }}
                    >
                      Inventory Date
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        textAlign: "right",
                        fontSize: "20px"
                      }}
                    >
                      {moment(date).format("DD-MM-YYYY")}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item sm={8} />
            <Grid item sm={4} container justifyContent={"center"}>
              <Table
                style={{
                  width: "350px",
                  border: "1px solid black",
                  height: "100px"
                }}
              >
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell
                      height={100}
                      width={200}
                      style={{
                        border: "1px solid black"
                      }}
                    ></StyledTableCell>
                    <StyledTableCell
                      height={100}
                      width={200}
                      style={{
                        border: "1px solid black"
                      }}
                    ></StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      style={{
                        textAlign: "center",
                        border: "1px solid black"
                      }}
                    >
                      Approved
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        textAlign: "center",
                        border: "1px solid black"
                      }}
                    >
                      Checked
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </ThemedCard>
      </Box>
    </ThemeProvider>
  )
}
export default FGInventoryMaterial
