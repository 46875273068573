import { LocalizationProvider, DatePicker } from "@mui/lab"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
  Autocomplete,
  useTheme
} from "@mui/material"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import moment from "moment"
import { useEffect, useRef, useState } from "react"
import DateAdapter from "@mui/lab/AdapterMoment"
import ThemedCard from "../../../common/Resources/ThemedComponents/ThemedCard"
import { ICapacity, IHoliday, ILine } from "../hook/useFetchData"

interface IDataSeries {
  name: string
  data: number[]
  type: string
}

export const ChartCapacity = (props: {
  chart?: HighchartsReact.Props
  line: string
  date: Date
  capacity: ICapacity[] | undefined
  holiday: IHoliday[]
}): JSX.Element => {
  const { line, capacity, holiday, date } = props
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const [dataSeries, setDataSeries] = useState<IDataSeries[]>([])
  const [manShitDay, setManShitDay] = useState<number>(0)
  const [manShitNight, setManShitNight] = useState<number>(0)
  const theme = useTheme()

  useEffect(() => {
    if (capacity) {
      const line = capacity.filter(
        (e: { line: string }) => e.line === props.line
      )
      const customerList: string[] = Array.from(
        new Set(line.map((e: { customer: string }) => e.customer))
      )
      const machineList = Array.from(
        new Set(line.map((e: { machine: string }) => e.machine))
      )

      const columnData: {
        name: string
        type: string
        colorByPoint?: boolean
        data: number[]
      }[] = []
      const totalHour: number[][] = []
      customerList?.forEach((customer) => {
        const dataCustomer = line.filter(
          (capacity: { customer: string }) => capacity.customer === customer
        )
        const dataMachine = machineList.map((machine) => {
          const dataJph = dataCustomer.find(
            (capacity: { machine: string }) => capacity.machine === machine
          )
          return Number(dataJph?.jph.toFixed(2))
        })

        const result = {
          name: customer,
          type: "column",
          colorByPoint: true,
          data: dataMachine.slice(0, machineList.length).map((value) => {
            return isNaN(value) ? 0 : value
          })
        }
        const hour = dataMachine.map((value) => {
          return isNaN(value) ? 0 : value
        })

        totalHour.push(hour)
        columnData.push(result)
      })
      let sums: number[] = []
      if (totalHour.length) {
        sums = totalHour[0].map((_, index) => {
          return totalHour.reduce((acc, curr) => {
            return acc + curr[index]
          }, 0)
        })
      }
      if (sums.length) {
        const manDay = Math.ceil(
          sums
            .map((hour) => {
              return hour >= 8 * workingPerMonth(month, year)
                ? 8 * workingPerMonth(month, year)
                : hour
            })
            .reduce((prev, curr) => prev + curr) /
            (8 * workingPerMonth(month, year))
        )
        const nightDay = sums.filter(
          (hours) => hours >= 8 * workingPerMonth(month, year)
        )
        let manNight = 0
        if (nightDay.length)
          manNight = Math.ceil(
            nightDay
              .map((hour) => hour - 8 * workingPerMonth(month, year))
              .reduce((prev, curr) => prev + curr) /
              (8 * workingPerMonth(month, year))
          )
        setManShitDay(manDay)
        setManShitNight(manNight)
      }

      const targetShift1 = [
        {
          name: "shift 1",
          type: "line",
          data: Array.from(
            Array(machineList.length),
            () => 8 * workingPerMonth(month, year)
          ).slice(0, machineList.length)
        }
      ]
      const targetShift2 = [
        {
          name: "shift 2",
          type: "line",
          data: Array.from(
            Array(machineList.length),
            () => 16 * workingPerMonth(month, year)
          ).slice(0, machineList.length)
        }
      ]

      const targetShift3 = [
        {
          name: "maximum",
          type: "line",
          data: Array.from(
            Array(machineList.length),
            () => 16 * 30 + 2.5 * 24
          ).slice(0, machineList.length)
        }
      ]
      const mergeShift = targetShift1.concat(targetShift2).concat(targetShift3)
      setDataSeries(columnData.concat(mergeShift))
    }
  }, [capacity, line])

  if (!capacity?.length) return <label>...Loading</label>
  const machineList = Array.from(
    new Set(
      capacity
        ?.filter((e: { line: string }) => e.line === props.line)
        .map((e: { machine: string }) => e.machine)
    )
  ).slice(0, 32) as string[]

  const monthYear = moment(date).format("DD-MM-YYYY").split("-")
  const month = Number(monthYear[1])
  const year = Number(monthYear[2])

  const workingPerMonth = (month: number, year: number) => {
    const weekdays: string[] = [
      "Saturday",
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday"
    ]
    const holidays: string[] = holiday.map(
      ({ holiday }: { holiday: string }) => holiday
    )

    const daysInMonth: number = new Date(year, month, 0).getDate()
    let workingDays = 0

    for (let day = 1; day <= daysInMonth; day++) {
      const date: Date = new Date(year, month - 1, day + 1)
      const dayOfWeek: string = weekdays[date.getDay()]

      if (
        dayOfWeek !== "Sunday" &&
        !holidays?.includes(date.toISOString().substring(0, 10))
      ) {
        workingDays++
      }
    }
    return workingDays
  }

  const options: Highcharts.Options = {
    title: {
      text: `Capacity ${line}`,
      style: {
        color: theme.palette.secondary.contrastText
      }
    },
    credits: { enabled: false },
    chart: {
      type: "column",
      backgroundColor: theme.palette.secondary.main
    },
    xAxis: {
      categories: machineList,
      labels: {
        style: {
          color: theme.palette.secondary.contrastText,
          fontSize: "8px"
        }
      }
    },
    yAxis: {
      labels: {
        style: {
          color: theme.palette.secondary.contrastText
        }
      },
      min: 0,
      title: {
        text: "Performant Per Hours",
        style: {
          color: theme.palette.secondary.contrastText
        }
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: "bold",
          color: theme.palette.secondary.contrastText,
          textOutline: "none"
        }
      }
    },
    legend: {
      align: "left",
      x: 70,
      verticalAlign: "top",
      y: 70,
      floating: false,
      backgroundColor: theme.palette.secondary.main,
      borderColor: "#CCC",
      borderWidth: 1,
      shadow: false,
      itemStyle: {
        color: theme.palette.secondary.contrastText
      }
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}"
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true
        }
      }
    },
    series: dataSeries?.map((e) => {
      return {
        name: e.name,
        data: e.data,
        type: e.type === "column" ? "column" : "line",
        color:
          e.type === "line" && e.data && e.data[0] === 192
            ? "black"
            : e.type === "line" && e.data && e.data[0] === 384
            ? "orange"
            : e.type === "line" && e.data && e.data[0] === 540
            ? "red"
            : ""
      }
    }),
    exporting: {
      enabled: false
    }
  }
  return (
    <ThemedCard>
      <h3 style={{ textAlign: "right" }}>
        Man Power Day : {manShitDay} Night : {manShitNight}
      </h3>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartComponentRef}
        {...props.chart}
      />
    </ThemedCard>
  )
}
interface ICapacityProp {
  lineList: ILine[]
  dialogOpen: boolean
  setDialogOpen: (dialogOpen: boolean) => void
  line: string
  setLine: (line: string) => void
  month: Date
  setMonth: (date: Date) => void
  capacity: ICapacity[] | undefined
  holiday: IHoliday[]
}

const Capacity = (props: ICapacityProp): JSX.Element => {
  const {
    dialogOpen,
    setDialogOpen,
    line,
    setLine,
    lineList,
    month,
    setMonth,
    capacity,
    holiday
  } = props

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle
          id="alert-dialog-title"
          textAlign={"center"}
          fontSize="25px"
        >
          Capacity Monthly
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={3} xs={12} justifyContent={"center"} margin={2}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  label="เลือกเดือน"
                  views={["year", "month"]}
                  value={new Date()}
                  onChange={(newSelectDate) => {
                    setMonth(moment(newSelectDate).toDate() as Date)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      value={moment(month).format("MM-YYYY")}
                      inputProps={{
                        readOnly: true
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sm={3} xs={12} justifyContent={"center"} margin={2}>
              <Autocomplete
                fullWidth
                id="line"
                value={line}
                options={lineList.map((e) => e.line)}
                disablePortal
                onChange={(e, value) => setLine(value || "")}
                renderInput={(params) => <TextField {...params} label="Line" />}
              />
            </Grid>
            <Grid item sm={12} xs={12} justifyContent="center">
              <ChartCapacity
                date={month}
                line={line}
                capacity={capacity}
                holiday={holiday}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ backgroundColor: "red" }}
            onClick={() => {
              setDialogOpen(false)
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default Capacity
