import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import { ThemeProvider } from "@mui/material/styles"
import { SetStateAction, useRef, useState } from "react"
import {
  Typography,
  Autocomplete,
  Switch,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useTheme,
  InputLabel,
  Stack,
  Paper
} from "@mui/material"
import QrReader from "react-qr-reader"
import moment from "moment"
import "../../css/Scanner/scanner.css"
import { useEffect } from "react"
import React from "react"
import { GET_LINE } from "../../common/Query/GeneralQuery"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { GET_EMPLOYEE, GET_MACHINES } from "../../common/Query/MasterQuery"
import { GET_PART_PROCESSING } from "../../common/Query/MaterialQuery"

import { ADD_TEST_FG } from "../../common/Mutation/ScannerMutation"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import CameraAltIcon from "@mui/icons-material/CameraAlt"
import { factoryList } from "../../common/utilities"
import { menuItemList } from "../../common/Resources/menuItemList"
import swal from "sweetalert"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import DatePickerCustom from "../../common/Resources/DatePickerCustom"
import { GET_TODAY_PLATING_THICKNESS } from "../../common/Query/ScannerQuery"

const pageTitle = "Scanner"

const Scanner: React.FC = () => {
  const theme = useTheme()
  const [isStream, setIsStream] = useState(false)
  const [dataScan, setDataScan] = useState("")
  const [prodDate, setProdDate] = useState("")
  const [pdLotNo, setPdLotNo] = useState("")
  const [jobOrder, setJobOrder] = useState("")
  const [partNo, setPartNo] = useState("")
  const [oldPartNo, setOldPartNo] = useState<string>("")
  const [boxNo, setBoxNo] = useState("")
  const [listLine, setListLine] = useState([])
  const [prodLine, setProdLine] = useState("")
  const [factory, setFactory] = useState<string>("")
  const [shift, setShift] = useState<string>("Day")
  const [machineList, setMachineList] = useState([])
  const [machine, setMachine] = useState("")
  const [processingList, setProcessingList] = useState([])
  const [process, setProcess] = useState<string>("")
  const [employeeList, setEmployeeList] = useState([])
  const [employee, setEmployee] = useState<string | null>("")
  const [actionDate, setActionDate] = useState<Date>(new Date())
  const [quantity, setQuantity] = useState(0)
  const [componentLot, setComponentLot] = useState<string>("")
  const [tNG, setTNG] = useState(0)
  const [iNG, setING] = useState(0)
  const [sNG, setSNG] = useState(0)
  const [supplierNG, setSupplierNG] = useState(0)
  const [scratch, setScratch] = useState(0)
  const [deformed, setDeformed] = useState(0)
  const [torn, setTorn] = useState(0)
  const [dirtyStains, setDirtyStains] = useState(0)
  const [rust, setRust] = useState(0)
  const [fin, setFin] = useState(0)
  const [woodSize, setWoodSize] = useState(0)
  const [BypassProcessStamp, setBypassProcessStamp] = useState(0)
  const [OtherStamp, setOtherStamp] = useState(0)
  const [offset, setOfset] = useState(0)
  const [wrongposition, setWrongposition] = useState(0)
  const [wrongsize, setWrongsize] = useState(0)
  const [notstuck, setNotstuck] = useState(0)
  const [noWelding, setNoWelding] = useState(0)
  const [skipProcessWeld, setSkipProcessWeld] = useState(0)
  const [anotherWeld, setAnotherWeld] = useState(0)
  const [nonstickplating, setNonstickplating] = useState(0)
  const [inflatablepaint, setInflatablepaint] = useState(0)
  const [otherPlate, setOtherPlate] = useState(0)
  const [intermittentwelding, setIntermittentwelding] = useState(0)
  const [onesidewelded, setOnesidewelded] = useState(0)
  const [tworoundsofwelding, setTworoundsofwelding] = useState(0)
  const [weldthrough, setWeldthrough] = useState(0)
  const [weldaccordingto, setWeldaccordingto] = useState(0)
  const [forgettoturnoffCO2, setForgettoturnoffCO2] = useState(0)
  const [deformedworkpiece, setDeformedworkpiece] = useState(0)
  const [otherArcweld, setOtherArcweld] = useState(0)
  const [incompletewelding, setIncompletewelding] = useState(0)
  const [totalING, setTotalING] = useState(0)
  const [remarks, setRemarks] = useState("")
  const [productionWorker, setProductionWorker] = useState<string | null>("")
  const getLine = useQuery(GET_LINE)
  const getPartprocessing = useQuery(GET_PART_PROCESSING)
  const getEmployee = useQuery(GET_EMPLOYEE)
  const getMachines = useQuery(GET_MACHINES)
  const [load, setLoad] = useState<boolean>(false)
  const [addTestFG] = useMutation(ADD_TEST_FG)
  const [expanded, setExpanded] = useState<string | false>("")
  const [rework, setRework] = useState(0)
  const [nonStickPlatingRework, setNonStickPlatingRework] = useState(0)
  const [woodJigFragment, setWoodJigFragment] = useState(0)
  const [solutionSpill, setSolutionSpill] = useState(0)
  const [solutionStain, setSolutionStain] = useState(0)
  const [needlePitting, setNeedlePitting] = useState(0)
  const [oilPitting, setOilPitting] = useState(0)
  const [greasyBulgingColor, setGreasyBulgingColor] = useState(0)
  const [roughSurface, setRoughSurface] = useState(0)
  const [nonStickBlackSpotPlating, setNonStickBlackSpotPlating] = useState(0)
  const [colorStain, setColorStain] = useState(0)
  const [peelSwellWorkPiece, setPeelSwellWorkPiece] = useState(0)
  const [burnWorkPiece, setBurnWorkPiece] = useState(0)
  const [greasyStainSolution, setGreasyStainSolution] = useState(0)
  const [whiteStain, setWhiteStain] = useState(0)
  const [firstPieceThickness, setFirstPieceThickness] = useState(0)
  const [secondPieceThickness, setSecondPieceThickness] = useState(0)
  const [thirdPieceThickness, setThirdPieceThickness] = useState(0)
  const [thickFormDisable, setThickFormDisable] = useState(false)
  const previousProdLine = useRef("")
  const [getTodayPlatingThickness, { data: thicknessData }] = useLazyQuery(
    GET_TODAY_PLATING_THICKNESS
  )

  const sendMessage = (msg: string) => {
    try {
      if (socket && socket.readyState === WebSocket.OPEN) {
        const messagePayload = {
          action: "sendMessage", // This should match your routeKey in API Gateway
          message: msg
        }

        socket.send(JSON.stringify(messagePayload))
      }
    } catch (e) {
      swal("Error", `${e}`, "warning")
    }
  }

  const [socket, setSocket] = useState<WebSocket | null>(null)

  useEffect(() => {
    // Replace with your WebSocket URL from AWS API Gateway
    const wsUrl =
      "wss://dht6wg9m3k.execute-api.ap-southeast-1.amazonaws.com/production/"
    const ws = new WebSocket(wsUrl)

    ws.onopen
    setSocket(ws)
  }, [])

  const handleGetTodayPlatingThickness = () => {
    getTodayPlatingThickness({
      variables: {
        jobOrderInput: jobOrder
      },
      fetchPolicy: "network-only"
    })
  }

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  useEffect(() => {
    if (getLine.data)
      setListLine(
        getLine.data.getLine
          .map((e: { lineName: string }) => e.lineName)
          .filter((e: string) => e !== "LINE MT")
      )
    if (getMachines.data) setMachineList(getMachines.data.machines)
    if (getPartprocessing.data)
      setProcessingList(getPartprocessing.data.partsprocessings)
    if (getEmployee.data) setEmployeeList(getEmployee.data.employee)
    setTotalING(
      scratch +
        deformed +
        torn +
        dirtyStains +
        rust +
        fin +
        woodSize +
        BypassProcessStamp +
        OtherStamp +
        offset +
        wrongposition +
        wrongsize +
        notstuck +
        noWelding +
        skipProcessWeld +
        anotherWeld +
        nonstickplating +
        inflatablepaint +
        otherPlate
    )
  }, [
    BypassProcessStamp,
    OtherStamp,
    anotherWeld,
    deformed,
    fin,
    getEmployee.data,
    getLine.data,
    getMachines.data,
    getPartprocessing.data,
    inflatablepaint,
    noWelding,
    nonstickplating,
    notstuck,
    offset,
    otherPlate,
    rust,
    scratch,
    skipProcessWeld,
    torn,
    dirtyStains,
    woodSize,
    wrongposition,
    wrongsize
  ])

  useEffect(() => {
    if (prodLine !== previousProdLine.current) {
      setRework(0)
      setNonStickPlatingRework(0)
      setWoodJigFragment(0)
      setSolutionSpill(0)
      setSolutionStain(0)
      setNeedlePitting(0)
      setOilPitting(0)
      setGreasyBulgingColor(0)
      setRoughSurface(0)
      setNonStickBlackSpotPlating(0)
      setColorStain(0)
      setPeelSwellWorkPiece(0)
      setBurnWorkPiece(0)
      setGreasyStainSolution(0)
      setWhiteStain(0)
      previousProdLine.current = prodLine
    } else {
      setRework(
        nonStickPlatingRework +
          woodJigFragment +
          solutionSpill +
          solutionStain +
          needlePitting +
          oilPitting +
          greasyBulgingColor +
          roughSurface +
          nonStickBlackSpotPlating +
          colorStain +
          peelSwellWorkPiece +
          burnWorkPiece +
          greasyStainSolution +
          whiteStain
      )
    }
  }, [
    prodLine,
    nonStickPlatingRework,
    woodJigFragment,
    solutionSpill,
    solutionStain,
    needlePitting,
    oilPitting,
    greasyBulgingColor,
    roughSurface,
    nonStickBlackSpotPlating,
    colorStain,
    peelSwellWorkPiece,
    burnWorkPiece,
    greasyStainSolution,
    whiteStain
  ])

  useEffect(() => {
    if (jobOrder) {
      handleGetTodayPlatingThickness()
    }
  }, [jobOrder])
  useEffect(() => {
    if (
      thicknessData &&
      thicknessData.getTodayPlatingThickness &&
      thicknessData.getTodayPlatingThickness.length > 0
    ) {
      setFirstPieceThickness(
        thicknessData.getTodayPlatingThickness[0].firstPieceThickness
      )
      setSecondPieceThickness(
        thicknessData.getTodayPlatingThickness[0].secondPieceThickness
      )
      setThirdPieceThickness(
        thicknessData.getTodayPlatingThickness[0].thirdPieceThickness
      )
      setThickFormDisable(true)
    } else {
      setFirstPieceThickness(0)
      setSecondPieceThickness(0)
      setThirdPieceThickness(0)
      setThickFormDisable(false)
    }
  }, [thicknessData])

  const checkQuantity = async () => {
    if (prodLine === "QC") setING(totalING)
    if (totalING !== iNG) {
      swal("Error", "กรุณากรอกรายละเอียดของเสีย", "warning")
      return false
    }
    return true
  }

  const isComplete = () => {
    if (tNG || iNG || sNG || supplierNG) {
      return (
        partNo !== "" &&
        moment(actionDate).format("YYYY-MM-DD") !== "" &&
        employee !== "" &&
        pdLotNo !== "" &&
        boxNo !== "" &&
        prodLine !== "" &&
        prodDate !== "" &&
        factory !== "" &&
        process !== "" &&
        shift !== ""
      )
    } else {
      return (
        partNo !== "" &&
        moment(actionDate).format("YYYY-MM-DD") !== "" &&
        employee !== "" &&
        pdLotNo !== "" &&
        quantity !== 0 &&
        boxNo !== "" &&
        prodLine !== "" &&
        prodDate !== "" &&
        factory !== "" &&
        process !== "" &&
        shift !== ""
      )
    }
  }
  const processLineConsistency = () => {
    const lineIsSpot = prodLine.includes("SPOT")
    const processIsSpot = process?.includes("SPOT")
    return (lineIsSpot && processIsSpot) || (!lineIsSpot && !processIsSpot)
  }

  const onReset = () => {
    setDataScan("")
    setPdLotNo("")
    setOldPartNo(partNo)
    setPartNo("")
    setJobOrder("")
    setProdDate("")
    setBoxNo("")
    setQuantity(0)
    setTNG(0)
    setING(0)
    setSNG(0)
    setSupplierNG(0)
    setScratch(0)
    setDeformed(0)
    setTorn(0)
    setDirtyStains(0)
    setRust(0)
    setFin(0)
    setWoodSize(0)
    setBypassProcessStamp(0)
    setOtherStamp(0)
    setOfset(0)
    setWrongposition(0)
    setWrongsize(0)
    setNotstuck(0)
    setNoWelding(0)
    setSkipProcessWeld(0)
    setAnotherWeld(0)
    setNonstickplating(0)
    setInflatablepaint(0)
    setOtherPlate(0)
    setIntermittentwelding(0)
    setOnesidewelded(0)
    setTworoundsofwelding(0)
    setWeldthrough(0)
    setWeldaccordingto(0)
    setForgettoturnoffCO2(0)
    setDeformedworkpiece(0)
    setOtherArcweld(0)
    setIncompletewelding(0)
    setTotalING(0)
    setRemarks("")
    setMachine("")
    setComponentLot("")
    setNonStickPlatingRework(0)
    setWoodJigFragment(0)
    setSolutionSpill(0)
    setSolutionStain(0)
    setNeedlePitting(0)
    setOilPitting(0)
    setGreasyBulgingColor(0)
    setRoughSurface(0)
    setNonStickBlackSpotPlating(0)
    setColorStain(0)
    setPeelSwellWorkPiece(0)
    setBurnWorkPiece(0)
    setGreasyStainSolution(0)
    setWhiteStain(0)
    setFirstPieceThickness(0)
    setSecondPieceThickness(0)
    setThirdPieceThickness(0)
    previousProdLine.current = ""
  }

  const checkMachine = () => {
    if (
      prodLine === "QC" ||
      prodLine === "LINE EDP" ||
      prodLine === "LINE ZINC" ||
      prodLine === "PLATING"
    ) {
      return true
    } else {
      return machine ? true : false
    }
  }

  const checkThicknessValue = () => {
    return !(
      (process === "EDP" ||
        process === "ZINC" ||
        process === "PLATING EDP" ||
        process === "PLATING ZINC") &&
      (firstPieceThickness <= 0 ||
        secondPieceThickness <= 0 ||
        thirdPieceThickness <= 0)
    )
  }

  const shiftList = ["Day", "Night"]

  const handleSubmit = async () => {
    setLoad(true)

    if (!isComplete()) {
      swal("Error", "กรุณากรอกข้อมูลให้ครบ", "warning")

      setLoad(false)
      return
    }

    if (!checkMachine()) {
      swal("Error", "กรุณากรอกข้อมูลให้ครบ", "warning")
      setLoad(false)
      return
    }
    if (!(await checkQuantity())) {
      setLoad(false)
      return
    }

    if (!processLineConsistency()) {
      swal("Error", "Line กับ Process ไม่ตรงกัน!", "warning")
      setLoad(false)
      return
    }
    if (!prodLine.toLocaleLowerCase().includes("os-")) {
      if (!checkThicknessValue()) {
        swal("Error", "ความหนาของชิ้นงานกรอกไม่ครบ 3 ชิ้น", "warning")
        setLoad(false)
        return
      }
    }

    const data = {
      partNo,
      actionDate: moment(actionDate).format("YYYY-MM-DD"),
      quantity,
      factory,
      packingBy: employee,
      pdLotNo,
      inOrOut: 1,
      tNG,
      iNG,
      sNG,
      supplierNG,
      jobOrder,
      machine,
      line: prodLine,
      process,
      boxNo,
      prodDate,
      scratched: scratch,
      deformed,
      torn,
      dirtyStains,
      rusted: rust,
      fin,
      incorrectStampSize: woodSize,
      skipProcessStamp: BypassProcessStamp,
      otherStamp: OtherStamp,
      offset,
      incorrectWeldPosition: wrongposition,
      incorrectWeldSize: wrongsize,
      looseWelding: notstuck,
      noWelding,
      skipProcessWeld,
      otherWeld: anotherWeld,
      loosePlating: nonstickplating,
      discoloredPlating: inflatablepaint,
      otherPlate,
      discontinuousWeld: intermittentwelding,
      oneSidedWeld: onesidewelded,
      twiceWelded: tworoundsofwelding,
      puncturedWeld: weldthrough,
      messyWeld: weldaccordingto,
      incompleteWeld: incompletewelding,
      forgetToTurnOffCO2: forgettoturnoffCO2,
      deformedArcweld: deformedworkpiece,
      otherArcweld,
      componentLot,
      remarks,
      productionWorker,
      shift,
      rework,
      nonStickPlatingRework,
      woodJigFragment,
      solutionSpill,
      solutionStain,
      needlePitting,
      oilPitting,
      greasyBulgingColor,
      roughSurface,
      nonStickBlackSpotPlating,
      colorStain,
      peelSwellWorkPiece,
      burnWorkPiece,
      greasyStainSolution,
      whiteStain,
      firstPieceThickness,
      secondPieceThickness,
      thirdPieceThickness
    }
    const sendData = {
      partNo,
      process,
      machine,
      actionDate: moment(actionDate).format("YYYY-MM-DD"),
      quantity
    }
    try {
      await addTestFG({
        variables: { data }
      })
      sendMessage(JSON.stringify(sendData))
      swal("Success", "บันทึกสำเร็จ", "success")
      onReset()
      setLoad(false)
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "warning")
      setLoad(false)
    }
  }

  const handleScan = (data: SetStateAction<string> | null): void => {
    if (data) {
      setDataScan(data)
      if (dataScan) {
        breakQRCode()
      }
    }
  }

  const handleError = (err: string) => {
    swal("Error", `${err}`, "warning")
  }

  const breakQRCode = async (e?: { preventDefault: () => void }) => {
    e?.preventDefault()
    const newSplit = dataScan.trim().split("|")
    if (newSplit.length !== 6) {
      swal("Error", "มีปัญหากับ format ของ QR Code!", "warning")
    }
    setProdDate(moment(newSplit[5], "DD-MM-YY").format("YYYY-MM-DD"))
    setPdLotNo(`${newSplit[5]}|${newSplit[4]}`)
    setJobOrder(`${newSplit[0]}|${newSplit[1]}|${newSplit[2]}`)
    setPartNo(newSplit[4])
    setBoxNo(newSplit[3])
    alert(dataScan)
    if (oldPartNo && oldPartNo !== newSplit[4]) {
      setProcess("")
    }
  }
  const listEmployee = Array.from(
    new Set(
      employeeList
        .filter((e: { departmentId: number; factory: string }) => {
          if (prodLine === "QC")
            return e.departmentId === 6 && e.factory === factory
          if (prodLine === "LINE SPOT")
            return e.departmentId === 4 && e.factory === factory
          if (prodLine === "PLATING")
            return e.departmentId === 15 && e.factory === factory
          if (
            prodLine !== "LINE SPOT" &&
            prodLine !== "QC" &&
            prodLine !== "PLATING" &&
            prodLine !== "LINE EDP" &&
            prodLine !== "LINE ZINC"
          )
            return e.departmentId === 4 && e.factory === factory
          if (prodLine === "LINE ZINC" || prodLine === "LINE EDP")
            return e.departmentId === 15 && e.factory === factory
        })
        .map((e: { firstName: string }) => e.firstName)
    )
  )
  return (
    <>
      <PageLabel
        menuItem={menuItemList.Scanner}
        menuItemName={Object.keys(menuItemList)[11]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <Box component="form" onSubmit={breakQRCode}>
          <Accordion
            expanded={isStream}
            onChange={() => setIsStream(!isStream)}
            style={{
              marginBottom: "5px",
              marginTop: "10px"
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ backgroundColor: "#ffffff", display: "none" }}
            />

            <AccordionDetails>
              {isStream ? (
                <>
                  <Grid container justifyContent={"center"}>
                    <Grid
                      item
                      sm={3}
                      xs={12}
                      container
                      justifyContent={"center"}
                    >
                      <QrReader
                        delay={300}
                        onError={handleError}
                        onScan={handleScan}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </AccordionDetails>
          </Accordion>
          <PageLabelCard title="WIP Out" subTitle="detail about WIP Out" />
          <ThemeProvider theme={theme}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <Grid container spacing={2} width={"98%"} margin={2}>
                <Grid item xs={12} sm={12}>
                  <Switch
                    checked={isStream}
                    onChange={(event) =>
                      setIsStream(event.target.value ? !isStream : isStream)
                    }
                    name="gilad"
                  />
                  <label style={{ fontSize: "14px" }}>
                    Active Camera{" "}
                    <CameraAltIcon style={{ verticalAlign: "middle" }} />
                  </label>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <ThemedTextField
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    id="scanner"
                    value={dataScan}
                    label="scanner"
                    onChange={(e) => {
                      setDataScan(e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={7} />
                <Grid item xs={12} sm={12}>
                  <ThemedTextField
                    fullWidth
                    className="disable-field"
                    disabled
                    label="Production Lot No."
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={pdLotNo}
                    id="Production Lot No."
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ThemedTextField
                    fullWidth
                    disabled
                    InputLabelProps={{
                      shrink: true
                    }}
                    label="Part No."
                    className="disable-field"
                    value={partNo}
                    id="partNo"
                    autoComplete="family-name"
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <ThemedTextField
                    fullWidth
                    className="disable-field"
                    disabled
                    InputLabelProps={{
                      shrink: true
                    }}
                    label="Mat'l Job Order"
                    value={jobOrder}
                    id="JobOrder"
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ThemedTextField
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    label="Production Date"
                    className="disable-field"
                    disabled
                    value={prodDate}
                    id="ProductionDate"
                    autoComplete="family-name"
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <ThemedTextField
                    InputLabelProps={{
                      shrink: true
                    }}
                    label="Box No."
                    className="disable-field"
                    fullWidth
                    disabled
                    value={boxNo}
                    id="Box."
                    autoComplete="family-name"
                  />
                </Grid>

                <Grid item xs={12} sm={2.4}>
                  <Autocomplete
                    fullWidth
                    value={factory}
                    options={factoryList}
                    disablePortal
                    onChange={(e, value) => setFactory(value ? value : "")}
                    renderInput={(params) => (
                      <ThemedTextField
                        {...params}
                        label="Factory"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2.4}>
                  <Autocomplete
                    fullWidth
                    value={prodLine}
                    options={listLine}
                    disablePortal
                    onChange={(e, value) => {
                      setProdLine(value ? value : "")
                      setMachine("")
                      setProcess("")
                    }}
                    renderInput={(params) => (
                      <ThemedTextField
                        {...params}
                        label="Production Line"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={2.4}>
                  <Autocomplete
                    fullWidth
                    value={machine}
                    options={machineList
                      .filter(
                        (e: {
                          line: string
                          factory: string
                          priority: string
                        }) => {
                          if (e.factory === "2") {
                            return e.line === prodLine && e.factory === factory
                          } else {
                            return e.line === prodLine && e.factory === factory
                          }
                        }
                      )
                      .map(({ machine }: { machine: string }) => machine)}
                    disablePortal
                    onChange={(e, value) => {
                      setMachine(value ? value : "")
                    }}
                    renderInput={(params) => (
                      <ThemedTextField
                        {...params}
                        label="M/C(หมายเลขเครื่องจักร)"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={2.4}>
                  <Autocomplete
                    fullWidth
                    value={process}
                    options={processingList
                      .filter((e: { partNo: string }) => {
                        return e.partNo === partNo
                      })
                      .map(
                        ({ processOrder }: { processOrder: string }) =>
                          processOrder
                      )}
                    disablePortal
                    onChange={(e, value) => {
                      setProcess(value ? value : "")
                    }}
                    renderInput={(params) => (
                      <ThemedTextField
                        {...params}
                        label="Process"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={2.4}>
                  <DatePickerCustom
                    date={actionDate}
                    setDate={setActionDate}
                    label={"วันที่ผลิต"}
                  />
                </Grid>
                <Grid item xs={12} sm={2.4}>
                  <Autocomplete
                    fullWidth
                    value={shift}
                    options={shiftList}
                    disablePortal
                    onChange={(e, value) => setShift(value ? value : "")}
                    renderInput={(params) => (
                      <ThemedTextField
                        {...params}
                        label="Shift"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2.4}>
                  <ThemedTextField
                    fullWidth
                    id="quantity"
                    label="จำนวนชิ้น"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={quantity ? quantity : ""}
                    placeholder="Enter the Quantity"
                    onChange={(e) => setQuantity(+e.target.value)}
                    autoComplete="family-name"
                  />
                </Grid>

                <Grid item xs={12} sm={2.4}>
                  <ThemedTextField
                    fullWidth
                    id="TNG."
                    label="T-NG(ปรับแต่งแม่พิมพ์)"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={tNG}
                    onChange={(e) => setTNG(+e.target.value)}
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12} sm={2.4}>
                  <ThemedTextField
                    fullWidth
                    label="I-NG(ของเสียในกระบวนการ)"
                    InputLabelProps={{
                      shrink: true
                    }}
                    id="ING"
                    value={iNG}
                    onChange={(e) => setING(+e.target.value)}
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12} sm={2.4}>
                  <ThemedTextField
                    fullWidth
                    label="S-NG(ของเสีย Setup)"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={(e) => setSNG(+e.target.value)}
                    id="SNG."
                    value={sNG}
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12} sm={2.4}>
                  <ThemedTextField
                    fullWidth
                    label="SUP-NG(ของเสีย Supplier)"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={(e) => setSupplierNG(+e.target.value)}
                    id="SNG."
                    value={supplierNG}
                    autoComplete="family-name"
                  />
                </Grid>

                <Grid item xs={12} sm={2.4}>
                  <ThemedTextField
                    fullWidth
                    InputProps={{ readOnly: true }}
                    label="Rework ชุบ"
                    InputLabelProps={{
                      shrink: true
                    }}
                    id="Rework"
                    value={rework}
                    autoComplete="family-name"
                  />
                </Grid>

                {prodLine === "LINE SPOT" ? (
                  <>
                    <Grid item xs={12} sm={2.4}>
                      <ThemedTextField
                        id="Component"
                        label="Component part / Semi part Lot No."
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="family-name"
                        onChange={(e) => setComponentLot(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                <Grid item xs={12} sm={2.4}>
                  <Autocomplete
                    fullWidth
                    disablePortal
                    renderInput={(params) => (
                      <ThemedTextField
                        {...params}
                        label="พนักงาน Scan"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                    onChange={(e, newValue) => setEmployee(newValue)}
                    options={listEmployee}
                  />
                </Grid>

                {prodLine !== "QC" ? (
                  <>
                    <Grid item xs={12} sm={2.4}>
                      <Autocomplete
                        fullWidth
                        disablePortal
                        renderInput={(params) => (
                          <ThemedTextField
                            {...params}
                            label="พนักงาน Production"
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        onChange={(e, newValue) =>
                          setProductionWorker(newValue)
                        }
                        options={listEmployee}
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                <Grid item xs={12} sm={2.4}>
                  <ThemedTextField
                    fullWidth
                    id="reamrk"
                    label="หมายเหตุ"
                    InputLabelProps={{
                      shrink: true
                    }}
                    autoComplete="family-name"
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} width={"98%"} margin={2}>
                <Grid item sm={12}>
                  <InputLabel
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0.15000000596046448px",
                      textAlign: "left"
                    }}
                  >
                    Optional recheck
                  </InputLabel>
                </Grid>

                <Grid item sm={12}>
                  <Stack direction={"row"}>
                    <Switch
                      checked={expanded === "panel1"}
                      onChange={() => {
                        setExpanded(expanded !== "panel1" ? "panel1" : false)
                      }}
                      name="gilad"
                    />
                    <label style={{ fontSize: "14px", marginTop: "11px" }}>
                      NG Stamp(ของเสียงานปั้ม)
                    </label>
                  </Stack>
                  <Stack>
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                      style={{ marginBottom: "5px", marginTop: "10px" }}
                    >
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ display: "none" }}
                      >
                        <Typography
                          style={{
                            margin: "0 auto",
                            display: "flex",
                            color: "white"
                          }}
                        >
                          NG Stamp(ของเสียงานปั้ม)
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="เป็นรอย/เศษเหล็กติด"
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={scratch}
                              onChange={(e) => setScratch(+e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="เสียรูป/โก่งงอ"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={deformed}
                              onChange={(e) => setDeformed(+e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="ขาดแหว่ง"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={torn}
                              onChange={(e) => setTorn(+e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="คราบสกปรก"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={dirtyStains}
                              onChange={(e) => setDirtyStains(+e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="สนิม"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={rust}
                              onChange={(e) => setRust(+e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="ครีบ"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={fin}
                              onChange={(e) => setFin(+e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              type="number"
                              label="ขนาดไม้ได้"
                              InputLabelProps={{
                                shrink: true
                              }}
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={woodSize}
                              onChange={(e) => setWoodSize(+e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="ข้ามprocess"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={BypassProcessStamp}
                              onChange={(e) =>
                                setBypassProcessStamp(+e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="อื่น ๆ"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={OtherStamp}
                              onChange={(e) => setOtherStamp(+e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                </Grid>

                <Grid item sm={12}>
                  <Stack direction={"row"}>
                    <Switch
                      checked={expanded === "panel2"}
                      onChange={() => {
                        setExpanded(expanded !== "panel2" ? "panel2" : false)
                      }}
                      name="gilad"
                    />
                    <label style={{ fontSize: "14px", marginTop: "11px" }}>
                      NG Weld(ของเสียไลน์ SPOT)
                    </label>
                  </Stack>
                  <Stack>
                    <Accordion
                      expanded={expanded === "panel2"}
                      style={{ marginBottom: "5px" }}
                      onChange={handleChange("panel2")}
                    >
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ display: "none" }}
                      >
                        <Typography
                          style={{
                            margin: "0 auto",
                            display: "flex",
                            color: "white"
                          }}
                        >
                          NG Weld(ของเสียไลน์ SPOT)
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              type="number"
                              label="Offset"
                              InputLabelProps={{
                                shrink: true
                              }}
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={offset}
                              onChange={(e) => setOfset(+e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="ผิดตำแหน่ง"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={wrongposition}
                              onChange={(e) =>
                                setWrongposition(+e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="ผิดขนาด/spec"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={wrongsize}
                              onChange={(e) => setWrongsize(+e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              type="number"
                              label="ไม่ติด"
                              InputLabelProps={{
                                shrink: true
                              }}
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={notstuck}
                              onChange={(e) => setNotstuck(+e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="ไม่ได้ Weld"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={noWelding}
                              onChange={(e) => setNoWelding(+e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              label="ข้ามprocess"
                              InputLabelProps={{
                                shrink: true
                              }}
                              fullWidth
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={skipProcessWeld}
                              onChange={(e) =>
                                setSkipProcessWeld(+e.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              label="อื่น ๆ"
                              InputLabelProps={{
                                shrink: true
                              }}
                              fullWidth
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={anotherWeld}
                              onChange={(e) => setAnotherWeld(+e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                </Grid>

                <Grid item sm={12}>
                  <Stack direction={"row"}>
                    <Switch
                      checked={expanded === "panel3"}
                      onChange={() => {
                        setExpanded(expanded !== "panel3" ? "panel3" : false)
                      }}
                      name="gilad"
                    />
                    <label style={{ fontSize: "14px", marginTop: "11px" }}>
                      NG Plating(ของเสียงานชุบ )
                    </label>
                  </Stack>
                  <Stack>
                    <Accordion
                      expanded={expanded === "panel3"}
                      onChange={handleChange("panel3")}
                      style={{ marginBottom: "5px" }}
                    >
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ display: "none" }}
                      >
                        <Typography
                          style={{
                            margin: "0 auto",
                            display: "flex",
                            color: "white"
                          }}
                        >
                          NG Plating(ของเสียงานชุบ )
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="ชุบไม่ติด"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={nonstickplating}
                              onChange={(e) =>
                                setNonstickplating(+e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="สีพอง"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={inflatablepaint}
                              onChange={(e) =>
                                setInflatablepaint(+e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="อื่น ๆ"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={otherPlate}
                              onChange={(e) => setOtherPlate(+e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                </Grid>

                <Grid item sm={12}>
                  <Stack direction={"row"}>
                    <Switch
                      checked={expanded === "panel4"}
                      onChange={() => {
                        setExpanded(expanded !== "panel4" ? "panel4" : false)
                      }}
                      name="gilad"
                    />
                    <label style={{ fontSize: "14px", marginTop: "11px" }}>
                      NG Arc Welding(ของเสียงานเชื่อม )
                    </label>
                  </Stack>
                  <Stack>
                    <Accordion
                      expanded={expanded === "panel4"}
                      style={{ marginBottom: "5px" }}
                      onChange={handleChange("panel4")}
                    >
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ display: "none" }}
                      >
                        <Typography
                          style={{
                            margin: "0 auto",
                            display: "flex",
                            color: "white"
                          }}
                        >
                          NG Arc Welding(ของเสียงานเชื่อม )
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="เชื่อมไม่ต่อเนื่อง"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={intermittentwelding}
                              onChange={(e) =>
                                setIntermittentwelding(+e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              label="เชื่อมติดด้านเดียว"
                              InputLabelProps={{
                                shrink: true
                              }}
                              fullWidth
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={onesidewelded}
                              onChange={(e) =>
                                setOnesidewelded(+e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              label="เชื่อม2รอบ"
                              InputLabelProps={{
                                shrink: true
                              }}
                              fullWidth
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={tworoundsofwelding}
                              onChange={(e) =>
                                setTworoundsofwelding(+e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="เชื่อมทะลุ"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={weldthrough}
                              onChange={(e) => setWeldthrough(+e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              label="เชื่อมเป็นตามด"
                              InputLabelProps={{
                                shrink: true
                              }}
                              fullWidth
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={weldaccordingto}
                              onChange={(e) =>
                                setWeldaccordingto(+e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="เชื่อมไม่เต็ม"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={incompletewelding}
                              onChange={(e) =>
                                setIncompletewelding(+e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="ลืมปิดCO2"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={forgettoturnoffCO2}
                              onChange={(e) =>
                                setForgettoturnoffCO2(+e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="ชิ้นงานเสียรูป"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={deformedworkpiece}
                              onChange={(e) =>
                                setDeformedworkpiece(+e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="อื่น ๆ"
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={otherArcweld}
                              onChange={(e) => setOtherArcweld(+e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                </Grid>

                {(prodLine === "LINE EDP" ||
                  prodLine === "LINE ZINC" ||
                  prodLine === "PLATING") && (
                  <>
                    <Grid item sm={12}>
                      <Stack direction={"row"}>
                        <Switch
                          checked={expanded === "panel5"}
                          onChange={() => {
                            setExpanded(
                              expanded !== "panel5" ? "panel5" : false
                            )
                          }}
                          name="gilad"
                        />
                        <label style={{ fontSize: "14px", marginTop: "11px" }}>
                          Rework ชุบ
                        </label>
                      </Stack>
                      <Stack>
                        <Accordion
                          expanded={expanded === "panel5"}
                          onChange={handleChange("panel5")}
                          style={{ marginBottom: "5px" }}
                        >
                          <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ display: "none" }}
                          >
                            <Typography
                              style={{
                                margin: "0 auto",
                                display: "flex",
                                color: "white"
                              }}
                            >
                              การแก้ไขใบ Rework
                            </Typography>
                          </AccordionSummary>
                          {/* inside form */}
                          <AccordionDetails sx={{ pt: "30px", pb: "30px" }}>
                            {prodLine === "LINE EDP" ? (
                              <>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={2.4}>
                                    <ThemedTextField
                                      fullWidth
                                      label="ชุบไม่ติด"
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                      type="number"
                                      onWheel={(event) =>
                                        event.currentTarget
                                          .querySelector("input")
                                          ?.blur()
                                      }
                                      value={nonStickPlatingRework}
                                      onChange={(e) =>
                                        setNonStickPlatingRework(
                                          +e.target.value
                                        )
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={2.4}>
                                    <ThemedTextField
                                      fullWidth
                                      label="สะเก็ดไม้จิ๊ก"
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                      type="number"
                                      onWheel={(event) =>
                                        event.currentTarget
                                          .querySelector("input")
                                          ?.blur()
                                      }
                                      value={woodJigFragment}
                                      onChange={(e) =>
                                        setWoodJigFragment(+e.target.value)
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={2.4}>
                                    <ThemedTextField
                                      fullWidth
                                      label="เม็ดน้ำยา"
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                      type="number"
                                      onWheel={(event) =>
                                        event.currentTarget
                                          .querySelector("input")
                                          ?.blur()
                                      }
                                      value={solutionSpill}
                                      onChange={(e) =>
                                        setSolutionSpill(+e.target.value)
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={2.4}>
                                    <ThemedTextField
                                      fullWidth
                                      label="คราบขาว / น้ำยา"
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                      type="number"
                                      onWheel={(event) =>
                                        event.currentTarget
                                          .querySelector("input")
                                          ?.blur()
                                      }
                                      value={solutionStain}
                                      onChange={(e) =>
                                        setSolutionStain(+e.target.value)
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={2.4}>
                                    <ThemedTextField
                                      fullWidth
                                      label="หลุมเข็ม"
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                      type="number"
                                      onWheel={(event) =>
                                        event.currentTarget
                                          .querySelector("input")
                                          ?.blur()
                                      }
                                      value={needlePitting}
                                      onChange={(e) =>
                                        setNeedlePitting(+e.target.value)
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={2.4}>
                                    <ThemedTextField
                                      fullWidth
                                      label="หลุมน้ำมัน"
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                      type="number"
                                      onWheel={(event) =>
                                        event.currentTarget
                                          .querySelector("input")
                                          ?.blur()
                                      }
                                      value={oilPitting}
                                      onChange={(e) =>
                                        setOilPitting(+e.target.value)
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={2.4}>
                                    <ThemedTextField
                                      fullWidth
                                      label="สีเยิ้ม/สีปูด"
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                      type="number"
                                      onWheel={(event) =>
                                        event.currentTarget
                                          .querySelector("input")
                                          ?.blur()
                                      }
                                      value={greasyBulgingColor}
                                      onChange={(e) =>
                                        setGreasyBulgingColor(+e.target.value)
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={2.4}>
                                    <ThemedTextField
                                      fullWidth
                                      label="ผิวไม่เรียบขรุขระ"
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                      type="number"
                                      onWheel={(event) =>
                                        event.currentTarget
                                          .querySelector("input")
                                          ?.blur()
                                      }
                                      value={roughSurface}
                                      onChange={(e) =>
                                        setRoughSurface(+e.target.value)
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </>
                            ) : (
                              <>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={2.4}>
                                    <ThemedTextField
                                      fullWidth
                                      label="ชุบไม่ติด/งานดำ"
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                      type="number"
                                      onWheel={(event) =>
                                        event.currentTarget
                                          .querySelector("input")
                                          ?.blur()
                                      }
                                      value={nonStickBlackSpotPlating}
                                      onChange={(e) =>
                                        setNonStickBlackSpotPlating(
                                          +e.target.value
                                        )
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={2.4}>
                                    <ThemedTextField
                                      fullWidth
                                      label="คราบสี/งานเหลือง"
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                      type="number"
                                      onWheel={(event) =>
                                        event.currentTarget
                                          .querySelector("input")
                                          ?.blur()
                                      }
                                      value={colorStain}
                                      onChange={(e) =>
                                        setColorStain(+e.target.value)
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={2.4}>
                                    <ThemedTextField
                                      fullWidth
                                      label="งานลอกพอง"
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                      type="number"
                                      onWheel={(event) =>
                                        event.currentTarget
                                          .querySelector("input")
                                          ?.blur()
                                      }
                                      value={peelSwellWorkPiece}
                                      onChange={(e) =>
                                        setPeelSwellWorkPiece(+e.target.value)
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={2.4}>
                                    <ThemedTextField
                                      fullWidth
                                      label="งานไหม้"
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                      type="number"
                                      onWheel={(event) =>
                                        event.currentTarget
                                          .querySelector("input")
                                          ?.blur()
                                      }
                                      value={burnWorkPiece}
                                      onChange={(e) =>
                                        setBurnWorkPiece(+e.target.value)
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={2.4}>
                                    <ThemedTextField
                                      fullWidth
                                      label="เยิ้ม/คราบน้ำยา"
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                      type="number"
                                      onWheel={(event) =>
                                        event.currentTarget
                                          .querySelector("input")
                                          ?.blur()
                                      }
                                      value={greasyStainSolution}
                                      onChange={(e) =>
                                        setGreasyStainSolution(+e.target.value)
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={2.4}>
                                    <ThemedTextField
                                      fullWidth
                                      label="คราบขาว"
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                      type="number"
                                      onWheel={(event) =>
                                        event.currentTarget
                                          .querySelector("input")
                                          ?.blur()
                                      }
                                      value={whiteStain}
                                      onChange={(e) =>
                                        setWhiteStain(+e.target.value)
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      </Stack>
                    </Grid>
                    <Grid item sm={12}>
                      <Paper sx={{ p: "30px 18px 30px 18px" }}>
                        <Typography variant="h5" sx={{ mb: "50px" }}>
                          ความหนาของชิ้นงาน 3 ชิ้นแรก
                        </Typography>
                        <Grid
                          container
                          spacing={2}
                          sx={{ justifyContent: "space-between" }}
                        >
                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="ชิ้นที่ 1"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={firstPieceThickness}
                              onChange={(e) =>
                                setFirstPieceThickness(+e.target.value)
                              }
                              disabled={thickFormDisable}
                            />
                          </Grid>
                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="ชิ้นที่ 2"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={secondPieceThickness}
                              onChange={(e) =>
                                setSecondPieceThickness(+e.target.value)
                              }
                              disabled={thickFormDisable}
                            />
                          </Grid>
                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="ชิ้นที่ 3"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={thirdPieceThickness}
                              onChange={(e) =>
                                setThirdPieceThickness(+e.target.value)
                              }
                              disabled={thickFormDisable}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </>
                )}
              </Grid>

              <Grid container margin={2} spacing={2} width={"98%"}>
                <Grid item sm={12} xs={12}>
                  <ThemedLoadingButton
                    variant="contained"
                    style={{
                      width: "86px",
                      height: "36px",
                      top: "9px"
                    }}
                    onClick={handleSubmit}
                    loading={load}
                  >
                    Submit
                  </ThemedLoadingButton>
                </Grid>
              </Grid>
              <Button type="submit"></Button>
            </Box>
          </ThemeProvider>
        </Box>
      </ThemedCard>
    </>
  )
}

export default Scanner
