import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Grid,
  InputLabel,
  Stack,
  Switch,
  ThemeProvider,
  Typography,
  useTheme
} from "@mui/material"
import moment from "moment"
import { SetStateAction, useEffect, useMemo, useState } from "react"
import QrReader from "react-qr-reader"
import swal from "sweetalert"
import { CREATE_APPROVE_EDIT } from "../../common/Mutation/productionMutation"
import {
  GET_EMPLOYEE,
  GET_MACHINES,
  GET_PARTS
} from "../../common/Query/MasterQuery"
import {
  GET_PART_PROCESSING,
  GET_WIP_STOCK
} from "../../common/Query/MaterialQuery"
import { GET_DATA_SCANNER } from "../../common/Query/ProductionQuery"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import CameraAltIcon from "@mui/icons-material/CameraAlt"
import { menuItemList } from "../../common/Resources/menuItemList"
import { ADD_UPDATE_REWORK } from "../../common/Mutation/ScannerMutation"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"

interface IDataGetScanner {
  id: number
  partNo: string
  jobOrder: string
  pdLotNo: string
  inOrOut: number
  actionDate: Date
  quantity: number
  factory: string
  packingBy: string
  remarks: string
  tNG: number
  iNG: number
  sNG: number
  supplierNG: number
  machine: string
  line: string
  process: string
  boxNo: number
  prodDate: Date
  totalCost: number
  dateScan: Date
  componentLot: string
  keyBy: string
  shift: string
}

interface IDataBox {
  boxNo: string
  id: number
  alias: string
}

const pageTitle = "Edit / Cancel"

const EditScanner: React.FC = () => {
  const [jobOrderList, setJobOrderList] = useState<string[]>([])
  const [jobOrder, setJobOrder] = useState<string>("")
  const [partNo, setPartNo] = useState<string>("")
  const [boxNo, setBoxNo] = useState<number>(0)
  const [date, setDate] = useState<string>("")
  const [OldDate, setOldDate] = useState<string>("")
  const [isStream, setIsStream] = useState(false)
  const [alias, setAlias] = useState<string>("")
  const [id, setId] = useState<number>(0)
  const [process, setProcess] = useState<string>("")
  const [factory, setFactory] = useState<string>("")
  const [line, setLine] = useState<string>("")
  const [quantity, setQuantity] = useState<number>(0)
  const [tNG, setTNG] = useState<number>(0)
  const [iNG, setING] = useState<number>(0)
  const [sNG, setSNG] = useState<number>(0)
  const [supplierNG, setSupplierNG] = useState(0)
  const [machine, setMachine] = useState<string>("")
  const [oldQuantity, setOldQuantity] = useState<number>(0)
  const [oldMachine, setOldMachine] = useState<string>("")
  const [employee, setEmployee] = useState<string>("")
  const [employeeList, setEmployeeList] = useState([])
  const [processList, setProcessList] = useState<string[]>([])
  const [boxNoList, setBoxNoList] = useState<IDataBox[]>([])
  const [dataScan, setDataScan] = useState("")
  const [scratch, setScratch] = useState(0)
  const [deformed, setDeformed] = useState(0)
  const [torn, setTorn] = useState(0)
  const [dirtyStains, setDirtyStains] = useState(0)
  const [rust, setRust] = useState(0)
  const [fin, setFin] = useState(0)
  const [woodSize, setWoodSize] = useState(0)
  const [BypassProcessStamp, setBypassProcessStamp] = useState(0)
  const [OtherStamp, setOtherStamp] = useState(0)
  const [incompletewelding, setIncompletewelding] = useState(0)
  const [offset, setOfset] = useState(0)
  const [wrongposition, setWrongposition] = useState(0)
  const [wrongsize, setWrongsize] = useState(0)
  const [notstuck, setNotstuck] = useState(0)
  const [noWelding, setNoWelding] = useState(0)
  const [skipProcessWeld, setSkipProcessWeld] = useState(0)
  const [anotherWeld, setAnotherWeld] = useState(0)
  const [nonstickplating, setNonstickplating] = useState(0)
  const [inflatablepaint, setInflatablepaint] = useState(0)
  const [otherPlate, setOtherPlate] = useState(0)
  const [intermittentwelding, setIntermittentwelding] = useState(0)
  const [onesidewelded, setOnesidewelded] = useState(0)
  const [tworoundsofwelding, setTworoundsofwelding] = useState(0)
  const [weldthrough, setWeldthrough] = useState(0)
  const [weldaccordingto, setWeldaccordingto] = useState(0)
  const [forgettoturnoffCO2, setForgettoturnoffCO2] = useState(0)
  const [deformedworkpiece, setDeformedworkpiece] = useState(0)
  const [otherArcweld, setOtherArcweld] = useState(0)
  const [queryTestScan, setQueryTestScan] = useState<IDataGetScanner[]>([])
  const [getDataScanner] = useLazyQuery(GET_DATA_SCANNER)
  const [error, setError] = useState<boolean>(false)
  const getEmployee = useQuery(GET_EMPLOYEE)
  const [createApproveEdit, { loading }] = useMutation(CREATE_APPROVE_EDIT)
  const getPart = useQuery(GET_PARTS)
  const [getStocks] = useLazyQuery(GET_WIP_STOCK)
  const [isOpen, setIsOpen] = useState<string>("Edit")
  const [expanded, setExpanded] = useState<string | false>("")

  const [shift, setShift] = useState<string>("")

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  let partNoList: Array<string> = []
  partNoList = getPart.data
    ? getPart.data.parts.map((e: { partNo: string }) => e.partNo)
    : []

  useMemo(async () => {
    if (partNo) {
      const stocks = await getStocks({
        variables: {
          partNo
        }
      })
      const jobOrderList: string[] = Array.from(
        new Set(
          stocks.data.wipStocks
            .filter((parts: { partNo: string }) => parts.partNo === partNo)
            .map((e: { jobOrder: string }) => e.jobOrder)
        )
      )
      setJobOrderList(jobOrderList)
    }
  }, [partNo])
  useMemo(async () => {
    try {
      if (jobOrder && partNo) {
        const result = await getDataScanner({
          variables: {
            dataInPushGetScanner: {
              jobOrder: jobOrder,
              partNo: partNo
            }
          },
          fetchPolicy: "network-only"
        })
        const dataScannerListQuery = result?.data?.getDataScanner
        if (dataScannerListQuery?.length > 0) {
          const processListSet: string[] = Array.from(
            new Set(
              dataScannerListQuery.map(
                ({ process }: { process: string }) => process
              )
            )
          )

          setProcessList(processListSet)
          setQueryTestScan(dataScannerListQuery)
        }
      }
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "error")
    }
  }, [jobOrder, partNo])

  useMemo(() => {
    let timesScanned = 1
    let curBox = 0
    const boxNoListSet: IDataBox[] = queryTestScan
      .filter((e: { process: string }) => e.process === process)
      .map((element: { boxNo: number; id: number }) => {
        if (curBox !== element.boxNo) {
          timesScanned = 1
          curBox = element.boxNo
        } else {
          timesScanned++
          curBox = element.boxNo
        }

        return {
          boxNo: element.boxNo.toString(),
          id: element.id,
          alias: `Box ที่ ${element.boxNo} สแกนครั้งที่ ${timesScanned}`
        }
      })

    setBoxNoList(boxNoListSet)
  }, [process])

  useEffect(() => {
    const findProcess = queryTestScan.find(
      (entry: IDataGetScanner) =>
        entry.process === process && entry.boxNo === boxNo && entry.id === id
    )
    if (getEmployee.data) setEmployeeList(getEmployee.data.employee)
    setQuantity(findProcess ? findProcess.quantity : 0)
    setTNG(findProcess ? findProcess.tNG : 0)
    setING(findProcess ? findProcess.iNG : 0)
    setSNG(findProcess ? findProcess.sNG : 0)
    setSupplierNG(findProcess ? findProcess.supplierNG : 0)
    setMachine(findProcess ? findProcess.machine : "")
    setOldQuantity(findProcess ? findProcess.quantity : 0)
    setOldMachine(findProcess ? findProcess.machine : "")
    setFactory(findProcess ? findProcess.factory : "")
    setLine(findProcess ? findProcess.line : "")
    setEmployee(findProcess ? findProcess.keyBy : "")
    setDate(findProcess ? findProcess.actionDate.toString() : "")
    setOldDate(findProcess ? findProcess.actionDate.toString() : "")
    setShift(findProcess ? findProcess.shift.toString() : "")
  }, [process, boxNo, id])

  const getMachines = useQuery(GET_MACHINES)
  const machineList = useMemo(() => {
    const result = getMachines?.data?.machines
    if (result?.length > 0) {
      return result
        .filter((data: { factory: string; line: string }) => {
          return data.factory === factory && data.line === line
        })
        ?.map(({ machine }: { machine: string }) => machine)
    }
    return []
  }, [line, factory])

  const handleSubmit = async (type: string) => {
    const findProcess = queryTestScan.find(
      (entry: IDataGetScanner) =>
        entry.process === process && entry.boxNo === boxNo && entry.id === id
    )

    if (
      iNG + sNG + tNG + supplierNG + quantity > (findProcess?.quantity || 0) &&
      isOpen !== "Delete"
    ) {
      setError(true)
      return swal("ERROR", "ผลรวมของจำนวนไม่ตรงกัน", "error")
    }
    if (process == "" || boxNo == 0) {
      setError(true)
      return swal("ERROR", "กรุณาใส่ Process ,Box no", "error")
    }
    try {
      const dataJson = JSON.stringify({
        id: id,
        type: type,
        partNo: partNo,
        jobOrder: jobOrder,
        boxNo: boxNo,
        alias: alias,
        quantity: quantity,
        tNG: tNG,
        sNG: sNG,
        iNG: iNG,
        supplierNG: supplierNG,
        machine: machine,
        keyBy: employee,
        actionDate: date,
        process: process,
        oldDate: OldDate,
        oldQuantity: oldQuantity,
        oldMachine: oldMachine,
        shift: shift,
        factory: factory
      })

      await createApproveEdit({
        variables: {
          dataCreateApproveEdit: {
            tableName: "testScanner",
            statusApprove: "รออนุมัติ",
            approver: "",
            requestDate: moment(new Date()).format("YYYY-MM-DD"),
            editJson: dataJson,
            editBy: employee
          }
        }
      })

      swal("Success", "บัททึกสำเร็จ", "success")
      onReset()
    } catch (err) {
      swal("Error", `${(err as Error).message}`, "error")
    }
  }

  const handleScan = (data: SetStateAction<string> | null): void => {
    if (data) {
      setDataScan(data)
      if (dataScan) {
        breakQRCode()
      }
    }
  }

  const onReset = () => {
    setDataScan("")
    setBoxNo(0)
    setId(0)
    setAlias("")
    setProcess("")
    setJobOrder("")
    setPartNo("")
  }
  const breakQRCode = async (e?: { preventDefault: () => void }) => {
    e?.preventDefault()
    const newSplit = dataScan.trim().split("|")
    if (newSplit.length !== 6) {
      swal("warning!", "มีปัญหากับ format ของ QR Code!", "warning")
    }
    setJobOrder(`${newSplit[0]}|${newSplit[1]}|${newSplit[2]}`)
    setPartNo(newSplit[4])
    alert(dataScan)
  }
  const handleError = (err: string) => {
    swal("Error", `${err}`, "error")
    console.error(err)
  }
  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Scanner}
        menuItemName={Object.keys(menuItemList)[11]}
        menuPageName={pageTitle}
      />
      <Grid container spacing={2} width={"98%"} margin={2} paddingRight={3}>
        <Grid item xs={12} sm={3}>
          <Autocomplete
            disablePortal
            id="Rework"
            options={["Rework", "Edit", "Delete"]}
            onChange={(e, newValue) => {
              setIsOpen(newValue as string)
              onReset()
            }}
            renderInput={(params) => <ThemedTextField {...params} label="" />}
          />
        </Grid>
      </Grid>
      <ThemedCard>
        {isOpen === "Edit" ? (
          <Box component="form" onSubmit={breakQRCode}>
            <Accordion
              expanded={isStream}
              onChange={() => setIsStream(!isStream)}
              style={{
                marginBottom: "5px",
                marginTop: "10px"
              }}
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ backgroundColor: "#ffffff", display: "none" }}
              />

              <AccordionDetails>
                {isStream ? (
                  <>
                    <Grid container justifyContent={"center"}>
                      <Grid
                        item
                        sm={3}
                        xs={12}
                        container
                        justifyContent={"center"}
                      >
                        <QrReader
                          delay={300}
                          onError={handleError}
                          onScan={handleScan}
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </AccordionDetails>
            </Accordion>
            <PageLabelCard title={isOpen} subTitle="Edit scanned boxes" />
            <Grid
              container
              spacing={2}
              width={"98%"}
              margin={2}
              paddingRight={3}
            >
              <></>
              <Grid item xs={12} sm={12}>
                <Switch
                  checked={isStream}
                  onChange={(event) =>
                    setIsStream(event.target.value ? !isStream : isStream)
                  }
                  name="gilad"
                />
                <label style={{ fontSize: "14px" }}>
                  Active Camera{" "}
                  <CameraAltIcon style={{ verticalAlign: "middle" }} />
                </label>
              </Grid>
              <></>
              <Grid item xs={12} sm={9}>
                <ThemedTextField
                  fullWidth
                  id="scanner"
                  value={dataScan}
                  label="scanner"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setDataScan(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Autocomplete
                  className="disable-field"
                  fullWidth
                  disabled
                  disablePortal
                  value={partNo}
                  renderInput={(params) => (
                    <ThemedTextField
                      {...params}
                      label="Part No"
                      error={!partNo && error}
                    />
                  )}
                  onChange={(e, newValue) =>
                    setPartNo(newValue ? newValue : "")
                  }
                  options={partNoList}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  disablePortal
                  disabled
                  value={jobOrder}
                  className="disable-field"
                  id="jobOrder"
                  options={jobOrderList}
                  style={{ width: "100%" }}
                  sx={{ width: 300 }}
                  onChange={(e, newValue) =>
                    setJobOrder(newValue ? newValue : "")
                  }
                  renderInput={(params) => (
                    <ThemedTextField
                      {...params}
                      label="Job Order"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  disablePortal
                  id="process"
                  value={process}
                  options={processList}
                  style={{ width: "100%" }}
                  sx={{ width: 300 }}
                  onChange={(e, newValue) =>
                    setProcess(newValue ? newValue : "")
                  }
                  renderInput={(params) => (
                    <ThemedTextField
                      {...params}
                      label="Select Process"
                      InputLabelProps={{ shrink: true }}
                      error={!process && error}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Autocomplete
                  fullWidth
                  value={
                    process
                      ? boxNoList.find((e) => e.id === id)
                      : { alias: "", boxNo: 0, id: 0 }
                  }
                  disablePortal
                  getOptionLabel={(option) => option.alias}
                  onChange={(event, selected) => {
                    setBoxNo(selected ? Number(selected.boxNo) : 0)
                    setId(selected ? selected.id : 0)
                    setAlias(selected ? selected.alias : "")
                  }}
                  options={boxNoList}
                  renderInput={(params) => (
                    <ThemedTextField
                      {...params}
                      label="Select Box No"
                      InputLabelProps={{ shrink: true }}
                      error={!process && error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ThemedTextField
                  fullWidth
                  value={quantity}
                  id="quantityFG"
                  label="Quantity FG"
                  autoComplete="family-name"
                  onChange={(e) => setQuantity(+e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ThemedTextField
                  fullWidth
                  id="TNG."
                  label="T-NG(ปรับแต่งแม่พิมพ์)"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={tNG}
                  onChange={(e) => setTNG(+e.target.value)}
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ThemedTextField
                  fullWidth
                  label="I-NG(ของเสียในกระบวนการ)"
                  InputLabelProps={{
                    shrink: true
                  }}
                  id="ING"
                  value={iNG}
                  onChange={(e) => setING(+e.target.value)}
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ThemedTextField
                  fullWidth
                  label="S-NG(ของเสีย Setup)"
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => setSNG(+e.target.value)}
                  id="SNG."
                  value={sNG}
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ThemedTextField
                  fullWidth
                  label="SUP-NG(ของเสีย Supplier)"
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => setSupplierNG(+e.target.value)}
                  id="SNG."
                  value={supplierNG}
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ThemedTextField
                  fullWidth
                  type="date"
                  label="วันที่ผลิต"
                  value={date}
                  id="date"
                  autoComplete="family-name"
                  onChange={(e) => setDate(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  value={machine}
                  renderInput={(params) => (
                    <ThemedTextField
                      {...params}
                      label="เครื่องที่ผลิต"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  onChange={(e, newValue) =>
                    setMachine(newValue ? newValue : "")
                  }
                  options={machineList}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  value={shift}
                  renderInput={(params) => (
                    <ThemedTextField {...params} label="Shift" />
                  )}
                  onChange={(e, newValue) => setShift(newValue ? newValue : "")}
                  options={["Day", "Night"]}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  value={employee}
                  renderInput={(params) => (
                    <ThemedTextField
                      {...params}
                      label="แก้ไข / ยกเลิก โดย"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  onChange={(e, newValue) =>
                    setEmployee(newValue ? newValue : "")
                  }
                  options={employeeList
                    .filter((e: { departmentId: number; factory: string }) => {
                      if (line === "QC")
                        return e.departmentId === 6 && e.factory === factory
                      if (line === "LINE SPOT")
                        return e.departmentId === 4 && e.factory === factory
                      if (line === "PLATING")
                        return e.departmentId === 15 && e.factory === factory
                      if (
                        line !== "LINE SPOT" &&
                        line !== "QC" &&
                        line !== "PLATING" &&
                        line !== "LINE EDP" &&
                        line !== "LINE ZINC"
                      )
                        return e.departmentId === 4 && e.factory === factory
                    })
                    .map((e: { firstName: string }) => e.firstName)}
                />
              </Grid>
              <Grid item container spacing={2} width={"98%"} margin={2} xs={12}>
                <Grid item sm={12}>
                  <InputLabel
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0.15000000596046448px",
                      textAlign: "left"
                    }}
                  >
                    Optional recheck
                  </InputLabel>
                </Grid>

                <Grid item sm={12}>
                  <Stack direction={"row"}>
                    <Switch
                      checked={expanded === "panel1"}
                      onChange={() => {
                        setExpanded(expanded !== "panel1" ? "panel1" : false)
                      }}
                      name="gilad"
                    />
                    <label style={{ fontSize: "14px", marginTop: "11px" }}>
                      NG Stamp(ของเสียงานปั้ม)
                    </label>
                  </Stack>
                  <Stack>
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                      style={{ marginBottom: "5px", marginTop: "10px" }}
                    >
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ display: "none" }}
                      >
                        <Typography
                          style={{
                            margin: "0 auto",
                            display: "flex",
                            color: "white"
                          }}
                        >
                          NG Stamp(ของเสียงานปั้ม)
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="เป็นรอย/เศษเหล็กติด"
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={scratch}
                              onChange={(e) => setScratch(+e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="เสียรูป/โก่งงอ"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={deformed}
                              onChange={(e) => setDeformed(+e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="ขาดแหว่ง"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={torn}
                              onChange={(e) => setTorn(+e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="คราบสกปรก"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={dirtyStains}
                              onChange={(e) => setDirtyStains(+e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="สนิม"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={rust}
                              onChange={(e) => setRust(+e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="ครีบ"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={fin}
                              onChange={(e) => setFin(+e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              type="number"
                              label="ขนาดไม้ได้"
                              InputLabelProps={{
                                shrink: true
                              }}
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={woodSize}
                              onChange={(e) => setWoodSize(+e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="ข้ามprocess"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={BypassProcessStamp}
                              onChange={(e) =>
                                setBypassProcessStamp(+e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="อื่น ๆ"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={OtherStamp}
                              onChange={(e) => setOtherStamp(+e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                </Grid>

                <Grid item sm={12}>
                  <Stack direction={"row"}>
                    <Switch
                      checked={expanded === "panel2"}
                      onChange={() => {
                        setExpanded(expanded !== "panel2" ? "panel2" : false)
                      }}
                      name="gilad"
                    />
                    <label style={{ fontSize: "14px", marginTop: "11px" }}>
                      NG Weld(ของเสียไลน์ SPOT)
                    </label>
                  </Stack>
                  <Stack>
                    <Accordion
                      expanded={expanded === "panel2"}
                      style={{ marginBottom: "5px" }}
                      onChange={handleChange("panel2")}
                    >
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ display: "none" }}
                      >
                        <Typography
                          style={{
                            margin: "0 auto",
                            display: "flex",
                            color: "white"
                          }}
                        >
                          NG Weld(ของเสียไลน์ SPOT)
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              type="number"
                              label="Offset"
                              InputLabelProps={{
                                shrink: true
                              }}
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={offset}
                              onChange={(e) => setOfset(+e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="ผิดตำแหน่ง"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={wrongposition}
                              onChange={(e) =>
                                setWrongposition(+e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="ผิดขนาด/spec"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={wrongsize}
                              onChange={(e) => setWrongsize(+e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              type="number"
                              label="ไม่ติด"
                              InputLabelProps={{
                                shrink: true
                              }}
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={notstuck}
                              onChange={(e) => setNotstuck(+e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="ไม่ได้ Weld"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={noWelding}
                              onChange={(e) => setNoWelding(+e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              label="ข้ามprocess"
                              InputLabelProps={{
                                shrink: true
                              }}
                              fullWidth
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={skipProcessWeld}
                              onChange={(e) =>
                                setSkipProcessWeld(+e.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              label="อื่น ๆ"
                              InputLabelProps={{
                                shrink: true
                              }}
                              fullWidth
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={anotherWeld}
                              onChange={(e) => setAnotherWeld(+e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                </Grid>

                <Grid item sm={12}>
                  <Stack direction={"row"}>
                    <Switch
                      checked={expanded === "panel3"}
                      onChange={() => {
                        setExpanded(expanded !== "panel3" ? "panel3" : false)
                      }}
                      name="gilad"
                    />
                    <label style={{ fontSize: "14px", marginTop: "11px" }}>
                      NG Plating(ของเสียงานชุบ )
                    </label>
                  </Stack>
                  <Stack>
                    <Accordion
                      expanded={expanded === "panel3"}
                      onChange={handleChange("panel3")}
                      style={{ marginBottom: "5px" }}
                    >
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ display: "none" }}
                      >
                        <Typography
                          style={{
                            margin: "0 auto",
                            display: "flex",
                            color: "white"
                          }}
                        >
                          NG Plating(ของเสียงานชุบ )
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="ชุบไม่ติด"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={nonstickplating}
                              onChange={(e) =>
                                setNonstickplating(+e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="สีพอง"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={inflatablepaint}
                              onChange={(e) =>
                                setInflatablepaint(+e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="อื่น ๆ"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={otherPlate}
                              onChange={(e) => setOtherPlate(+e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                </Grid>

                <Grid item sm={12}>
                  <Stack direction={"row"}>
                    <Switch
                      checked={expanded === "panel4"}
                      onChange={() => {
                        setExpanded(expanded !== "panel4" ? "panel4" : false)
                      }}
                      name="gilad"
                    />
                    <label style={{ fontSize: "14px", marginTop: "11px" }}>
                      NG Arc Welding(ของเสียงานเชื่อม )
                    </label>
                  </Stack>
                  <Stack>
                    <Accordion
                      expanded={expanded === "panel4"}
                      style={{ marginBottom: "5px" }}
                      onChange={handleChange("panel4")}
                    >
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ display: "none" }}
                      >
                        <Typography
                          style={{
                            margin: "0 auto",
                            display: "flex",
                            color: "white"
                          }}
                        >
                          NG Arc Welding(ของเสียงานเชื่อม )
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="เชื่อมไม่ต่อเนื่อง"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={intermittentwelding}
                              onChange={(e) =>
                                setIntermittentwelding(+e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              label="เชื่อมติดด้านเดียว"
                              InputLabelProps={{
                                shrink: true
                              }}
                              fullWidth
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={onesidewelded}
                              onChange={(e) =>
                                setOnesidewelded(+e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              label="เชื่อม2รอบ"
                              InputLabelProps={{
                                shrink: true
                              }}
                              fullWidth
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={tworoundsofwelding}
                              onChange={(e) =>
                                setTworoundsofwelding(+e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="เชื่อมทะลุ"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={weldthrough}
                              onChange={(e) => setWeldthrough(+e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              label="เชื่อมเป็นตามด"
                              InputLabelProps={{
                                shrink: true
                              }}
                              fullWidth
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={weldaccordingto}
                              onChange={(e) =>
                                setWeldaccordingto(+e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="เชื่อมไม่เต็ม"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={incompletewelding}
                              onChange={(e) =>
                                setIncompletewelding(+e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="ลืมปิดCO2"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={forgettoturnoffCO2}
                              onChange={(e) =>
                                setForgettoturnoffCO2(+e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="ชิ้นงานเสียรูป"
                              InputLabelProps={{
                                shrink: true
                              }}
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={deformedworkpiece}
                              onChange={(e) =>
                                setDeformedworkpiece(+e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={2.4}>
                            <ThemedTextField
                              fullWidth
                              label="อื่น ๆ"
                              type="number"
                              onWheel={(event) =>
                                event.currentTarget
                                  .querySelector("input")
                                  ?.blur()
                              }
                              value={otherArcweld}
                              onChange={(e) => setOtherArcweld(+e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                </Grid>
              </Grid>
              <Grid item sm={12}>
                <ThemedLoadingButton
                  style={{
                    marginLeft: "10px",
                    backgroundColor: theme.palette.primary.main
                  }}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => handleSubmit("EDIT")}
                  loading={loading}
                >
                  Submit Edit (แก้ไข)
                </ThemedLoadingButton>
              </Grid>
            </Grid>

            <Button type="submit"></Button>
          </Box>
        ) : isOpen === "Rework" ? (
          <Rework />
        ) : (
          <Box component="form" onSubmit={breakQRCode}>
            <Accordion
              expanded={isStream}
              onChange={() => setIsStream(!isStream)}
              style={{
                marginBottom: "5px",
                marginTop: "10px"
              }}
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ backgroundColor: "#ffffff", display: "none" }}
              />

              <AccordionDetails>
                {isStream ? (
                  <>
                    <Grid container justifyContent={"center"}>
                      <Grid
                        item
                        sm={3}
                        xs={12}
                        container
                        justifyContent={"center"}
                      >
                        <QrReader
                          delay={300}
                          onError={handleError}
                          onScan={handleScan}
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </AccordionDetails>
            </Accordion>
            <PageLabelCard title={isOpen} subTitle="Delete scanned boxes" />
            <Grid
              container
              spacing={2}
              width={"98%"}
              margin={2}
              paddingRight={3}
            >
              <></>
              <Grid item xs={12} sm={12}>
                <Switch
                  checked={isStream}
                  onChange={(event) =>
                    setIsStream(event.target.value ? !isStream : isStream)
                  }
                  name="gilad"
                />
                <label style={{ fontSize: "14px" }}>
                  Active Camera{" "}
                  <CameraAltIcon style={{ verticalAlign: "middle" }} />
                </label>
              </Grid>
              <></>
              <Grid item xs={12} sm={9}>
                <ThemedTextField
                  fullWidth
                  id="scanner"
                  value={dataScan}
                  label="scanner"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setDataScan(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Autocomplete
                  className="disable-field"
                  fullWidth
                  disabled
                  disablePortal
                  value={partNo}
                  renderInput={(params) => (
                    <ThemedTextField
                      {...params}
                      label="Part No"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  onChange={(e, newValue) =>
                    setPartNo(newValue ? newValue : "")
                  }
                  options={partNoList}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  disablePortal
                  disabled
                  value={jobOrder}
                  className="disable-field"
                  id="jobOrder"
                  options={jobOrderList}
                  style={{ width: "100%" }}
                  sx={{ width: 300 }}
                  onChange={(e, newValue) =>
                    setJobOrder(newValue ? newValue : "")
                  }
                  renderInput={(params) => (
                    <ThemedTextField
                      {...params}
                      label="Job Order"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  disablePortal
                  id="process"
                  value={process}
                  options={processList}
                  style={{ width: "100%" }}
                  sx={{ width: 300 }}
                  onChange={(e, newValue) =>
                    setProcess(newValue ? newValue : "")
                  }
                  renderInput={(params) => (
                    <ThemedTextField
                      {...params}
                      label="Select Process"
                      error={!process && error}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Autocomplete
                  fullWidth
                  value={
                    process
                      ? boxNoList.find((e) => e.id === id)
                      : { alias: "", boxNo: 0, id: 0 }
                  }
                  disablePortal
                  getOptionLabel={(option) => option.alias}
                  onChange={(event, selected) => {
                    setBoxNo(selected ? Number(selected.boxNo) : 0)
                    setId(selected ? selected.id : 0)
                    setAlias(selected ? selected.alias : "")
                  }}
                  options={boxNoList}
                  getOptionDisabled={(option) =>
                    option !== boxNoList[boxNoList.length - 1]
                  }
                  renderInput={(params) => (
                    <ThemedTextField
                      {...params}
                      label="Select Box No"
                      InputLabelProps={{ shrink: true }}
                      error={!process && error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ThemedTextField
                  fullWidth
                  value={quantity}
                  id="quantity"
                  autoComplete="family-name"
                  onChange={(e) => setQuantity(+e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <ThemedTextField
                  fullWidth
                  type="date"
                  label="วันที่ผลิต"
                  value={date}
                  id="date"
                  autoComplete="family-name"
                  onChange={(e) => setDate(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  value={machine}
                  renderInput={(params) => (
                    <ThemedTextField
                      {...params}
                      label="เครื่องที่ผลิต"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  onChange={(e, newValue) =>
                    setMachine(newValue ? newValue : "")
                  }
                  options={machineList}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  fullWidth
                  value={employee}
                  renderInput={(params) => (
                    <ThemedTextField
                      {...params}
                      label="แก้ไข / ยกเลิก โดย"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  onChange={(e, newValue) =>
                    setEmployee(newValue ? newValue : "")
                  }
                  options={Array.from(
                    new Set(
                      employeeList.map(
                        (e: { firstName: string }) => e.firstName
                      )
                    )
                  )}
                />
              </Grid>
              <Grid item sm={12}>
                <ThemedLoadingButton
                  contrast
                  style={{
                    marginLeft: "10px"
                  }}
                  variant="outlined"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => handleSubmit("DELETE")}
                  loading={loading}
                >
                  Submit Delete (ยกเลิก)
                </ThemedLoadingButton>
              </Grid>
            </Grid>

            <Button type="submit"></Button>
          </Box>
        )}
      </ThemedCard>
    </ThemeProvider>
  )
}

const Rework: React.FC = () => {
  const [operator, setOperator] = useState<string>("")
  const [partNo, setPartNo] = useState<string>("")
  const [quantity, setQuantity] = useState<number>(0)
  const [inputScanner, setInputScanner] = useState<string>("")
  const [pdLotNo, setPdLotNo] = useState<string>("")
  const [factory, setFactory] = useState<string>("")
  const [error, setError] = useState<boolean>(false)
  const [date, setDate] = useState<string>("")
  const [process, setProcess] = useState<string>("")
  const [updateRework, { loading }] = useMutation(ADD_UPDATE_REWORK)

  const getPart = useQuery(GET_PARTS)
  const getPartprocessing = useQuery(GET_PART_PROCESSING)
  const isComplete = () => {
    return (
      operator !== "" &&
      partNo !== "" &&
      quantity !== 0 &&
      pdLotNo !== "" &&
      date !== "" &&
      factory !== "" &&
      process !== "" &&
      inputScanner !== ""
    )
  }

  const handleSubmit = async () => {
    if (!isComplete()) {
      setError(true)
      swal("Error", `กรุณาใส่ข้อมูลให้ครบถ้วน`, "error")
      return
    }
    try {
      await updateRework({
        variables: {
          updateRw: {
            operator,
            partNo,
            quantity,
            pdLotNo,
            actionDate: date,
            factory,
            process,
            scanner: inputScanner
          }
        }
      })
      swal("Successful", "You Insert Data Success", "success")
    } catch (err) {
      swal("Error", `${(err as Error).message}`, "error")
    }
  }

  let processingList: string[] = []
  processingList =
    getPartprocessing?.data?.partsprocessings
      .filter((e: { partNo: string }) => e.partNo === partNo)
      .map((e: { processOrder: string }) => e.processOrder) || []

  let partNoList: string[] = []
  partNoList = getPart?.data?.parts.map((e: { partNo: string }) => e.partNo)
  return (
    <>
      <Box component="form">
        <PageLabelCard title="Rework" subTitle="detail about Rework" />
        <Grid container spacing={2} width={"98%"} margin={2} paddingRight={3}>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              fullWidth
              value={partNo}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Part No"
                  InputLabelProps={{ shrink: true }}
                  error={!partNo && error}
                />
              )}
              onChange={(e, newValue) => setPartNo(newValue ? newValue : "")}
              options={partNoList}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ThemedTextField
              fullWidth
              value={quantity}
              id="quantity"
              error={!quantity && error}
              autoComplete="family-name"
              label="quantity"
              onChange={(e) => setQuantity(+e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item sm={4}>
            <Autocomplete
              fullWidth
              disablePortal
              value={factory}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Select Factory"
                  error={!factory && error}
                />
              )}
              onChange={(e, newValue) => setFactory(newValue ?? "")}
              options={["1", "2"]}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <ThemedTextField
              fullWidth
              value={pdLotNo}
              id="PdLot"
              label="PdLot"
              onChange={(e) => setPdLotNo(e.target.value)}
              InputLabelProps={{ shrink: true }}
              error={!pdLotNo && error}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ThemedTextField
              fullWidth
              type="date"
              label="วันที่ผลิต"
              value={date}
              id="date"
              autoComplete="family-name"
              onChange={(e) => setDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              error={!date && error}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              fullWidth
              value={process}
              options={processingList}
              disablePortal
              onChange={(e, value) => {
                setProcess(value ? value : "")
              }}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Process"
                  InputLabelProps={{ shrink: true }}
                  error={!process && error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ThemedTextField
              fullWidth
              type="text"
              label="พนักงาน Scan"
              value={inputScanner}
              id="scanner"
              onChange={(e) => setInputScanner(e.target.value)}
              InputLabelProps={{ shrink: true }}
              error={!inputScanner && error}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {" "}
            <ThemedTextField
              fullWidth
              id="operator"
              value={operator}
              label="operator"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setOperator(e.target.value)}
              error={!operator && error}
            />
          </Grid>
          <Grid item sm={12}>
            <ThemedLoadingButton
              style={{
                marginLeft: "10px"
              }}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
              loading={loading}
            >
              Submit Rework(แก้ไข)
            </ThemedLoadingButton>
          </Grid>
        </Grid>

        <Button type="submit"></Button>
      </Box>
    </>
  )
}
export default EditScanner
