import {
  Divider,
  useTheme,
  Autocomplete,
  Button,
  Grid,
  Theme
} from "@mui/material"
import { PageLabel } from "../../common/Resources/PageLabel"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import { menuItemList } from "../../common/Resources/menuItemList"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { ThemeProvider } from "@mui/styles"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import { GET_MATERIALS } from "../../common/Query/MasterQuery"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { GET_MATSTOCK } from "../../common/Query/MaterialQuery"
import { useMemo, useRef, useState } from "react"
import { DataGrid } from "bitsoft-c3"
import {
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp
} from "@mui/x-data-grid"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Close"
import Swal from "sweetalert2"
import {
  DELETE_MAT_STOCK,
  UPDATE_MAT_STOCK
} from "../../common/Mutation/MaterialMutation"
import { user } from "../../common/MainApp"
import moment from "moment"

interface IMatstock {
  indexNo: number
  matNo: string
  inOrOut: number
  actionDate: string
  quantity: string
  factory: number
  packingBy: string
  jobOrder: number
  remarks: string
  isNG: string
  invoiceNo: string
  partNo: boolean
  deliveryDate: number
  dateScan: string
  smQty: string
  supplier: string
  updatedBy: string
  updatedDate: string
  isNew?: boolean
}

const makeColumns = (
  handleCancelClick: (id: GridRowId) => () => void,
  handleDeleteClick: (id: GridRowId) => () => void,
  handleEditClick: (id: GridRowId) => () => void,
  handleSaveClick: (id: GridRowId) => () => void,
  rowModesModel: GridRowModesModel,
  theme: Theme
): GridColDef[] => {
  const column: GridColDef[] = [
    {
      field: "actions",
      type: "actions",
      align: "center",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
        if (row.inOrOut === 1) {
          if (isInEditMode) {
            return [
              <Button
                startIcon={<SaveIcon />}
                onClick={handleSaveClick(id)}
                sx={{
                  color: theme.palette.text.primary,
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: theme.palette.primary.main
                  }
                }}
              />,
              <Button
                startIcon={<CancelIcon />}
                className="textPrimary"
                onClick={handleCancelClick(id)}
                sx={{
                  color: theme.palette.text.primary,
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: theme.palette.text.secondary
                  }
                }}
              />
            ]
          }
          return [
            <Button
              startIcon={<EditIcon />}
              className="textPrimary"
              onClick={handleEditClick(id)}
              sx={{
                color: theme.palette.text.primary,
                "&:hover": {
                  backgroundColor: "transparent",
                  color: theme.palette.error.main
                }
              }}
            />,
            <Button
              startIcon={<DeleteIcon />}
              onClick={handleDeleteClick(id)}
              sx={{
                color: theme.palette.text.primary,
                "&:hover": {
                  backgroundColor: "transparent",
                  color: theme.palette.error.main
                }
              }}
            />
          ]
        } else if (row.inOrOut === -1) {
          return [
            <Button
              startIcon={<DeleteIcon />}
              onClick={handleDeleteClick(id)}
              sx={{
                color: theme.palette.text.primary,
                "&:hover": {
                  backgroundColor: "transparent",
                  color: theme.palette.error.main
                }
              }}
            />
          ]
        }
        return []
      }
    },
    {
      field: "indexNo",
      headerName: "ID",
      headerAlign: "center",
      align: "center"
    },
    {
      field: "matNo",
      headerName: "Material No",
      minWidth: 150,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "inOrOut",
      headerName: "in Or Out",
      minWidth: 100,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "actionDate",
      headerName: "Action Date",
      headerAlign: "center",
      minWidth: 150,
      align: "center"
    },
    {
      field: "quantity",
      headerName: "Quantity",
      minWidth: 100,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "factory",
      headerName: "Factory",
      headerAlign: "center",
      align: "center"
    },
    {
      field: "invoiceNo",
      headerName: "Invoice No.",
      minWidth: 180,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "packingBy",
      headerName: "PackingBy",
      minWidth: 160,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "jobOrder",
      headerName: "Job Order",
      minWidth: 300,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "remarks",
      headerName: "Remarks",
      minWidth: 180,
      type: "string",
      editable: true,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "isNG",
      headerName: "isNG",
      minWidth: 80,
      headerAlign: "center",
      align: "center"
    },

    // {
    //   field: "partNo",
    //   headerName: "Part No.",
    //   minWidth: 180,
    //   headerAlign: "center",
    //   align: "center"
    // },
    {
      field: "deliveryDate",
      headerName: "Delivery Date",
      minWidth: 180,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "smQty",
      headerName: "sm Qty",
      minWidth: 180,
      type: "number",
      editable: true,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "supplier",
      headerName: "Supplier",
      minWidth: 180,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "updatedBy",
      headerName: "Updated By",
      minWidth: 180,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "updatedDate",
      headerName: "Updated Date",
      minWidth: 180,
      headerAlign: "center",
      align: "center"
    }
  ]
  return column
}

const MatInOutEditor: React.FC = () => {
  const theme = useTheme()
  const [rows, setRows] = useState<GridRowsProp>([])
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
  const rowIdRef = useRef<GridRowId>()
  const [matNo, setMatNo] = useState<string>("")
  const { data: getMatNo } = useQuery(GET_MATERIALS)

  const listMatNo: string[] = useMemo(() => {
    if (!getMatNo || !getMatNo.materials) return []
    else {
      return getMatNo?.materials.map(({ matNo }: { matNo: string }) => {
        return matNo
      })
    }
  }, [getMatNo])

  const [getMatStocks, { loading: loadingGetMatStocks }] = useLazyQuery(
    GET_MATSTOCK,
    {
      onCompleted: (data) => {
        if (data && data.matStocks.length) setRows(data.matStocks)
        else Swal.fire({ title: `ไม่พบข้อมูล`, icon: "info" })
      }
    }
  )

  const [letDeleteMatStock] = useMutation(DELETE_MAT_STOCK)
  const [letUpdateMatStock] = useMutation(UPDATE_MAT_STOCK, {
    awaitRefetchQueries: true,
    refetchQueries: [GET_MATSTOCK]
  })

  const handleSubmitGetMatStocks = async () => {
    setRows([])
    if (matNo) {
      try {
        await getMatStocks({
          variables: {
            matNo: matNo
          },
          fetchPolicy: "network-only"
        })
      } catch (error) {
        Swal.fire({ title: `${(error as Error).message}`, icon: "error" })
      }
    } else
      Swal.fire({ title: "ขอความกรุณาใส่ Material Number", icon: "warning" })
  }

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false }
    await handleSaveData(updatedRow as IMatstock, "update")
    setRows(
      rows.map((row) => (row.indexNo === newRow.indexNo ? updatedRow : row))
    )
    return updatedRow
  }

  const handleSaveData = async (updateRow: IMatstock, type: string) => {
    if (type === "delete") {
      try {
        const deleted = await letDeleteMatStock({
          variables: {
            indexNo: updateRow.indexNo,
            matNo: updateRow.matNo,
            inOrOut: updateRow.inOrOut,
            invoiceNo: updateRow.invoiceNo,
            updatedBy: user.username,
            fromPage: "Materials > Material In & Out Editor"
          }
        })
        return deleted.data?.deleteMatStock
      } catch (error) {
        Swal.fire({
          title: "error",
          text: (error as Error).message,
          icon: "error"
        })
      }
    }
    if (type === "update") {
      try {
        const updated = await letUpdateMatStock({
          variables: {
            indexNo: updateRow.indexNo,
            remarks: updateRow.remarks,
            smQty: updateRow.smQty,
            updatedBy: user.employee,
            updatedDate: moment().format("YYYY-MM-DD HH:mm:ss")
          }
        })
        handleSubmitGetMatStocks()
        Swal.fire({
          title: "สำเร็จ",
          text: "ข้อมูลถูกแก้ไขเรียบร้อย",
          icon: "success"
        })
        return updated.data?.updateWipStock
      } catch (error) {
        Swal.fire({
          title: "error",
          text: (error as Error).message,
          icon: "error"
        })
      }
    }
  }

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit }
    })
  }
  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View }
    })
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })
    const editedRow = rows.find((row) => row.indexNo === id)
    if (editedRow?.isNew) {
      setRows(rows.filter((row) => row.indexNo !== id))
    }
  }

  const whatDataWillBeDeleted = async (id: number): Promise<number[]> => {
    const current = (rows as IMatstock[]).find(
      (item: IMatstock) => item.indexNo === id
    )
    let result: number[] = []

    if (current?.inOrOut === -1) {
      result.push(current?.indexNo)
    } else if (current?.inOrOut === 1) {
      const getData = (rows as IMatstock[]).filter((item: IMatstock) => {
        return (
          item.matNo === current?.matNo && item.invoiceNo === current?.invoiceNo
        )
      })
      result = getData.map((item: IMatstock) => {
        return item.indexNo
      })
    }
    return result
  }

  const handleDeleteClick = (id: GridRowId) => async () => {
    const getIndexNoToShowBeforeDelete: number[] = await whatDataWillBeDeleted(
      id as number
    )
    const displayText =
      getIndexNoToShowBeforeDelete.length > 1
        ? `เหล็กขาเข้า InOrOut = 1, มี ID ${getIndexNoToShowBeforeDelete} ที่จะถูกลบ`
        : `เหล็กขาออก InOrOut = -1, มี ID ${getIndexNoToShowBeforeDelete} ที่จะถูกลบ`

    const result = await Swal.fire({
      title: "ลบข้อมูลใช่หรือไม่ ?",
      text: displayText,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "ลบข้อมูล",
      cancelButtonText: "ยกเลิก"
    })
    if (result.isConfirmed) {
      try {
        const clickedRowToDelete = rows.find((row) => row.indexNo === id)
        const showResult = await handleSaveData(
          clickedRowToDelete as IMatstock,
          "delete"
        )
        if (showResult) {
          handleSubmitGetMatStocks()
          Swal.fire({
            title: "เสร็จสิ้น",
            text: "ลบข้อมูลเรียบร้อย",
            icon: "success"
          })
        } else {
          Swal.fire({
            title: "ล้มเหลว",
            text: "ลบไม่สำเร็จ",
            icon: "error"
          })
        }
      } catch (error) {
        Swal.fire({
          title: "ล้มเหลว",
          text: (error as Error).message,
          icon: "error"
        })
      }
    }
  }

  const handleProcessError = async (error: Error) => {
    const id = rowIdRef.current as GridRowId
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })
    return Swal.fire({
      title: "error",
      text: error.message,
      icon: "error"
    })
  }

  const columns = makeColumns(
    handleCancelClick,
    handleDeleteClick,
    handleEditClick,
    handleSaveClick,
    rowModesModel,
    theme
  )

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Materials}
        menuItemName={Object.keys(menuItemList)[1]}
        menuPageName={"Material In & Out Editor"}
      />
      <ThemedCard>
        <PageLabelCard
          title="Material In & Out Editor"
          subTitle="Use to edit value of materials in and out"
        />
        <Divider
          sx={{ height: 1, bgcolor: theme.palette.secondary.contrastText }}
        />
        <br />
        <Grid container spacing={2} sx={{ p: "20px" }}>
          <Grid item xs={4} md={2}>
            <Autocomplete
              value={matNo}
              onChange={(event, value) => {
                setMatNo(value as string)
              }}
              options={listMatNo}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Material No."
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>

          <Grid item xs={3} md={2}>
            <ThemedLoadingButton
              contrast
              loading={loadingGetMatStocks}
              variant="outlined"
              sx={{ mt: 1, width: "70%" }}
              onClick={handleSubmitGetMatStocks}
            >
              SUBMIT
            </ThemedLoadingButton>
          </Grid>

          <Grid item xs={12}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              dataGridProps={{
                rowModesModel: rowModesModel,
                onRowEditStop: handleRowEditStop,
                processRowUpdate: processRowUpdate,
                onProcessRowUpdateError: handleProcessError,
                getRowHeight: () => "auto",
                getRowId: (row) => row.indexNo,
                sx: {
                  bgcolor: theme.palette.secondary.main,
                  "& .MuiGrid-root .MuiButtonBase-root": {
                    color: theme.palette.secondary.contrastText,
                    "&:hover": {
                      bgcolor: "rgba(0, 0, 0, 0.1) !important"
                    }
                  },
                  "& .MuiDataGrid-row:nth-of-type(odd)": {
                    backgroundColor: theme.palette.background.default
                  }
                }
              }}
            />
          </Grid>
        </Grid>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default MatInOutEditor
