import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CardHeader,
  Grid,
  Switch,
  Typography
} from "@mui/material"
import { SetStateAction, useEffect, useState } from "react"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import { GET_PARTS } from "../../common/Query/MasterQuery"
import { useQuery } from "@apollo/client"
import { IPart } from "../Master/Parts"
import BarcodeScannerComponent from "react-qr-barcode-scanner"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import { bankImage, getImagePng } from "../../common/utilities"
import CameraAltIcon from "@mui/icons-material/CameraAlt"

const ScannerQRCodeReadPart = (): JSX.Element => {
  const { data } = useQuery(GET_PARTS)
  const [isShow, setIsShow] = useState<boolean>(false)
  const [partNo, setPartNo] = useState<IPart>({} as IPart)
  const [isStream, setIsStream] = useState<boolean>(false)
  const [dataScan, setDataScan] = useState<string>("")

  const handleScan = (data: SetStateAction<string> | null): void => {
    if (data) {
      setDataScan(data)
    }
  }
  const breakQRCode = (e?: { preventDefault: () => void }) => {
    e?.preventDefault()
    const findPart = data.parts.find((part: IPart) => part.partNo === dataScan)
    if (findPart) {
      setPartNo(findPart)
      setIsShow(true)
    } else setIsShow(false)
  }

  useEffect(() => {
    if (dataScan) {
      breakQRCode()
    }
  }, [dataScan])

  return (
    <ThemedCard>
      <CardHeader
        titleTypographyProps={{ variant: "h4", fontFamily: "Sarabun" }}
        title={"Scanner QR Code Read Part"}
        subheaderTypographyProps={{ fontFamily: "Sarabun" }}
      />
      <Box component="form" onSubmit={breakQRCode}>
        <Accordion
          expanded={isStream}
          onChange={() => setIsStream(!isStream)}
          style={{
            marginBottom: "5px",
            marginTop: "10px"
          }}
        >
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ backgroundColor: "#ffffff", display: "none" }}
          />

          <AccordionDetails>
            {isStream ? (
              <>
                <Grid container justifyContent={"center"}>
                  <Grid item sm={3} xs={12} container justifyContent={"center"}>
                    <BarcodeScannerComponent
                      width={"100%"}
                      height={"100%"}
                      onUpdate={(_, result) => {
                        if (result) {
                          handleScan(result.getText())
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </AccordionDetails>
        </Accordion>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Grid container spacing={2} width={"98%"} margin={2}>
            <Grid item xs={12} sm={12}>
              <Switch
                checked={isStream}
                onChange={(event) => {
                  setIsStream(event.target.value ? !isStream : isStream)
                }}
                name="gilad"
              />
              <label style={{ fontSize: "14px" }}>
                Active Camera{" "}
                <CameraAltIcon style={{ verticalAlign: "middle" }} />
              </label>
            </Grid>
            <Grid item xs={12} sm={4}>
              <ThemedTextField
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                id="scanner"
                value={dataScan}
                label="scanner"
                onChange={(e) => {
                  setDataScan(e.target.value)
                }}
              />
            </Grid>
            {isShow && (
              <>
                <Grid item sm={4} xs={12} container justifyContent={"center"}>
                  <Box
                    sx={{
                      bgcolor: "background.paper",
                      boxShadow: 1,
                      borderRadius: 1,
                      p: 2,
                      m: 1,
                      textAlign: "left",
                      fontFamily: "Sarabun"
                    }}
                  >
                    <Typography variant="h6">
                      Part No. : {partNo.partNo}
                    </Typography>
                    <Typography variant="h6">
                      Part Name : {partNo.partName}
                    </Typography>
                    <Typography variant="h6">
                      Customer : {partNo.customer}
                    </Typography>
                    <Typography variant="h6">Model : {partNo.model}</Typography>
                    <Typography variant="h6">Cost : {partNo.cost}</Typography>
                    <Typography variant="h6">
                      Lead Time : {partNo.leadTime}
                    </Typography>
                    <Typography variant="h6">Stamp : {partNo.stamp}</Typography>
                    <Typography variant="h6">Weld : {partNo.weld}</Typography>
                    <Typography variant="h6">
                      Arc Weld : {partNo.arcweld}
                    </Typography>
                    <Typography variant="h6">Plate : {partNo.plate}</Typography>
                  </Box>
                </Grid>
                <Grid item sm={4} xs={12} container justifyContent={"center"}>
                  <img
                    src={getImagePng(`images/parts/${partNo.partNo}`)}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null
                      currentTarget.src = bankImage
                    }}
                    style={{ width: "100%" }}
                    alt=""
                  ></img>
                </Grid>
              </>
            )}
          </Grid>
          <Button type="submit"></Button>
        </Box>
      </Box>
    </ThemedCard>
  )
}
export default ScannerQRCodeReadPart
