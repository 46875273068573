import { Route, Routes, Navigate } from "react-router-dom"
import {
  Customers,
  Material,
  Parts,
  Machine,
  Process,
  PartsProcess,
  Employee
} from "../page/Master"
import {
  AuthorizationProduction,
  AuthorizationQC,
  AuthorizationConfirmation
} from "../page/Authorization"
import { CreateUsersBae, Login } from "../page/Login"
import { Document } from "../page/Document"
import { MPAddRemove, AddFG, MPEntry } from "../page/MasterPlan"
import {
  MaterialsStockCard,
  MatIn,
  MatOut,
  SMOrder,
  MaterialTag,
  BarCodeMaterial,
  MatOutScanner,
  MaterialOutReport,
  InventoryReport,
  WipStock
} from "../page/Material"
import {
  Transport,
  LogisticsCostRecord,
  LogisticsCostReport,
  DeliveryTag,
  DeliveryTag1,
  DeliveryScan,
  DeliveryTagReport
} from "../page/Logistics"
import {
  WorkInProcess,
  ProductionTag,
  JobOrder,
  ProductionPlan,
  ProductionPlanReport
} from "../page/Planning"
import {
  ApproveEditScanner,
  Dashboard,
  EditScanner,
  IotQuantity,
  Production,
  SupplierRecord,
  SupplierStockCard,
  WhLocationReport
} from "../page"
import { Scanner } from "../page"
import {
  GetWarehouseTag,
  WarehouseFGIn,
  WarehouseFGOut,
  WhFGReport,
  WhLocationScan,
  WhScanner,
  WhTagReport,
  FGInventoryMaterial,
  WarehouseFGOutMultiple,
  PackagingStandard
} from "../page/Warehouse"
import { Factory1, Factory2, PMhistory, PMreset } from "../page/Engineer"
import {
  PremiumFreight,
  PremiumFreightReport,
  QCDemolish,
  QCFinishedGoods,
  MasterDockAuditCheckSheet
} from "../page/QC"
import jwt_decode from "jwt-decode"
import { SummaryPlan, DailyProductionList } from "../page/Production"
import SparePartList from "../page/Maintenance/SparePartList"
import {
  KPIMaintenance,
  PmQrRecord,
  PMReport,
  RepairDocument,
  RepairRecord
} from "../page/Maintenance"
import PreventiveMaintenancePlan from "../page/Maintenance/PreventiveMaintenancePlan"
import MachineMt from "../page/Maintenance/MachineMt"
import { AccountProduction, InvoiceReport } from "../page/Account"
import { Box, Theme, useTheme } from "@mui/material"
import "./routes.css"
import { DailyPlatingReport, MasterZinc } from "../page/Plate"
import WarehouseEditFG from "../page/Warehouse/WarehouseEditFG"
import Bom from "../page/Master/Bom"
import MonthlyPlatingReport from "../page/Plate/MonthlyPlatingReport"
import MPReportWithProvider from "../context/MPContext"
import UploadFileSlipSalary from "../page/Human Resources/uploadFileSlip"
import SlipSalary from "../page/SlipSalary/slipSalary"
import MasterBreakdown from "../page/Production/MasterBreakdown"
import PMDocument from "../page/Engineer/PMDocument"
import PMSparePartIn from "../page/Engineer/PMSparePartInOrOut"
import PMSparePartStockCard from "../page/Engineer/PMSparePartStockCard"
import MasterPMSparePart from "../page/Engineer/MasterPMSparePart"
import PMEngineerReport from "../page/Engineer/Component/PMEngineerReport"
import PMLogSheetReport from "../page/Engineer/Component/PMLogSheetReport"
import PMDashBoard from "../page/Engineer/PMDashBoard"
import { IotProductionReport, IotScanner } from "../page/Iot"
import SupplierReport from "../page/SupplierControl/SupplierReport"
import SupplierTransport from "../page/SupplierControl/SupplierTransport"
import ScannerQRCodeReadPart from "../page/ScannerQRCodeReadPart/ScannerQRCodeReadPart"
import MatInOutEditor from "../page/Material/MatInOutEditor"
import { SupplierEdit } from "../page/SupplierControl"

const token: string = window?.localStorage?.getItem("token") || ""
let roles: string[] = []
if (token) {
  const user: { role: string } = jwt_decode(token)
  roles = user?.role.toString().split(",")
}
export const checkRole = (role: string[]): boolean => {
  const intersection = roles.filter((element) => role.includes(element))
  return intersection.length > 0 ? true : false
}

const useStyles = (theme: Theme) => ({
  pageBody: {
    backgroundColor: theme.palette.primary.light,
    "@media print": {
      minWidth: "fit-content",
      backgroundColor: "white",
      color: "black",
      width: "100vw",
      left: 0,
      top: 0
    }
  }
})

const Routers = (): JSX.Element => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const smOrder =
    "/%E0%B9%83%E0%B8%9A%E0%B8%95%E0%B8%A3%E0%B8%A7%E0%B8%88%E0%B8%AA%E0%B8%B4%E0%B8%99%E0%B8%84%E0%B9%89%E0%B8%B2(sm)"

  const DASHBOARD_ROUTES = (
    <>
      <Route path="Dashboard" element={<Dashboard />} />
    </>
  )

  const LOGISTICS_ROUTES = (
    <>
      <Route
        path="Transport"
        element={!checkRole(["99", "9"]) ? <Navigate to="/" /> : <Transport />}
      />
      <Route
        path="LogisticsCostRecord"
        element={
          !checkRole(["99", "9"]) ? (
            <Navigate to="/" />
          ) : (
            <LogisticsCostRecord />
          )
        }
      />
      <Route
        path="LogisticsCostReport"
        element={
          !checkRole(["99", "9"]) ? (
            <Navigate to="/" />
          ) : (
            <LogisticsCostReport />
          )
        }
      />
      <Route
        path="DeliveryTag"
        element={
          !checkRole(["99", "9"]) ? <Navigate to="/" /> : <DeliveryTag />
        }
      />
      <Route
        path="DeliveryTag-1"
        element={
          !checkRole(["99", "9"]) ? <Navigate to="/" /> : <DeliveryTag1 />
        }
      />
      <Route
        path="DeliveryScan"
        element={
          !checkRole(["99", "9"]) ? <Navigate to="/" /> : <DeliveryScan />
        }
      />
      <Route
        path="DeliveryTagReport"
        element={
          !checkRole(["99", "9"]) ? <Navigate to="/" /> : <DeliveryTagReport />
        }
      />
      <Route
        path="ScannerQRCodePart"
        element={
          !checkRole(["99", "9"]) ? (
            <Navigate to="/" />
          ) : (
            <ScannerQRCodeReadPart />
          )
        }
      />
    </>
  )

  const QC_ROUTES = (
    <>
      <Route
        path="/QCDemolish"
        element={!checkRole(["99", "6"]) ? <Navigate to="/" /> : <QCDemolish />}
      />
      <Route
        path="/QCFinishedGoods"
        element={
          !checkRole(["99", "6"]) ? <Navigate to="/" /> : <QCFinishedGoods />
        }
      />
      <Route
        path="/PremiumFreight"
        element={
          !checkRole(["99", "6"]) ? <Navigate to="/" /> : <PremiumFreight />
        }
      />
      <Route
        path="/PremiumFreightReport"
        element={
          !checkRole(["99", "6"]) ? (
            <Navigate to="/" />
          ) : (
            <PremiumFreightReport />
          )
        }
      />
      <Route
        path="/DockAuditCheckSheet"
        element={
          !checkRole(["99", "6"]) ? (
            <Navigate to="/" />
          ) : (
            <MasterDockAuditCheckSheet />
          )
        }
      />
    </>
  )

  const MASTER = (
    <>
      <Route
        path="/Customers"
        element={!token ? <Navigate to="/" /> : <Customers />}
      />
      <Route
        path="/Material"
        element={!checkRole(["99", "1"]) ? <Navigate to="/" /> : <Material />}
      />

      <Route
        path="/Parts"
        element={!checkRole(["99", "1"]) ? <Navigate to="/" /> : <Parts />}
      />
      <Route
        path="/Machine"
        element={!checkRole(["99", "1"]) ? <Navigate to="/" /> : <Machine />}
      />
      <Route
        path="/Process"
        element={!checkRole(["99", "1"]) ? <Navigate to="/" /> : <Process />}
      />
      <Route
        path="/PartProcess"
        element={
          !checkRole(["99", "1"]) ? <Navigate to="/" /> : <PartsProcess />
        }
      />
      <Route
        path="/Employee"
        element={!checkRole(["99", "1"]) ? <Navigate to="/" /> : <Employee />}
      />
      <Route
        path="/Bom"
        element={!checkRole(["99", "1"]) ? <Navigate to="/" /> : <Bom />}
      />
    </>
  )

  const MATERIAL = (
    <>
      <Route
        path="/Materials'In"
        element={!checkRole(["99", "2"]) ? <Navigate to="/" /> : <MatIn />}
      />
      <Route
        path="/Materials'Out"
        element={!checkRole(["99", "2"]) ? <Navigate to="/" /> : <MatOut />}
      />
      <Route
        path="/MatInOutEditor"
        element={
          !checkRole(["99", "2"]) ? <Navigate to="/" /> : <MatInOutEditor />
        }
      />
      <Route
        path={smOrder}
        element={!checkRole(["99", "2"]) ? <Navigate to="/" /> : <SMOrder />}
      />
      <Route
        path="MaterialsReportStockCard"
        element={
          !checkRole(["99", "2"]) ? <Navigate to="/" /> : <MaterialsStockCard />
        }
      />
      <Route
        path="FIFOMaterials'Tag"
        element={
          !checkRole(["99", "2"]) ? <Navigate to="/" /> : <MaterialTag />
        }
      />

      <Route
        path="BarcodePartList"
        element={
          !checkRole(["99", "2"]) ? <Navigate to="/" /> : <BarCodeMaterial />
        }
      />
      <Route
        path="MaterialOutScanner"
        element={
          !checkRole(["99", "2"]) ? <Navigate to="/" /> : <MatOutScanner />
        }
      />
      <Route
        path="MaterialOutReport"
        element={
          !checkRole(["99", "2"]) ? <Navigate to="/" /> : <MaterialOutReport />
        }
      />
      <Route
        path="MaterialInventoryReport"
        element={
          !checkRole(["99", "2"]) ? <Navigate to="/" /> : <InventoryReport />
        }
      />
      <Route
        path="WipStock"
        element={!checkRole(["99", "2"]) ? <Navigate to="/" /> : <WipStock />}
      />
    </>
  )

  const PLANNING = (
    <>
      <Route
        path="/MPReport"
        element={
          !checkRole(["99", "3"]) ? (
            <Navigate to="/" />
          ) : (
            <MPReportWithProvider />
          )
        }
      />
      <Route
        path="/AddBeginStock"
        element={!checkRole(["99", "3"]) ? <Navigate to="/" /> : <AddFG />}
      />
      <Route
        path="/AddCancel"
        element={
          !checkRole(["99", "3"]) ? <Navigate to="/" /> : <MPAddRemove />
        }
      />
      <Route
        path="/OrderActualPlan"
        element={!checkRole(["99", "3"]) ? <Navigate to="/" /> : <MPEntry />}
      />
      <Route
        path="/ProductionTag"
        element={
          !checkRole(["99", "3"]) ? <Navigate to="/" /> : <ProductionTag />
        }
      />
      <Route
        path="/WorkInProcess"
        element={
          !checkRole(["99", "3"]) ? <Navigate to="/" /> : <WorkInProcess />
        }
      />
      <Route
        path="/JobOrder"
        element={!checkRole(["99", "3"]) ? <Navigate to="/" /> : <JobOrder />}
      />
      <Route
        path="ProductionPlan"
        element={
          !checkRole(["99", "3"]) ? <Navigate to="/" /> : <ProductionPlan />
        }
      />
      <Route
        path="ProductionPlanReport"
        element={
          !checkRole(["99", "3"]) ? (
            <Navigate to="/" />
          ) : (
            <ProductionPlanReport />
          )
        }
      />
    </>
  )

  const PRODUCTION = (
    <>
      <Route
        path="/ProductionReport"
        element={!checkRole(["99", "4"]) ? <Navigate to="/" /> : <Production />}
      />
      <Route
        path="ProductionOEEResult"
        element={
          !checkRole(["99", "4"]) ? <Navigate to="/" /> : <SummaryPlan />
        }
      />
      <Route
        path="DailyProductionPlanReport"
        element={
          !checkRole(["99", "4"]) ? (
            <Navigate to="/" />
          ) : (
            <DailyProductionList />
          )
        }
      />
      <Route
        path="MasterBreakdown"
        element={
          !checkRole(["99", "4"]) ? <Navigate to="/" /> : <MasterBreakdown />
        }
      />
    </>
  )
  const PLATE = (
    <>
      <Route
        path="/MasterPlating"
        element={
          !checkRole(["99", "4", "15"]) ? <Navigate to="/" /> : <MasterZinc />
        }
      />
      <Route
        path="/MonthlyPlatingReport"
        element={
          !checkRole(["99", "4", "15"]) ? (
            <Navigate to="/" />
          ) : (
            <MonthlyPlatingReport />
          )
        }
      />
      <Route
        path="/PlatingDailyReport"
        element={
          !checkRole(["99", "4", "15"]) ? (
            <Navigate to="/" />
          ) : (
            <DailyPlatingReport />
          )
        }
      />
    </>
  )
  const SCANNER = (
    <>
      <Route
        path="EditCancelScanner"
        element={
          !checkRole(["99", "5", "6"]) ? <Navigate to="/" /> : <EditScanner />
        }
      />
      <Route
        path="/Scanner"
        element={
          !checkRole(["99", "5", "6"]) ? <Navigate to="/" /> : <Scanner />
        }
      />
      <Route
        path="/ApproveEditScanner"
        element={
          !checkRole(["99", "5", "6"]) ? (
            <Navigate to="/" />
          ) : (
            <ApproveEditScanner />
          )
        }
      />
    </>
  )

  const ENGINEER = (
    <>
      <Route
        path="Factory1"
        element={!checkRole(["99", "7"]) ? <Navigate to="/" /> : <Factory1 />}
      />

      <Route
        path="Factory2"
        element={!checkRole(["99", "7"]) ? <Navigate to="/" /> : <Factory2 />}
      />
      <Route
        path="PMHistory"
        element={!checkRole(["99", "7"]) ? <Navigate to="/" /> : <PMhistory />}
      />
      <Route
        path="PMReset"
        element={!checkRole(["99", "7"]) ? <Navigate to="/" /> : <PMreset />}
      />
      <Route
        path="PMDocument"
        element={
          !checkRole(["99", "7", "12", "6", "4"]) ? (
            <Navigate to="/" />
          ) : (
            <PMDocument />
          )
        }
      />
      <Route
        path="PMSparePartIn"
        element={
          !checkRole(["99", "7"]) ? <Navigate to="/" /> : <PMSparePartIn />
        }
      />
      <Route
        path="PMSparePartStockCard"
        element={
          !checkRole(["99", "7"]) ? (
            <Navigate to="/" />
          ) : (
            <PMSparePartStockCard />
          )
        }
      />
      <Route
        path="MasterSparePart"
        element={
          !checkRole(["99", "7"]) ? <Navigate to="/" /> : <MasterPMSparePart />
        }
      />
      <Route
        path="PMEngineerReport"
        element={
          !checkRole(["99", "7", "12", "6", "4"]) ? (
            <Navigate to="/" />
          ) : (
            <PMEngineerReport />
          )
        }
      />
      <Route
        path="PMLogSheetReport"
        element={
          !checkRole(["99", "7", "12", "6", "4"]) ? (
            <Navigate to="/" />
          ) : (
            <PMLogSheetReport />
          )
        }
      />
      <Route
        path="PMDashBoard"
        element={
          !checkRole(["99", "7", "12", "6", "4"]) ? (
            <Navigate to="/" />
          ) : (
            <PMDashBoard />
          )
        }
      />
    </>
  )

  const WAREHOUSE = (
    <>
      <Route
        path="WHLocationreport"
        element={
          !checkRole(["99", "8"]) ? <Navigate to="/" /> : <WhLocationReport />
        }
      />
      <Route
        path="WHReport"
        element={
          !checkRole(["99", "8"]) ? <Navigate to="/" /> : <WhTagReport />
        }
      />
      <Route
        path="FGReportStockCard"
        element={!checkRole(["99", "8"]) ? <Navigate to="/" /> : <WhFGReport />}
      />
      <Route
        path="WHLocationscan"
        element={
          !checkRole(["99", "8"]) ? <Navigate to="/" /> : <WhLocationScan />
        }
      />

      <Route
        path="WHTag"
        element={
          !checkRole(["99", "8"]) ? <Navigate to="/" /> : <GetWarehouseTag />
        }
      />
      <Route
        path="WHScanner"
        element={!checkRole(["99", "8"]) ? <Navigate to="/" /> : <WhScanner />}
      />
      <Route
        path="/PackagingStandard"
        element={
          !checkRole(["99", "8"]) ? <Navigate to="/" /> : <PackagingStandard />
        }
      />
      <Route
        path="/FGin"
        element={
          !checkRole(["99", "8"]) ? <Navigate to="/" /> : <WarehouseFGIn />
        }
      />
      <Route
        path="/FGOut"
        element={
          !checkRole(["99", "8"]) ? <Navigate to="/" /> : <WarehouseFGOut />
        }
      />
      <Route
        path="WarehouseEditFG"
        element={
          !checkRole(["99", "8"]) ? <Navigate to="/" /> : <WarehouseEditFG />
        }
      />
      <Route
        path="FGInventoryReport"
        element={
          !checkRole(["99", "8"]) ? (
            <Navigate to="/" />
          ) : (
            <FGInventoryMaterial />
          )
        }
      />
      <Route
        path="FGOutMultiple"
        element={
          !checkRole(["99", "8"]) ? (
            <Navigate to="/" />
          ) : (
            <WarehouseFGOutMultiple />
          )
        }
      />
    </>
  )

  const AUTHORIZATION = (
    <>
      <Route
        path="/Production"
        element={
          !checkRole(["99", "10"]) ? (
            <Navigate to="/" />
          ) : (
            <AuthorizationProduction />
          )
        }
      />
      <Route
        path="/QC"
        element={
          !checkRole(["99", "10"]) ? <Navigate to="/" /> : <AuthorizationQC />
        }
      />
      <Route
        path="/Confirmation"
        element={
          !checkRole(["99", "10"]) ? (
            <Navigate to="/" />
          ) : (
            <AuthorizationConfirmation />
          )
        }
      />
    </>
  )

  const LOGIN = (
    <>
      <Route
        path="/"
        element={
          token && checkRole(["17"]) ? (
            <Navigate to="SlipSalary" />
          ) : token ? (
            <Navigate to="Dashboard" />
          ) : (
            <Login />
          )
        }
      />
      <Route
        path="/SignUp"
        element={!checkRole(["99"]) ? <Navigate to="/" /> : <CreateUsersBae />}
      />
    </>
  )

  const Slip = (
    <>
      <Route path="/SlipSalary" element={<SlipSalary />} />
    </>
  )

  const DOCUMENT = (
    <>
      <Route path="/Document" element={<Document />} />
    </>
  )
  const Maintenance = (
    <>
      <Route
        path="/RepairDocument"
        element={
          !checkRole(["99", "12"]) ? <Navigate to="/" /> : <RepairDocument />
        }
      />
      <Route
        path="/MachineMaintenance"
        element={!checkRole(["99", "12"]) ? <Navigate to="/" /> : <MachineMt />}
      />
      <Route
        path="/SparePart"
        element={
          !checkRole(["99", "12"]) ? <Navigate to="/" /> : <SparePartList />
        }
      />
      <Route
        path="/PMReport"
        element={!checkRole(["99", "12"]) ? <Navigate to="/" /> : <PMReport />}
      />
      <Route
        path="/PMPlan"
        element={
          !checkRole(["99", "12"]) ? (
            <Navigate to="/" />
          ) : (
            <PreventiveMaintenancePlan />
          )
        }
      />
      <Route
        path="/KPIMaintenance"
        element={
          !checkRole(["99", "12"]) ? <Navigate to="/" /> : <KPIMaintenance />
        }
      />
      <Route
        path="/RepairRecord"
        element={
          !checkRole(["99", "12"]) ? <Navigate to="/" /> : <RepairRecord />
        }
      />
      <Route
        path="/PMPlanRecord"
        element={
          !checkRole(["99", "12"]) ? <Navigate to="/" /> : <PmQrRecord />
        }
      />
    </>
  )

  const Account = (
    <>
      <Route
        path="/Invoice"
        element={
          !checkRole(["99"]) ? <Navigate to="/" /> : <AccountProduction />
        }
      />
      <Route
        path="/invoiceReport"
        element={!checkRole(["99"]) ? <Navigate to="/" /> : <InvoiceReport />}
      />
    </>
  )

  const HumanResources = (
    <>
      <Route
        path="/UploadDataSlipSalary"
        element={
          !checkRole(["99"]) ? <Navigate to="/" /> : <UploadFileSlipSalary />
        }
      />
    </>
  )

  const Iot = (
    <>
      <Route
        path="/iot"
        element={
          !checkRole(["99", "5", "6"]) ? <Navigate to="/" /> : <IotQuantity />
        }
      />
      <Route
        path="/iotScanner"
        element={
          !checkRole(["99", "5", "6"]) ? <Navigate to="/" /> : <IotScanner />
        }
      />
      <Route
        path="/iotReport"
        element={
          !checkRole(["99", "5", "6"]) ? (
            <Navigate to="/" />
          ) : (
            <IotProductionReport />
          )
        }
      />
    </>
  )

  const SUPPLIER_CONTROL = (
    <>
      <Route
        path="/SupplierRecord"
        element={
          !checkRole(["99", "1", "4"]) ? (
            <Navigate to="/" />
          ) : (
            <SupplierRecord />
          )
        }
      />
      <Route
        path="/SupplierStockCard"
        element={
          !checkRole(["99", "1", "4"]) ? (
            <Navigate to="/" />
          ) : (
            <SupplierStockCard />
          )
        }
      />
      <Route
        path="/SupplierReport"
        element={
          !checkRole(["99", "1", "4"]) ? (
            <Navigate to="/" />
          ) : (
            <SupplierReport />
          )
        }
      />
      <Route
        path="/SupplierEdit"
        element={
          !checkRole(["99", "1", "4"]) ? <Navigate to="/" /> : <SupplierEdit />
        }
      />
      <Route
        path="/SupplierTransport"
        element={
          !checkRole(["99", "1", "4"]) ? (
            <Navigate to="/" />
          ) : (
            <SupplierTransport />
          )
        }
      />
    </>
  )

  return !token ? (
    <Routes>
      <Route
        path="/*"
        element={token ? <Navigate to="Dashboard" /> : <Login />}
      />
    </Routes>
  ) : (
    <Box component="div" sx={{ ...classes.pageBody }}>
      <Routes>
        {DASHBOARD_ROUTES}
        {MASTER}
        {MATERIAL}
        {PLANNING}
        {PRODUCTION}
        {PLATE}
        {SCANNER}
        {ENGINEER}
        {WAREHOUSE}
        {LOGISTICS_ROUTES}
        {AUTHORIZATION}
        {QC_ROUTES}
        {LOGIN}
        {DOCUMENT}
        {Slip}
        {Maintenance}
        {Account}
        {HumanResources}
        {Iot}
        {SUPPLIER_CONTROL}
      </Routes>
    </Box>
  )
}

export default Routers
