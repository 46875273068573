import React, { useEffect, useState } from "react"
import {
  Grid,
  Autocomplete,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  createFilterOptions,
  useTheme,
  ThemeProvider
} from "@mui/material"
import moment from "moment"
import { DatePicker, LocalizationProvider } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterMoment"
import "../../css/Logistics/LogisticsCSS.css"
import AdvanceForm from "./logisticscomponents/AdvanceForm"
import { useQuery, useMutation, useLazyQuery } from "@apollo/client"
import {
  GET_LOGISTICS_REPORT_CUSTOMERS,
  GET_LOGISTICS_REPORT_CAR_LICENSE,
  GET_LOGISTICS_COST_RECORD_PRICE
} from "../../common/Query/LogisticsQuery"
import { ADD_LOGISTICS_RECORD } from "../../common/Mutation/LogisticsMutation"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { menuItemList } from "../../common/Resources/menuItemList"
import swal from "sweetalert"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"

export interface LogisticsCostRecordDataType {
  deliveryDate: string
  fromOrigin: string
  companyCustom: string
  poCustomer: string
  invoiceNo: string
  amountSales: number //float
  carPerWay: number
  cost: number
  drop: number
  advance: number
  premiumFreight: number
  transportCost: number
  userRecord: string
  carLicense: string
  remarks: string
}

const pageTitle = "Logistics Cost Record"

const LogisticsCostRecord = (): JSX.Element => {
  const theme = useTheme()
  const [selectDate, setSelectDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [numberOfTrips, setNumberOfTrips] = useState<number>(1)
  // const [normalRate, setNormalRate] = useState<number>(0) //"cost" in Vue - ราคาเที่ยวปกติ
  const [drop, setDrop] = useState<number>(0)
  const [premiumFreight, setPremiumFreight] = useState<number>(0)
  const [poCustomer, setPoCustomer] = useState<string>("")
  const [invoiceNumber, setInvoiceNumber] = useState<string>("")
  const [sales, setSales] = useState<number>(0.0)
  const [recorder, setRecorder] = useState<string>("")
  const [remarks, setRemarks] = useState<string>("")

  const [origin, setOrigin] = useState<string>("")
  const [destination, setDestination] = useState<string>("")
  const [carLicense, setCarLicense] = useState<string>("")
  const [advanceQuantity, setAdvanceQuantity] = useState<number>(0)
  const [costPerUnit, setCostPerUnit] = useState<number>(0)
  const [deliveryOption, setDeliveryOption] = useState<string>("")
  const { data: customerData, loading: customerLoading } = useQuery(
    GET_LOGISTICS_REPORT_CUSTOMERS
  )
  const { data: carLicenseData, loading: carLicenseLoading } = useQuery(
    GET_LOGISTICS_REPORT_CAR_LICENSE
  )
  const deliveryCustomerList =
    !customerLoading && customerData
      ? customerData.companycustom.map(
          ({ companyName }: { companyName: string }) => companyName
        )
      : [""]
  const carLicenseList =
    !carLicenseLoading && carLicenseData
      ? carLicenseData.carLicense.map(
          ({ carLicense }: { carLicense: string }) => carLicense
        )
      : [""]

  const [
    getNormalRate,
    {
      data: normalRateData,
      called: fetchedNormalRate,
      loading: gettingNormalRate
    }
  ] = useLazyQuery(GET_LOGISTICS_COST_RECORD_PRICE, {
    variables: {
      origin: origin,
      destination: destination
    }
  })
  const [addLogisticCostRecord, { loading, error }] =
    useMutation(ADD_LOGISTICS_RECORD)

  useEffect(() => {
    getNormalRate()
  }, [origin, destination, getNormalRate])

  if (error) return <p>Error: {error}</p>

  const handleDeliveryOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDeliveryOption((event.target as HTMLInputElement).value)
  }

  const handleLogisticCostRecordSubmit = (): void => {
    if (!inComplete()) {
      swal("Warning", "กรุณาใส่ข้อมูลให้ครบถ้วน", "warning")

      return
    }
    const normalRate =
      fetchedNormalRate && !gettingNormalRate && normalRateData
        ? normalRateData.price.price * numberOfTrips
        : 0
    const logisticsCostRecordData: LogisticsCostRecordDataType = {
      deliveryDate: selectDate,
      fromOrigin: origin,
      companyCustom: destination,
      poCustomer,
      invoiceNo: invoiceNumber,
      amountSales: sales,
      carPerWay: numberOfTrips,
      cost: normalRate,
      drop: drop,
      advance: advanceQuantity * costPerUnit,
      premiumFreight: premiumFreight,
      transportCost:
        normalRate + drop + advanceQuantity * costPerUnit + premiumFreight,
      userRecord: recorder,
      carLicense,
      remarks
    }
    try {
      addLogisticCostRecord({
        variables: {
          data: logisticsCostRecordData
        }
      })
      isClear()
      swal("Success", "บันทึกข้อมูลสำเร็จ", "success")
    } catch (error) {
      swal("Warning", `${(error as Error).message}`, "warning")
    }
  }

  // const originList = [
  //   "BAE1",
  //   "BAE2",
  //   "PROSPIRA",
  //   "HITACHI",
  //   "H-ONE",
  //   "MAGNA",
  //   "MMTh",
  //   "SURTEC",
  //   "TECHNO",
  //   "TENNECO"
  // ]
  const dropList = [0, 100, 200, 500]
  const recorderList = [
    "วรากร",
    "วรารัตน์",
    "ธีรภัทร์",
    "ณัฏฐ์",
    "วันเฉลิม",
    "สุรศักดิ์",
    "ชุติมันต์"
  ]
  const inComplete = () => {
    return (
      selectDate &&
      origin &&
      destination &&
      poCustomer &&
      invoiceNumber &&
      carLicense &&
      recorder
    )
  }

  const isClear = () => {
    setAdvanceQuantity(0)
    setSales(0)
    setRemarks("")
    setDrop(0)
    setCarLicense("")
    setOrigin("")
    setDestination("")
    setPoCustomer(poCustomer.includes("MMTH") ? poCustomer : "")
    setInvoiceNumber(poCustomer.includes("MMTH") ? invoiceNumber : "")
  }
  const filter = createFilterOptions<number>()

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Logistics}
        menuItemName={Object.keys(menuItemList)[8]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <PageLabelCard
          title="Cost Record"
          subTitle="detail about Cost Record"
        />
        <PageLabelCard title="ข้อมูลการขนส่ง" />
        <FormControl component="fieldset" style={{ marginLeft: "25px" }}>
          <RadioGroup
            name="deliveryOption"
            aria-label="deliveryOption"
            value={deliveryOption.toString()}
            onChange={handleDeliveryOptionChange}
            row
          >
            {["Supplier", "Customer"].map((value) => (
              <FormControlLabel
                key={value}
                value={value.toString()}
                control={<Radio />}
                label={value.toString()}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Grid container spacing={2} margin={2} width={"98%"} paddingRight={3}>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              fullWidth
              value={carLicense}
              disableClearable
              disablePortal
              onChange={(event, selectedTruckLicense) => {
                setCarLicense(selectedTruckLicense as string)
              }}
              options={carLicenseList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="ทะเบียนรถบรรทุก"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              fullWidth
              value={origin}
              disableClearable
              disablePortal
              onChange={(event, selectedOrigin) => {
                setOrigin(selectedOrigin)
              }}
              options={deliveryCustomerList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="ต้นทาง"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              fullWidth
              value={destination}
              disableClearable
              disablePortal
              onChange={(event, selectedDestination) => {
                setDestination(selectedDestination)
              }}
              options={deliveryCustomerList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="ปลายทาง"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                label="วันที่จัดส่ง"
                openTo="day"
                views={["year", "month", "day"]}
                inputFormat="DD/MM/YYYY"
                value={selectDate}
                onChange={(newSelectDate) => {
                  setSelectDate(moment(newSelectDate).format("YYYY-MM-DD"))
                }}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    fullWidth
                    placeholder="DD/MM/YYYY"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <ThemedTextField
              fullWidth
              value={numberOfTrips}
              key="numberOfTrips"
              aria-label="numberOfTrips"
              type="number"
              label="จำนวนเที่ยว"
              InputLabelProps={{
                shrink: true
              }}
              onWheel={(event) =>
                event.currentTarget.querySelector("input")?.blur()
              }
              inputProps={{
                minLength: 0,
                step: "1"
              }}
              onChange={(e) => setNumberOfTrips(+e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <ThemedTextField
              fullWidth
              label="ราคาเที่ยวปกติ"
              InputLabelProps={{
                shrink: true
              }}
              key="normalRate"
              aria-label="normalRate"
              value={
                fetchedNormalRate && !gettingNormalRate && normalRateData
                  ? normalRateData.price.price * numberOfTrips
                  : 0
              }
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <Autocomplete
              filterOptions={(options, params) => {
                const filtered = filter(options, params)
                const { inputValue } = params
                if (inputValue !== "") {
                  filtered.push(parseInt(inputValue))
                }
                return filtered
              }}
              freeSolo
              fullWidth
              value={drop}
              disablePortal
              getOptionLabel={(option) => option.toString()}
              onChange={(event, selectedDrop) => {
                setDrop(selectedDrop ? parseInt(selectedDrop.toString()) : 0)
              }}
              options={dropList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="ราคา Drop"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AdvanceForm
              advanceQuantity={advanceQuantity}
              costPerUnit={costPerUnit}
              setAdvanceQuantity={setAdvanceQuantity}
              setCostPerUnit={setCostPerUnit}
            />
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <ThemedTextField
              value={premiumFreight}
              label="Premium Freight"
              InputLabelProps={{ shrink: true }}
              key="premiumFreight"
              aria-label="premiumFreight"
              fullWidth
              onChange={(e) => setPremiumFreight(+e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ThemedTextField
              value={poCustomer}
              key="poCustomer"
              label="ใบขอซื้อ(PO.Customer)"
              InputLabelProps={{ shrink: true }}
              aria-label="poCustomer"
              fullWidth
              onChange={(e) => setPoCustomer(e.target.value.toUpperCase())}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ThemedTextField
              value={invoiceNumber}
              key="invoiceNumber"
              label="ใบแจ้งหนี้เลขที่(Inv.NO)"
              InputLabelProps={{ shrink: true }}
              aria-label="invoiceNumber"
              fullWidth
              onChange={(e) => setInvoiceNumber(e.target.value.toUpperCase())}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <ThemedTextField
              type="number"
              label="ยอดขาย(บาท)"
              InputLabelProps={{ shrink: true }}
              onWheel={(event) =>
                event.currentTarget.querySelector("input")?.blur()
              }
              value={sales ? sales : ""}
              key="sales"
              aria-label="sales"
              fullWidth
              onChange={(e) => setSales(+e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Autocomplete
              fullWidth
              value={recorder}
              disablePortal
              onChange={(event, selectedRecorder) => {
                setRecorder(selectedRecorder as string)
              }}
              options={recorderList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="ผู้บันทึก"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <ThemedTextField
              fullWidth
              label="หมายเหตุ"
              InputLabelProps={{ shrink: true }}
              value={remarks}
              key="remarks"
              aria-label="remarks"
              onChange={(e) => setRemarks(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <ThemedLoadingButton
              loading={loading}
              variant="contained"
              loadingPosition="end"
              style={{ backgroundColor: theme.palette.primary.main }}
              onClick={() => handleLogisticCostRecordSubmit()}
            >
              Submit
            </ThemedLoadingButton>
          </Grid>
        </Grid>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default LogisticsCostRecord
