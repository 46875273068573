import React, { ChangeEvent, useState } from "react"

import {
  UploadPackagingStandard,
  CreatePackagingStandard
} from "../../common/Mutation/PackagingStandardMutation"
import {
  DOWNLOAD_PACKAGING_STANDARD,
  GetPackagingStandard
} from "../../common/Query/PackagingStandardQuery"
import { useLazyQuery, useQuery } from "@apollo/client"
import { useMutation } from "@apollo/client"
import swal from "sweetalert"

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  Box,
  Grid,
  useTheme,
  Switch,
  Autocomplete,
  TablePagination,
  ThemeProvider
} from "@mui/material"
import TableCell from "@mui/material/TableCell"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import AutocompletePartNo from "../../common/Resources/AutocompletePartNo"
import { FileUpload, FileDownload, LayersSharp } from "@mui/icons-material"
import AutocompleteCustomer from "../../common/Resources/AutocompleteCustomer"
import moment from "moment"
import UploadFileBox, {
  SelectedFileBox
} from "../../common/Resources/UploadFileBox"
import { menuItemList } from "../../common/Resources/menuItemList"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
export interface IPackagingStandard {
  partNo: string
  revision: string
  remarks: string
  date: string
  record: string
  fileName: string
}

export function onlyUnique(
  value: string,
  index: number,
  self: string[]
): boolean {
  return self.indexOf(value) === index
}

const pageTitle = "Packaging Standard"

const UploadPackagingSTD: React.FC = () => {
  const theme = useTheme()
  const [error, setError] = useState<boolean>(false)
  const [partNo, setPartNo] = useState<string>("")
  const [revision, setRevision] = useState<string>("")
  const [remark, setRemark] = useState<string>("")
  const [date, setDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [operator, setOperator] = useState<string>("")

  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const [_createPackagingStandard, { loading: createLoading }] = useMutation(
    CreatePackagingStandard,
    {
      refetchQueries: [GetPackagingStandard],
      awaitRefetchQueries: true
    }
  )
  const [uploadPackagingStandard, { loading }] = useMutation(
    UploadPackagingStandard
  )

  const toggleUpload = async () => {
    if (!partNo || !revision || !remark || !operator) {
      setError(true)
      return swal("ERROR", "กรอกข้อมูลให้ครบ", "error")
    }
    try {
      // mutation file upload
      const { data: dataUrl } = await uploadPackagingStandard({
        variables: {
          fileName: selectedFile?.name,
          contentType: selectedFile?.type
        }
      })
      const presignedUrl = dataUrl.uploadPackagingStandard

      const response = await fetch(presignedUrl, {
        method: "PUT",
        body: selectedFile,
        headers: {
          "Content-Type": selectedFile?.type ?? ""
        }
      })

      // Clear
      setPartNo("")
      setRevision("")
      setRemark("")
      setDate("")
      setOperator("")
      if (response.status === 200) {
        const data = {
          partNo,
          revision,
          remarks: remark,
          date,
          record: operator,
          fileName: selectedFile?.name
        }

        await _createPackagingStandard({
          variables: data
        })

        swal("Success", "Upload Package Standard Successful ", "success")
      }
    } catch (error) {
      swal("ERROR", `${(error as Error).message}`, "warning")
    }
  }
  const onChangeRemark = (event: ChangeEvent<HTMLInputElement>) => {
    setRemark(event.target.value)
  }
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(
      event.currentTarget.files?.["0"] ? event.currentTarget.files?.["0"] : null
    )
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <AutocompletePartNo
          partNo={partNo}
          setPartNo={setPartNo}
          error={!partNo && error}
        ></AutocompletePartNo>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ThemedTextField
          label="revision"
          fullWidth
          onChange={(event) => setRevision(event.target.value)}
          value={revision}
          error={!revision && error}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <ThemedTextField
          type="date"
          label="Date"
          InputLabelProps={{
            shrink: true
          }}
          value={date}
          error={!date && error}
          fullWidth
          onChange={(event) => setDate(event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <ThemedTextField
          label="Operator"
          fullWidth
          onChange={(event) => setOperator(event.target.value)}
          value={operator}
          error={!operator && error}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <ThemedTextField
          label="Remarks"
          fullWidth
          onChange={onChangeRemark}
          value={remark}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <UploadFileBox
          changeHandler={changeHandler}
          error={error}
          selectedFile={selectedFile}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <SelectedFileBox
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          createLoading={createLoading}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <ThemedLoadingButton
          loading={loading}
          variant="contained"
          sx={{ mt: 1 }}
          style={{
            backgroundColor: theme.palette.primary.main,
            color: "white"
          }}
          onClick={toggleUpload}
          endIcon={<FileUpload />}
        >
          Upload
        </ThemedLoadingButton>
      </Grid>
    </Grid>
  )
}

const DownloadPackagingStandard: React.FC<{
  packagingStandard: IPackagingStandard[]
  isStream: boolean
}> = (props: {
  packagingStandard: IPackagingStandard[]
  isStream: boolean
}) => {
  const { packagingStandard, isStream } = props
  const [customerSelect, setCustomerSelect] = useState<string>("")
  const [partNoSelect, setPartNoSelect] = useState<string>("")
  const [revisionSelect, setRevisionSelect] = useState<string | null>("")
  const [downloadPackagingStandard, { loading }] = useLazyQuery(
    DOWNLOAD_PACKAGING_STANDARD
  )

  const toggleDownload = async () => {
    try {
      const result = await downloadPackagingStandard({
        variables: {
          record: customerSelect,
          partNo: partNoSelect,
          revision: revisionSelect
        }
      })
      swal("Success", "Download Package Standard Successful", "success")
      window.open(result.data.downloadPackagingStandard.url)
    } catch (error) {
      swal("ERROR", `${(error as Error).message}`, "error")
    }
  }
  const theme = useTheme()
  return (
    <Grid
      container
      spacing={2}
      marginLeft="44px"
      style={isStream ? {} : { display: "none" }}
    >
      <Grid item xs={12} sm={6}>
        <AutocompleteCustomer
          setCustomer={setCustomerSelect}
          customer={customerSelect}
        />
      </Grid>

      <Grid item xs={12} sm={8} />
      <Grid item xs={12} sm={6}>
        <AutocompletePartNo setPartNo={setPartNoSelect} partNo={partNoSelect} />
      </Grid>
      <Grid item xs={12} sm={8} />
      <Grid item xs={12} sm={6}>
        <Autocomplete
          fullWidth
          value={revisionSelect}
          options={packagingStandard
            .filter(
              (packagingStandard: IPackagingStandard) =>
                packagingStandard.partNo === partNoSelect
            )
            .map((e: IPackagingStandard) => e.revision)}
          disablePortal
          onChange={(e, value) => setRevisionSelect(value)}
          renderInput={(params) => (
            <ThemedTextField {...params} label="Revision" />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <ThemedLoadingButton
          loading={loading}
          variant="outlined"
          sx={{ mt: 1 }}
          style={{
            border: `1px solid ${theme.palette.primary.main}`
          }}
          onClick={toggleDownload}
          endIcon={<FileDownload />}
        >
          Upload
        </ThemedLoadingButton>
      </Grid>
    </Grid>
  )
}
interface Column {
  id: "partNo" | "remarks" | "date" | "record" | "revision"
  label: string
  minWidth?: number
  align?: "left"
  format?: (value: number) => string
}
const columns: Column[] = [
  { id: "partNo", label: "PartNo", minWidth: 20 },
  { id: "revision", label: "Revision", minWidth: 20 },
  { id: "remarks", label: "Remarks", minWidth: 20 },
  { id: "date", label: "Date", minWidth: 20 },
  { id: "record", label: "Record", minWidth: 20 }
]
const SearchRevisionTable = (
  tableResult: IPackagingStandard[]
): JSX.Element => {
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="SearchRevision">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} align={column.align}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableResult
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((packagingstandard: IPackagingStandard) => {
              return (
                <TableRow key={packagingstandard.revision}>
                  <TableCell component="th" scope="row">
                    {packagingstandard.partNo}
                  </TableCell>
                  <TableCell>{packagingstandard.revision}</TableCell>
                  <TableCell>{packagingstandard.remarks}</TableCell>
                  <TableCell>{packagingstandard.date}</TableCell>
                  <TableCell>{packagingstandard.record}</TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={tableResult.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

const SearchRevision: React.FC<{
  packagingStandard: IPackagingStandard[]
}> = (props: { packagingStandard: IPackagingStandard[] }) => {
  const [partNoSelect, setPartNoSelect] = useState<string>("")
  const [tableResult, setTableResult] = useState<IPackagingStandard[]>([])
  const [error, setError] = useState<boolean>(false)
  const { packagingStandard } = props

  const toggleShowHistory = () => {
    if (!partNoSelect) {
      setError(true)
      return swal("ERROR", "ใส่ข้อมูล Part No", "warning")
    }
    const packagingStandard_with_PartNo = packagingStandard.filter(
      (packagingstandard: IPackagingStandard) => {
        return packagingstandard.partNo === partNoSelect
      }
    )
    setTableResult(packagingStandard_with_PartNo)
  }
  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={10}>
          <AutocompletePartNo
            setPartNo={setPartNoSelect}
            partNo={partNoSelect}
            error={!partNoSelect && error}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <ThemedLoadingButton
            variant="contained"
            sx={{ mt: 1 }}
            style={{
              backgroundColor: theme.palette.primary.main,
              color: "white"
            }}
            onClick={toggleShowHistory}
          >
            show history
          </ThemedLoadingButton>
        </Grid>
        {SearchRevisionTable(tableResult)}
      </Grid>
    </ThemeProvider>
  )
}

const Packaging_Standard: React.FC = () => {
  const { error, data } = useQuery(GetPackagingStandard, {
    fetchPolicy: "network-only"
  })
  const [isStream, setIsStream] = useState<boolean>(false)

  if (error) swal("error", error.toString(), "")
  let packagingStandard: IPackagingStandard[] = []
  if (data) packagingStandard = data.packagingStandard
  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <Box style={{ marginLeft: "22px" }} component="form">
        <PageLabel
          menuItem={menuItemList.Warehouse}
          menuItemName={Object.keys(menuItemList)[6]}
          menuPageName={pageTitle}
        />
        <ThemedCard style={{ marginTop: "26px" }}>
          <PageLabelCard
            title="Upload Packaging Standard"
            subTitle="detail about Upload Packaging Standard"
          />
          <Grid container spacing={2} width={"98%"} margin={2}>
            <UploadPackagingSTD />
            <Grid item xs={12} sm={12}>
              <Switch
                checked={isStream}
                onChange={(event) =>
                  setIsStream(event.target.value ? !isStream : isStream)
                }
                name="gilad"
              />
              <label style={{ fontSize: "16px" }}>
                Download Packaging Standard (WH)
                <LayersSharp style={{ verticalAlign: "middle" }} />
              </label>
            </Grid>
            <DownloadPackagingStandard
              packagingStandard={packagingStandard}
              isStream={isStream}
            />
          </Grid>
          <PageLabelCard
            title="Search Revision"
            subTitle="detail about Search Revision"
          />
          <Grid
            container
            spacing={2}
            width={"100%"}
            marginTop={2}
            marginLeft={2}
            marginRight={2}
            marginBottom={0}
          >
            <SearchRevision packagingStandard={packagingStandard} />
          </Grid>
        </ThemedCard>
      </Box>
    </ThemeProvider>
  )
}

export default Packaging_Standard
export {
  UploadPackagingSTD as UploadPackagingStandard,
  DownloadPackagingStandard,
  SearchRevision
}
