import { gql } from "@apollo/client"

export const ADD_LOGISTICS_RECORD = gql`
  mutation SubmitLogisticsCostRecord($data: Data!) {
    submitLogisticsCostRecord(data: $data)
  }
`

export const ADD_DELIVERY_TAG = gql`
  mutation Deliverytag($data: deliverytag!) {
    deliverytag(data: $data)
  }
`

export const ADD_DELIVERY_SCAN = gql`
  mutation DeliveryScan($data: deliveryScan!) {
    deliveryScan(data: $data) {
      partNo
      deliveryDate
      pdLotNo
      remarks
      factory
      period
      poCustomer
      totalBox
      boxNo
      operator
    }
  }
`
